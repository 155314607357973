import { Button, CircularProgress, Grid, Paper, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconAdd from "@material-ui/icons/Add";
import IconGames from "@material-ui/icons/Games";
import IconOpenWith from "@material-ui/icons/OpenWith";
import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import IMandant from "../../../../types/IMandant";
import IPartnerLogo from "../../../../types/IPartnerLogo";
import deepCopy from "../../../../utils/deepCopy";
import PartnerLogoCard from "../components/cards/PartnerLogoCard";

interface IMandantPartnerLogoTabProps {
    partnerLogos?: IPartnerLogo[];
    mandant?: IMandant;
    onChange: (event: any) => void;
    onUpload: (image: IPartnerLogo, index: number) => void;
    onDelete: (index: number) => void;
    onResetImage: (index: number, imageId: number) => void;

    onImageCreate: () => void;
    onSort: (oldIndex: number, newIndex: number) => void;
    handleImagesSortSave: (sortedData: IPartnerLogo[] | undefined) => void;
}

const MandantPartnerLogoTab = (props: IMandantPartnerLogoTabProps) => {
    const { partnerLogos, mandant, onChange, handleImagesSortSave, onUpload, onDelete, onResetImage, onImageCreate } =
        props;
    const [sortingActive, setSortingActive] = React.useState<boolean>(false);
    const [sortedData, setSortedData] = React.useState<any>();

    React.useEffect(() => {
        if (partnerLogos) {
            setSortedData(deepCopy(partnerLogos));
        }
    }, [partnerLogos]);

    const toggleSortingAction = () => {
        if (sortingActive) {
            handleImagesSortSave(sortedData);
        }

        setSortingActive(!sortingActive);
    };

    const SortableItem = SortableElement(({ value, index }: { value: any; index: any }) => (
        <PartnerLogoCard
            key={value.name + index}
            index={index}
            partnerLogo={value}
            onChange={onUpload}
            onDelete={onDelete}
            onReset={onResetImage}
            isSorting={sortingActive}
        />
    ));

    const SortableList = SortableContainer(({ items }: { items: any }) => {
        return (
            <Grid container direction="row" spacing={2} style={{ marginLeft: "-16px", marginRight: "-16px" }}>
                {items ? (
                    items.map((image: IPartnerLogo, index: number) => (
                        <SortableItem value={image} index={index} key={image.id ? image.id + index + index : index} />
                    ))
                ) : (
                    <CircularProgress />
                )}
            </Grid>
        );
    });
    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        // Create a copy of the partnerLogos array
        const updatedPartnerLogos = sortedData ? [...sortedData] : [];

        // Use the arrayMove function or other suitable sorting logic to update the array
        const movedItem = updatedPartnerLogos.splice(oldIndex, 1)[0];
        updatedPartnerLogos.splice(newIndex, 0, movedItem);

        // Update the state with the sorted array
        setSortedData(updatedPartnerLogos);
    };

    return (
        <>
            <Paper variant="outlined" className="main-paper">
                <Typography variant="h2" className="main-header">
                    Partner Logos
                </Typography>
                <Grid container direction="row" spacing={2} className="gap-top">
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField
                            variant="outlined"
                            name={"partnerLogosLabel"}
                            label="Überschrift Partnerlogos"
                            value={mandant?.partnerLogosLabel ? mandant?.partnerLogosLabel : "Gefördert von"}
                            onChange={onChange}
                            fullWidth
                            required={true}
                        />
                    </Grid>
                </Grid>
                <Button
                    color="secondary"
                    onClick={() => {
                        onImageCreate();
                    }}
                    startIcon={<IconAdd />}
                >
                    Logo hinzufügen
                </Button>
                <Button
                    color="secondary"
                    onClick={toggleSortingAction}
                    startIcon={sortingActive ? <IconOpenWith /> : <IconGames />}
                >
                    {sortingActive ? "Sortierung deaktivieren" : "Sortierung aktivieren"}
                </Button>

                {sortingActive && <SortableList items={sortedData ? sortedData : []} onSortEnd={onSortEnd} axis="xy" />}
                {!sortingActive && (
                    <Grid container direction="row" spacing={2} style={{ marginLeft: "-16px", marginRight: "-16px" }}>
                        {partnerLogos != null ? (
                            partnerLogos.map((image: IPartnerLogo, index: number) => (
                                <PartnerLogoCard
                                    key={index}
                                    index={index}
                                    partnerLogo={image}
                                    onChange={onUpload}
                                    onDelete={onDelete}
                                    onReset={onResetImage}
                                />
                            ))
                        ) : (
                            <CircularProgress />
                        )}
                    </Grid>
                )}
            </Paper>
        </>
    );
};

export default MandantPartnerLogoTab;
