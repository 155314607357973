/* eslint-disable curly */
import { Checkbox, Grid, InputAdornment, InputLabel, Paper, TextField, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Alert } from "@material-ui/lab";
import React from "react";

import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import IDocument from "../../../../types/IDocument";
import IMandant from "../../../../types/IMandant";
import ISelectOption from "../../../../xhr/interface/ISelectOption";
import { IMandantValidationState } from "../validations/mandantDataValidation";

const themes: ISelectOption[] = [
    {
        label: "Sporty",
        id: "sporty",
    },
    {
        label: "Classic",
        id: "classic",
    },
    {
        label: "Bold",
        id: "bold",
    },
];

export interface IMandantDataTabProps {
    mandant: IMandant;
    mandantData: IMandant;
    onChange: (event: any) => void;
    onImageChange: (image: IDocument, attrKey: string) => void;
    // onImageListChange: (image: IDocument, attrKey: string) => void;
    onCheckboxChange: (event: any) => void;
    errorState: IMandantValidationState;
}

const MandantDataTab = (props: IMandantDataTabProps) => {
    const {
        onImageChange,
        // onImageListChange,
        mandant,
        mandantData,
        onChange,
        onCheckboxChange,
    } = props;

    return (
        <Paper variant="outlined" className="main-paper">
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper variant="outlined" className="main-paper gap-top">
                        <Typography variant="h3" className="main-header gap-bottom">
                            Portal Daten
                        </Typography>
                        <TextField
                            name="name"
                            label="Name Mandant"
                            fullWidth
                            value={mandantData.name}
                            onChange={onChange}
                            variant="outlined"
                            error={props.errorState.name}
                            required
                        />
                        <TextField
                            name="key"
                            label="Url Key"
                            fullWidth
                            value={mandantData.key}
                            onChange={onChange}
                            variant="outlined"
                            error={props.errorState.key}
                            required
                        />

                        {mandant.key === "stegadmin" && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={mandantData.flagHaveDomain}
                                            onChange={onCheckboxChange}
                                            name="flagHaveDomain"
                                        />
                                    }
                                    label="Benutze meine eigene Domain"
                                />
                                <Alert color="info" className="gap-bottom">
                                    Hier aktivieren um das Portal auf eine kundenspezifische Domain umzustellen.
                                </Alert>
                                {mandantData.flagHaveDomain && (
                                    <>
                                        <TextField
                                            name="domain"
                                            label="Adresse Portal"
                                            fullWidth
                                            value={mandantData.domain}
                                            onChange={onChange}
                                            variant="outlined"
                                        />

                                        <Alert color="info" className="gap-bottom">
                                            Die Webadresse ohne "https" und ohne Slash: "/".
                                            <br />
                                            Wenn das Portal über https://beispiel.kunde.de/ erreichbar sein soll muss
                                            hier der Wert: <strong>beispiel.kunde.de</strong> eingetragen werden.
                                        </Alert>
                                    </>
                                )}
                            </>
                        )}

                        <TextField
                            name="urlWebsite"
                            label="Webseite Mandant"
                            fullWidth
                            value={mandantData.urlWebsite}
                            onChange={onChange}
                            variant="outlined"
                        />
                        <TextField
                            name="slogan"
                            label="Slogan"
                            fullWidth
                            value={mandantData.slogan}
                            onChange={onChange}
                            variant="outlined"
                        />
                        <TextField
                            name="matomoTrackingId"
                            label="Matomo Tracking-Id"
                            fullWidth
                            value={mandantData.matomoTrackingId}
                            onChange={onChange}
                            variant="outlined"
                            error={props.errorState.name}
                        />
                        <Alert color="info" className="gap-bottom">
                            Wenn die Tracking ID aus Matomo hier eingegeben wird, wird das Webtracking aktiviert. Dies
                            muss dann auch im Impressum und im Datenschutz des Portals angezeigt werden.
                        </Alert>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={mandantData.flagHideStegLogo}
                                        onChange={onCheckboxChange}
                                        name="flagHideStegLogo"
                                    />
                                }
                                label="Das Logo der STEG im Portal deaktivieren"
                            />
                        </FormGroup>
                        <Alert color="info" className="gap-bottom">
                            Das Steg Logo im Header wird nicht mehr angezeigt wenn du diese Option aktivierts.
                        </Alert>

                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={mandantData.flagShowBigCustomerLogo}
                                        onChange={onCheckboxChange}
                                        name="flagShowBigCustomerLogo"
                                    />
                                }
                                label="Große Logos verwenden"
                            />
                        </FormGroup>
                        <Alert color="info" className="gap-bottom">
                            "Große Logos verwenden" bewirkt, dass die Logos oben im Header auf 200px vergrößert werden.
                            Standardmäßig sind diese nur 130px groß. Bitte beachte, dass das auch eventuell vorhandene
                            Partnerlogos betreffen wird.
                        </Alert>

                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!mandantData.flagUseOldUi}
                                        onChange={onCheckboxChange}
                                        name="flagUseOldUi"
                                    />
                                }
                                label="Das alte UI verwenden (Umleitung auf beteiligung-steg.de)"
                            />
                        </FormGroup>
                        <Alert color="info" className="gap-bottom">
                            Es gibt die Möglichkeit noch das alte UI zu verwenden. Bitte beachte, dass du direkt nach
                            dem Speichern dann auf eine Neue Adresse mit dem alten UI umgeleitet wirst. Der Wechsel
                            zurück auf das neue UI kann nur über den Stegadminbereich rückgängig gemacht werden.
                        </Alert>

                        <Typography variant="h3" className="main-header gap-bottom">
                            Farbschema
                        </Typography>
                        <TextField
                            name="primaryColor"
                            label="primary-color"
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <div
                                            style={{
                                                backgroundColor: props.mandantData.primaryColor,
                                                color: "white",
                                            }}
                                        >
                                            Farbe
                                        </div>
                                    </InputAdornment>
                                ),
                            }}
                            value={props.mandantData.primaryColor}
                            onChange={props.onChange}
                            variant="outlined"
                        />
                        <InputLabel id="project">Projekt</InputLabel>
                        <Select
                            placeholder="Theme"
                            value={props.mandantData.theme ? props.mandantData.theme : "classic"}
                            id={"theme"}
                            name={"theme"}
                            fullWidth={true}
                            onChange={props.onChange}
                            variant="outlined"
                        >
                            <MenuItem key="empty" value="">
                                <em>Bitte ein Theme wählen</em>
                            </MenuItem>
                            {themes.map((theme: ISelectOption) => {
                                return (
                                    <MenuItem key={theme.id} value={theme.id}>
                                        {theme.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <Typography variant="h3" className="main-header gap-bottom">
                            E-Mails / Kontaktdaten
                        </Typography>
                        <TextField
                            name="contactEmail"
                            label="Standard Kontakt E-Mail"
                            fullWidth
                            value={mandantData.contactEmail ? mandantData.contactEmail : ""}
                            onChange={onChange}
                            variant="outlined"
                        />
                        <TextField
                            name="notifyEmailsElse"
                            label="Notify E-Mails"
                            fullWidth
                            multiline
                            value={mandantData.notifyEmailsElse ? mandantData.notifyEmailsElse : ""}
                            onChange={onChange}
                            variant="outlined"
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <Paper variant="outlined" className="main-paper gap-top">
                        <ImageUpload
                            onChange={onImageChange}
                            docData={mandantData.logoImage}
                            idKey={"logoImage"}
                            name={"logoImage"}
                            label={"Logo"}
                            defaultFilename="Logo.jpg"
                            hasFreeScale={true}
                        />
                        {mandantData.favIcon && (
                            <ImageUpload
                                onChange={onImageChange}
                                docData={mandantData.favIcon}
                                idKey={"favIcon"}
                                name={"favIcon"}
                                label={"Fav Icon"}
                                defaultFilename="FavIcon.jpg"
                                hasFreeScale={true}
                            />
                        )}
                        <ImageUpload
                            onChange={onImageChange}
                            docData={mandantData.headerImage}
                            idKey={"headerImage"}
                            name={"headerImage"}
                            label={"Standard Header Bild *"}
                            defaultFilename="StandardHeaderBild.jpg"
                            errorState={props.errorState.headerImage}
                            minSourceImageWidth={2000}
                            minSourceImageHeight={1125}
                            imageExportHeightInPixels={1125}
                            imageExportWidthInPixels={2000}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default MandantDataTab;
