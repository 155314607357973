import { Grid, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import SyncDisabledIcon from "@material-ui/icons/SyncDisabled";
import React, { useCallback, useEffect, useState } from "react";
import { arrayMove } from "react-sortable-hoc";
import uuid from "uuid-random";

import DocumentTypeEnum from "../../../../../../enums/DocumentTypeEnum";
import IDocument from "../../../../../../types/IDocument";
import InitialDocumentData from "../../../../../../types/initialData/InitialDocumentData";
import ImageUpload from "../../../../../imageUpload/ImageUpload";
import CmsImage from "../../../../renderer/content/components/CmsImage";
import { CmsContentBlock, CmsContentBlockCarousel } from "../../../../types/CmsPageData";
import { useCmsEditorContext } from "../../../masks/CmsEditorContext";
import { SortableListImage } from "../../sorting/download/SortableListImage";
import { CmsContentEditor } from "../CmsContentEditor";
import { CmsContentTypeProps } from "./CmsContentTypeProps";

export interface CmsContentAttachmentListProps extends CmsContentTypeProps {
    contentNode: CmsContentBlockCarousel;
}

export function CmsContentImageCarousel({
    contentNode,
    editMode,
    startEditMode,
    stopEditMode,
    showLayoutMenu,
}: CmsContentAttachmentListProps) {
    const { imagesIndex, setImagesIndex, setFormHasChangesAction, setContentIndex, contentIndex } =
        useCmsEditorContext();

    const [sortingEnabled, setSortingEnabled] = useState<boolean>(false);

    const updateContentNode = useCallback(
        (newContent: CmsContentBlock) => {
            setContentIndex({
                ...contentIndex,
                [contentNode.id]: {
                    ...newContent,
                } as CmsContentBlock,
            });
            setFormHasChangesAction();
        },
        [contentIndex, setContentIndex, contentNode]
    );

    useEffect(() => {
        if (!editMode) {
            if (!Array.isArray(contentNode.data) || contentNode.data.length == 0) {
                startEditMode();
            }
        }

        if (!Array.isArray(contentNode.data)) {
            const newRefId = uuid();

            updateContentNode({
                ...contentNode,
                data: [{ key: newRefId, type: DocumentTypeEnum.IMAGE }],
            });

            setImagesIndex({
                ...imagesIndex,
                [newRefId]: {
                    ...InitialDocumentData,
                    key: newRefId,
                    type: DocumentTypeEnum.IMAGE,
                },
            });
        }
    }, [contentNode.data, editMode]);

    const stopEditModeIfAllowed = () => {
        if (!Array.isArray(contentNode.data) || contentNode.data.length == 0) {
            return;
        }
        stopEditMode();
    };

    const handleImageChange = (attach: Partial<IDocument>) => {
        setImagesIndex({
            ...imagesIndex,
            ["" + attach.key]: { ...attach } as IDocument,
        });
        setFormHasChangesAction();
    };

    const addAdditionalImage = () => {
        const newRef = uuid();

        setContentIndex({
            ...contentIndex,
            [contentNode.id]: {
                ...contentNode,
                data: [...contentNode.data, { key: newRef, type: DocumentTypeEnum.IMAGE }],
            } as CmsContentBlock,
        });

        setImagesIndex({
            ...imagesIndex,
            [newRef]: { ...InitialDocumentData, key: newRef, type: 2 },
        });

        setFormHasChangesAction();
    };

    const removeAttachmentAction = (id: string) => {
        const index = contentNode.data.findIndex((check) => {
            return check.key === id;
        });

        contentNode.data.splice(index, 1);

        setContentIndex({
            ...contentIndex,
            [contentNode.id]: {
                ...contentNode,
                data: [...contentNode.data],
            } as CmsContentBlock,
        });

        const newIndex = {
            ...imagesIndex,
        };
        delete newIndex[id];

        setImagesIndex(newIndex);
        setFormHasChangesAction();
    };

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        setContentIndex({
            ...contentIndex,
            [contentNode.id]: {
                ...contentNode,
                data: arrayMove(contentNode.data, oldIndex, newIndex),
            } as CmsContentBlock,
        });
    };

    // the use effect will fix this
    if (!Array.isArray(contentNode.data)) {
        return <></>;
    }

    if (!editMode) {
        return (
            <Grid container spacing={2} direction="row" onClick={startEditMode}>
                {contentNode.data.map((ref, index) => {
                    const img = imagesIndex[ref.key];
                    return (
                        <Grid key={index + ""} item xs={12} md={6} lg={4}>
                            <CmsImage image={img} className="portal-cms-image" />
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    return (
        <CmsContentEditor
            stopEditMode={stopEditModeIfAllowed}
            contentNode={contentNode}
            updateContentNode={updateContentNode}
            showLayoutMenu={showLayoutMenu}
            additionalButtons={[
                <IconButton key="add-file" onClick={addAdditionalImage} color="primary" disabled={sortingEnabled}>
                    <AddIcon />
                </IconButton>,
                <IconButton
                    key="toggle-sorting"
                    onClick={() => {
                        setSortingEnabled(!sortingEnabled);
                    }}
                    title={sortingEnabled ? "Sortierung deaktivieren" : "Sortierung aktivieren"}
                    color="primary"
                >
                    {sortingEnabled ? <SyncDisabledIcon /> : <SyncIcon />}
                </IconButton>,
            ]}
        >
            <>
                {sortingEnabled && (
                    <SortableListImage
                        imageIndex={imagesIndex}
                        images={contentNode.data}
                        onSortEnd={onSortEnd}
                        axis="xy"
                    />
                )}
                {!sortingEnabled && (
                    <Grid container spacing={2}>
                        {contentNode.data.map((docRef, fileIndex: number) => {
                            const img = imagesIndex[docRef.key];
                            return (
                                <Grid item xs={6} key={fileIndex}>
                                    <ImageUpload
                                        onChange={handleImageChange}
                                        docData={img}
                                        idKey={contentNode.id}
                                        name={contentNode.id}
                                        label={"Bild"}
                                        defaultFilename="Bild.jpg"
                                        hasFreeScale
                                        withSizeCheck={true}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </>
        </CmsContentEditor>
    );
}
