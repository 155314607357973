import "./DashboardContainer.css";

import React from "react";
import * as router from "react-router-dom";

import PrivateRoute from "../../../routes/privateRoute/PrivateRoute";
import PublicRoute from "../../../routes/PublicRoute/PublicRoute";
import RoutesEnum from "../../../routes/RoutesEnum";
import IMandant from "../../../types/IMandant";
import AdminRoutesContainer from "../adminRoutesContainer/AdminRoutesContainer";
import LoginContainer from "../loginContainer/LoginContainer";

type IDashboardContainerProps = {
    mandant: IMandant;
};

const DashboardContainer = (props: IDashboardContainerProps) => {
    const { url } = router.useRouteMatch();

    return (
        <div>
            <router.Switch>
                {!props.mandant.flagHaveDomain && (
                    <PrivateRoute
                        path={"/:mandantKey" + RoutesEnum.ADMIN_HOME}
                        mandant={props.mandant}
                        component={AdminRoutesContainer}
                    />
                )}
                {props.mandant.flagHaveDomain && (
                    <PrivateRoute
                        path={RoutesEnum.ADMIN_HOME}
                        mandant={props.mandant}
                        component={AdminRoutesContainer}
                    />
                )}

                <PublicRoute path={url + "/login"} mandant={props.mandant} component={LoginContainer} />
            </router.Switch>
        </div>
    );
};

export default DashboardContainer;
