import React from "react";
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import LoadMandantAction from "../../store/mandantStore/LoadMandant";
import LoadProjectSelectbox from "../../store/projectSelectbox/LoadProjectSelectbox";
import IApplicationRootState from "../../store/StoreTypes";
import IUser from "../../store/userStore/interfaces/IUser";
import setUserFromStore from "../../store/userStore/userAction/SetUserFromStoreAction";
import { logout as logoutAction } from "../../store/userStore/userAction/UserAction";
import IMandant from "../../types/IMandant";
import { IMandantLoadingParameter } from "../../utils/getMandantLoadingParameter";
import { tokenRefreshRequest } from "../../xhr/UserRequests";

interface IMapStateProps {
    mandant: IMandant;
    user: IUser | null;
}

interface IMapDispatchProps {
    dispatchHandleLoadMandant: (mandantKey: string, isDomain: boolean) => void;
    dispatchLoadProjectSelectbox: (mandant: IMandant) => void;
    dispatchLogoutOn401: () => void;
    dispatchUserFromStore: (mandantKey: string) => void;
}

interface IOwnProps {
    loadingParameter?: IMandantLoadingParameter;
}

interface IMandantProviderProps extends IMapStateProps, IMapDispatchProps, IOwnProps {}

/** component */

const MandantProvider: React.FunctionComponent<IMandantProviderProps> = (props) => {
    const {
        loadingParameter,
        dispatchHandleLoadMandant,
        dispatchLoadProjectSelectbox,
        dispatchLogoutOn401,
        dispatchUserFromStore,
        mandant,
    } = props;

    React.useEffect(() => {
        if (loadingParameter?.mandantKey) {
            dispatchHandleLoadMandant(loadingParameter.mandantKey, false);
        } else if (loadingParameter?.domain) {
            dispatchHandleLoadMandant(loadingParameter.domain, true);
        }

        const interval = setInterval(() => {
            const oldToken = localStorage.getItem("jwt-" + loadingParameter) || "";

            const tokenTime = new Date(localStorage.getItem("expiresAt-" + loadingParameter) || "");
            const tokenTimeUtc = new Date(
                Date.UTC(
                    tokenTime.getFullYear(),
                    tokenTime.getMonth(),
                    tokenTime.getDate(),
                    tokenTime.getHours(),
                    tokenTime.getMinutes(),
                    tokenTime.getSeconds()
                )
            );

            const limitTime = new Date().getTime() + 300000; // 300000 milliseconds = 5 minutes

            if (loadingParameter?.mandantKey) {
                if (tokenTimeUtc.getTime() < limitTime) {
                    tokenRefreshRequest(loadingParameter?.mandantKey, {
                        token: oldToken,
                    })
                        .then((response) => {
                            localStorage.setItem("jwt-" + loadingParameter?.mandantKey, response.data.token);
                            localStorage.setItem("expiresAt-" + loadingParameter?.mandantKey, response.data.expiresAt);
                        })
                        .catch((err: any) => {
                            if (err?.response?.status === 401) {
                                localStorage.removeItem("jwt-" + loadingParameter);
                                localStorage.removeItem("expiresAt-" + loadingParameter);
                                dispatchLogoutOn401();
                                clearInterval(interval);
                            }
                        });
                }
            }
        }, 15000);
        return () => clearInterval(interval);
    }, [dispatchHandleLoadMandant, loadingParameter]);

    React.useEffect(() => {
        if (mandant.id) {
            dispatchUserFromStore(mandant.key);
            dispatchLoadProjectSelectbox(mandant);
        }
    }, [mandant, dispatchLoadProjectSelectbox]);

    return <></>;
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<IMapStateProps, IOwnProps, IApplicationRootState> = (
    state: IApplicationRootState
) => {
    return {
        mandant: state.mandant.mandant,
        user: state.user.userData,
    };
};
/**
 * for async func we only set handleOnLoginEvent: (params:any) => LoginAction(params)
 * for non async we use  handleOnLoginEvent: () => {dispatch(SomeActions())
 */
const mapDispatchToProps: MapDispatchToPropsParam<IMapDispatchProps, IOwnProps> = (
    dispatch: ThunkDispatch<{}, {}, Action>
) => {
    return {
        dispatchHandleLoadMandant: (mandantKey: string, isDomain: boolean) => {
            if (mandantKey === "") {
                return Promise.resolve();
            }

            return dispatch(LoadMandantAction(mandantKey, isDomain));
        },
        dispatchUserFromStore: (mandantKey: string) => {
            return dispatch(setUserFromStore(mandantKey));
        },
        dispatchLoadProjectSelectbox: (mandant: IMandant) => {
            return dispatch(LoadProjectSelectbox(mandant.key));
        },
        dispatchLogoutOn401: () => {
            return dispatch(logoutAction());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MandantProvider);
