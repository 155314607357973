import isEmail from "validator/lib/isEmail";

import ICampaign from "../../../../types/appointment/ICampaign";
import ICampaignRoom from "../../../../types/appointment/ICampaignRoom";

export type ICampaignValidationState = {
    hasError: boolean;
    title?: boolean;
    startDate?: boolean;
    endDate?: boolean;
    bookingEndDate?: boolean;
    managementEmail?: boolean;
    assistantEmail?: boolean;
    projectId?: boolean;
    roomAssignedEmail?: boolean;
};

export const requiredCampaignDataFilled = (campaign: ICampaign, nextRoom: ICampaignRoom): ICampaignValidationState => {
    const validationState: ICampaignValidationState = {
        hasError: false,
    };

    const startDate = Date.parse(campaign.startDate);
    const endDate = Date.parse(campaign.endDate);
    const bookingEndDate = Date.parse(campaign.bookingEndDate);

    if (campaign.title.length < 1) {
        validationState.title = true;
        validationState.hasError = true;
    }

    if (!campaign.project?.id) {
        validationState.projectId = true;
        validationState.hasError = true;
    }

    if (campaign.managementEmail.length < 1 || !isEmail(campaign.managementEmail)) {
        validationState.managementEmail = true;
        validationState.hasError = true;
    }

    if (campaign.assistantEmail.length < 1 || !isEmail(campaign.assistantEmail)) {
        validationState.assistantEmail = true;
        validationState.hasError = true;
    }

    if (nextRoom.assignedEmail && !isEmail(nextRoom.assignedEmail)) {
        validationState.roomAssignedEmail = true;
        validationState.hasError = true;
    }

    if (isNaN(startDate)) {
        validationState.startDate = true;
        validationState.hasError = true;
    }

    if (isNaN(endDate) || endDate < startDate) {
        validationState.endDate = true;
        validationState.hasError = true;
    }

    if (isNaN(bookingEndDate) || bookingEndDate > endDate || bookingEndDate < startDate) {
        validationState.bookingEndDate = true;
        validationState.hasError = true;
    }

    return validationState;
};
