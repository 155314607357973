import React from "react";
import { Route } from "react-router-dom";

import LoginContainer from "../../masks/admin/loginContainer/LoginContainer";
import IMandant from "../../types/IMandant";
import { isStillAuthenticated } from "../../utils";

const PrivateRoute: React.FC<{
    component: any;
    path: string;
    exact?: boolean;
    mandant: IMandant;
}> = (props) => {
    const condition = isStillAuthenticated(props.mandant.key);

    //const redirectUrl = toPortalUrl(props.mandant, "/datenverwaltung/login");

    return condition ? (
        <Route
            path={props.path}
            exact={props.exact}
            render={(renderProps: any) => <props.component {...renderProps} mandant={props.mandant} />}
        />
    ) : (
        <Route
            path={props.path}
            exact={props.exact}
            render={(renderProps: any) => <LoginContainer {...renderProps} mandant={props.mandant} />}
        />
    );
};
export default PrivateRoute;
