import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";

import { Format } from "../../../../config/config";
import ICampaignParticipantList from "../../../../types/appointment/ICampaignParticipantList";

export default function exportTableToExcel(tableRows: ICampaignParticipantList[]): Promise<void> {
    const workbook = new Excel.Workbook();
    const sheetName = `Teilnehmerin`;
    const sheet = workbook.addWorksheet(sheetName);
    const headerRow = [
        "UserId",
        "Ordnungsnummer",
        "Zugangstoken",
        "Datum",
        "Raum",
        "Anrede",
        "Vorname",
        "Nachname",
        "Strasse",
        "Hausnummer",
        "Postleitzahl",
        "Stadt",
        "Land",
        "Email",
        "Per Brief schicken",
        "Notifier Email gesendet",
        "Erinnerungsmail gesendet",
    ];

    workbook.created = new Date();
    workbook.views = [
        {
            activeTab: 1,
            firstSheet: 0,
            height: 20000,
            visibility: "visible",
            width: 30000,
            x: 0,
            y: 0,
        },
    ];

    sheet.addRow([]);
    const titleRow = sheet.addRow([sheetName]);
    sheet.addRow([]);
    const theHeadRow = sheet.addRow(headerRow);

    titleRow.font = {
        name: "Calibri",
        family: 4,
        size: 18,
        bold: true,
        color: { argb: "FF00677f" },
    };
    titleRow.height = 30;
    theHeadRow.font = { name: "Calibri", family: 4, size: 14, bold: true };

    for (let i = 0; i < tableRows.length; i++) {
        const col = sheet.getColumn(i + 1);
        const rowWidth = 25;
        col.width = rowWidth;
    }

    if (tableRows?.length > 0) {
        tableRows.forEach((tableRow) => {
            const rowData = [
                tableRow.user?.id,
                tableRow.orderNumber,
                tableRow.accessToken,
                tableRow.appointment?.startDate ? moment(tableRow.appointment?.startDate).format(Format.dateTime) : "",
                tableRow.appointment?.campaignRoom?.name,
                tableRow.user?.salutation,
                tableRow.user?.firstname,
                tableRow.user?.lastname,
                tableRow.user?.street,
                tableRow.user?.streetNo,
                tableRow.user?.zip,
                tableRow.user?.city,
                tableRow.user?.country,
                tableRow.email,
                tableRow?.flagSendPostalMail ? "Bitte Per Post" : "E-Mail reicht",
                tableRow?.flagNotifierMailSent ? "Per Post benachrichtigen" : "keine Benachrichtigung",
                tableRow?.flagReminderMailSent ? "Erinnerungsmail" : "keine Erinnerung",
            ];

            sheet.addRow(rowData);
        });
    } else {
        sheet.addRow(["keine Teilnehmer für den Export verfügbar."]);
    }

    return workbook.xlsx.writeBuffer().then((buf) => {
        return saveAs(new Blob([buf]), sheetName + ".xlsx");
    });
}
