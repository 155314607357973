import IProjectFull from "../../../types/IProjectFull";
import IProjectResult from "../../../types/IProjectResult";

export type IProjectValidationState = {
    hasError: boolean;
    title?: boolean;
    urlKey?: boolean;
    shortDesc?: boolean;
    theStart?: boolean;
    theEnd?: boolean;
    imageMenu?: boolean;
    imageHeader?: boolean;
    resultIndex?: number;
};

export type IProjectValidationStateWithDocumentAndImage = IProjectValidationState & {
    linkTitel?: boolean;
    description?: boolean;
    document?: boolean;
    previewImage?: boolean;
};

export type IProjectResultsValidationState = {
    hasError: boolean;
    resultIndex?: number;
    document?: boolean;
    previewImage?: boolean;
};

export const requiredProjectDataFilled = (project: IProjectFull): IProjectValidationState => {
    const validationState: IProjectValidationState = {
        hasError: false,
    };

    if (project.title.length < 1) {
        validationState.title = true;
        validationState.hasError = true;
    }

    if (project.urlKey.length < 1) {
        validationState.urlKey = true;
        validationState.hasError = true;
    }

    if (project.shortDesc.length < 1) {
        validationState.shortDesc = true;
        validationState.hasError = true;
    }

    if (isNaN(Date.parse(project.theStart))) {
        validationState.theStart = true;
        validationState.hasError = true;
    }

    if (project.imageMenu === null || (!project.imageMenu.data && !project.imageMenu.url)) {
        validationState.imageMenu = true;
        validationState.hasError = true;
    }

    if (project.imageHeader === null || (!project.imageHeader.data && !project.imageHeader.url)) {
        validationState.imageHeader = true;
        validationState.hasError = true;
    }
    return validationState;
};

export const requiredProjectResulatsDataFilled = (
    projectResults: IProjectResult[]
): IProjectValidationStateWithDocumentAndImage[] => {
    // Check if projectResults is null or empty
    if (!projectResults || projectResults.length === 0) {
        return [];
    }

    const validationStateArray: IProjectValidationStateWithDocumentAndImage[] = [];

    projectResults.forEach((result: IProjectResult, index: number) => {
        const validationState: IProjectValidationStateWithDocumentAndImage = {
            hasError: false,
        };
        if (projectResults === null || result.title.length < 1) {
            validationState.title = true;
            validationState.hasError = true;
        }
        if (projectResults === null || result.linkTitel.length < 1) {
            validationState.linkTitel = true;
            validationState.hasError = true;
        }
        if (projectResults === null || result.description.length < 1) {
            validationState.description = true;
            validationState.hasError = true;
        }
        if (projectResults === null || (!result.document.data && !result.document.url)) {
            validationState.document = true;
            validationState.hasError = true;
        }
        if (projectResults === null || (!result.previewImage.data && !result.previewImage.url)) {
            validationState.previewImage = true;
            validationState.hasError = true;
        }

        // Check if the current result has any errors
        if (Object.values(validationState).some((value) => value)) {
            validationStateArray.push({
                ...validationState,
                resultIndex: index,
            });
        }
    });
    return validationStateArray;
};
