import "./ProjectResultCard.css";

import { Box, Card, FormControl, Grid, Paper, TextField, Typography } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import { AddPhotoAlternateOutlined } from "@material-ui/icons";
import AttachmentIcon from "@material-ui/icons/Attachment";
import IconDelete from "@material-ui/icons/Delete";
import IconReplay from "@material-ui/icons/Replay";
import React from "react";

import { API_URL_STATIC } from "../../../../../config/config";
import IDocument from "../../../../../types/IDocument";
import IProjectResult from "../../../../../types/IProjectResult";
import { formatBytes } from "../../../../../utils/bytesConverter";
import { IProjectValidationStateWithDocumentAndImage } from "../../projectDataValidation";

interface IProjectResultCardProps {
    projectResult: IProjectResult;
    index: number;
    isSorting?: boolean;
    projectResultsErrorState?: IProjectValidationStateWithDocumentAndImage[];
    onChange: (projectResult: IProjectResult, index: number) => void;
    onDelete: (index: number) => void;
    onReset: (index: number, resultId: number) => void;
}
const ProjectResultCard = (props: IProjectResultCardProps) => {
    const { projectResult, index, isSorting, projectResultsErrorState, onChange, onDelete, onReset } = props;

    if (projectResult === null) {
        return <div>Projekt Ergebnis</div>;
    }
    const handleUploadImageClick = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
            onChange(
                {
                    ...projectResult,
                    previewImage: {
                        ...projectResult.previewImage,
                        data: reader.result,
                        name: file.name,
                        size: file.size,
                        type: file.type,
                    },
                    isChanged: true,
                },
                props.index
            );
        };
    };

    const handleUploadClick = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
            onChange(
                {
                    ...projectResult,
                    document: {
                        ...projectResult.document,
                        data: reader.result,
                        name: file.name,
                        size: file.size,
                        type: file.type,
                    },
                    isChanged: true,
                },
                props.index
            );
        };
    };

    const handleDocumentTextChange = (event: any) => {
        onChange(
            {
                ...projectResult,
                [event.target.name]: event.target.value,
                isChanged: true,
            },
            props.index
        );
    };

    /**
     * @param imageNode
     */
    const getImageSrc = (imageNode: IDocument) => {
        if (!imageNode) {
            return null;
        }

        if (imageNode.data) {
            return imageNode.data;
        }

        if (imageNode.url) {
            return API_URL_STATIC + imageNode.url;
        }

        return null;
    };

    const projectImageSource = getImageSrc(projectResult.previewImage);
    const projectResultSource = getImageSrc(projectResult.document);

    const sizeIsNotNull = (size?: number | null) => {
        if (size == null) {
            return false;
        }

        return size > 0;
    };
    const elementErrorState =
        projectResultsErrorState && projectResultsErrorState.find((el) => el.resultIndex === index);

    return (
        <Grid container item xs={12} md={6} lg={4} direction="column">
            <Card
                variant="outlined"
                style={{ height: "100%" }}
                className={
                    elementErrorState?.previewImage || elementErrorState?.document ? "preview-image-with-error" : ""
                }
            >
                <FormControl fullWidth>
                    <Paper elevation={0}>
                        <>
                            <input
                                accept="image/*"
                                id={"project-gallery-projectResult-upload-" + props.index}
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleUploadImageClick}
                            />

                            <label htmlFor={"project-gallery-projectResult-upload-" + props.index}>
                                {!projectImageSource && (
                                    <Fab
                                        className={
                                            elementErrorState?.previewImage || elementErrorState?.document
                                                ? "preview-image-with-error"
                                                : ""
                                        }
                                        component="span"
                                        style={{
                                            marginTop: "16px",
                                            marginLeft: "16px",
                                            marginBottom: "16px",
                                        }}
                                    >
                                        <AddPhotoAlternateOutlined />
                                    </Fab>
                                )}
                            </label>
                            {projectImageSource && (
                                <img style={{ maxWidth: "100%" }} src={projectImageSource} alt="Neuer Upload" />
                            )}
                        </>
                        <>
                            <input
                                accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                id={"project-gallery-attachements-upload-" + props.index}
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleUploadClick}
                            />

                            <label htmlFor={"project-gallery-attachements-upload-" + props.index}>
                                {!projectResultSource && (
                                    <Fab
                                        className={
                                            elementErrorState?.previewImage || elementErrorState?.document
                                                ? "preview-image-with-error"
                                                : ""
                                        }
                                        component="span"
                                        style={{
                                            marginTop: "16px",
                                            marginLeft: "16px",
                                            marginBottom: "16px",
                                        }}
                                    >
                                        <AttachmentIcon />{" "}
                                    </Fab>
                                )}
                            </label>
                            {projectResultSource && (
                                <div className="gap-left">
                                    <Typography component="div">{projectResult.document.name}</Typography>

                                    {sizeIsNotNull(projectResult.document.size) && (
                                        <Typography>Dateigröße : {formatBytes(projectResult.document.size)}</Typography>
                                    )}
                                </div>
                            )}
                        </>
                    </Paper>
                    <Box m={0} padding={2}>
                        <Paper elevation={0}>
                            <TextField
                                error={elementErrorState?.title}
                                variant="outlined"
                                label="Überschrift"
                                name="title"
                                required={true}
                                value={projectResult.title || ""}
                                onChange={handleDocumentTextChange}
                                fullWidth
                                disabled={isSorting}
                            />

                            <TextField
                                error={elementErrorState?.linkTitel}
                                variant="outlined"
                                label="Link Text"
                                name="linkTitel"
                                required={true}
                                value={projectResult.linkTitel || ""}
                                onChange={handleDocumentTextChange}
                                fullWidth
                                disabled={isSorting}
                            />

                            <TextField
                                error={elementErrorState?.description}
                                variant="outlined"
                                label="Beschreibung"
                                name="description"
                                required={true}
                                value={projectResult.description || ""}
                                onChange={handleDocumentTextChange}
                                fullWidth
                                multiline
                                disabled={isSorting}
                            />
                        </Paper>
                        {!isSorting && (
                            <IconButton
                                className="event_form-button"
                                onClick={() => {
                                    onDelete(index);
                                }}
                                title="Löschen"
                            >
                                <IconDelete />
                            </IconButton>
                        )}

                        {projectResult.isChanged && projectResult.id != null && (
                            <IconButton
                                className="event_form-button"
                                color="secondary"
                                title="Zurücksetzen"
                                onClick={() => {
                                    onReset(index, parseInt("" + projectResult.id));
                                }}
                            >
                                <IconReplay />
                            </IconButton>
                        )}
                    </Box>
                </FormControl>
            </Card>
        </Grid>
    );
};
export default ProjectResultCard;
