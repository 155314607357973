import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@material-ui/core";
import React from "react";

import toInputString from "../../../../utils/form/toInputString";

export interface IChangeAdminPasswordForm {
    password?: string;
    passwordCheck?: string;
    activeMandant?: string;
}

type IChangeAdminPasswordModalState = {
    formData: IChangeAdminPasswordForm;
};

type IOwnProps = {
    isOpen: boolean;
    setClose: () => void;
    resetAdminPasswortAction: (formData: IChangeAdminPasswordForm) => void;
    activeMandant?: string;
};

type IChangeAdminPasswordModalProps = IOwnProps;

class ChangeAdminPasswordModal extends React.Component<IChangeAdminPasswordModalProps, IChangeAdminPasswordModalState> {
    constructor(props: IChangeAdminPasswordModalProps) {
        super(props);

        this.state = {
            formData: {
                password: "",
                passwordCheck: "",
                activeMandant: props.activeMandant,
            },
        };
    }

    private onCancel = () => {
        this.props.setClose();
    };

    private onSave = () => {
        this.props.resetAdminPasswortAction(this.state.formData);
    };

    private onChangePassword = (event: any) => {
        this.setState({
            formData: {
                ...this.state.formData,
                password: event.target.value,
            },
        });
    };

    private onChangePasswordCheck = (event: any) => {
        this.setState({
            formData: {
                ...this.state.formData,
                passwordCheck: event.target.value,
            },
        });
    };

    public render = () => {
        const { isOpen, setClose } = this.props;

        return (
            <Dialog open={isOpen} onClose={setClose} className={"app-crud-form"}>
                <DialogTitle id="simple-dialog-title">Adminpasswort für das Portal zurücksetzen</DialogTitle>

                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={12} lg={12}>
                            <TextField
                                name="password"
                                label="Passwort"
                                variant="outlined"
                                type="password"
                                fullWidth
                                onChange={this.onChangePassword}
                                value={toInputString(this.state.formData?.password)}
                            />
                        </Grid>
                        <Grid item sm={12} md={12} lg={12}>
                            <TextField
                                name="passwordCheck"
                                label="Passwort zur Sicherheit wiederholen"
                                variant="outlined"
                                type="password"
                                fullWidth
                                error={this.state.formData?.passwordCheck !== this.state.formData?.password}
                                onChange={this.onChangePasswordCheck}
                                value={toInputString(this.state.formData?.passwordCheck)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onCancel}>Abbrechen</Button>
                    <Button onClick={this.onSave} color="primary">
                        Adminpasswort zurücksetzen
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
}

export default ChangeAdminPasswordModal;
