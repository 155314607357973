import React from "react";
import { Route } from "react-router-dom";

import IMandant from "../../types/IMandant";

type IPublicRouteArgs = {
    component: any;
    path: string;
    mandant: IMandant;
};
const PublicRoute = ({ mandant, component: Component, ...rest }: IPublicRouteArgs) => {
    return <Route {...rest} render={(props: any) => <Component {...props} />} />;
};

export default PublicRoute;
