import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import IMandant from "../../../types/IMandant";
import { loginRequest } from "../../../xhr/UserRequests";
import IApplicationRootState from "../../StoreTypes";
import { loginError, loginPending, loginSuccess } from "../userAction/UserAction";

const LoginAction = (
    email: string,
    password: string,
    mandant: IMandant
): ThunkAction<void, IApplicationRootState, {}, Action> => {
    return async (dispatch: ThunkDispatch<{}, {}, Action>, getState: () => IApplicationRootState) => {
        dispatch(loginPending());
        try {
            dispatch(loginError(""));
            // since mandant is not set for the moment we can get it using getState from redux-thunk

            const userData = await loginRequest({ username: email, password }, mandant);
            //can make status check here
            localStorage.setItem("jwt-" + mandant.key, userData.data.loginToken.token);
            localStorage.setItem("expiresAt-" + mandant.key, userData.data.loginToken.expiresAt);
            localStorage.setItem("user-" + mandant.key, JSON.stringify(userData.data));
            dispatch(loginSuccess(userData.data));
        } catch (err) {
            // remove the items
            localStorage.removeItem("jwt-" + mandant.key);
            localStorage.removeItem("expiresAt-" + mandant.key);
            localStorage.removeItem("user-" + mandant.key);

            dispatch(loginError("Der Login ist fehlgeschlagen"));
        }
    };
};

export default LoginAction;
