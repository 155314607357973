import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";

import { Format } from "../../../../config/config";
import IMapProposal from "../../../../types/IMapProposal";

export default function exportMapComments(tableRows: IMapProposal[]): Promise<void> {
    const workbook = new Excel.Workbook();
    const sheetName = `Kommentare`;
    const sheet = workbook.addWorksheet(sheetName);
    const headerRow = ["ID", "Erstell Datum", "Kategorie", "Kommentar", "Antwort", "latitude", "longitude", "Projekt"];

    workbook.created = new Date();
    workbook.views = [
        {
            activeTab: 1,
            firstSheet: 0,
            height: 20000,
            visibility: "visible",
            width: 30000,
            x: 0,
            y: 0,
        },
    ];

    sheet.addRow([]);
    const titleRow = sheet.addRow([sheetName]);
    sheet.addRow([]);
    const theHeadRow = sheet.addRow(headerRow);

    titleRow.font = {
        name: "Calibri",
        family: 4,
        size: 18,
        bold: true,
        color: { argb: "FF00677f" },
    };
    titleRow.height = 30;
    theHeadRow.font = { name: "Calibri", family: 4, size: 14, bold: true };

    for (let i = 0; i < headerRow.length; i++) {
        const col = sheet.getColumn(i + 1);
        col.width = 25;
    }

    if (tableRows?.length > 0) {
        tableRows.forEach((tableRow) => {
            const rowData = [
                tableRow?.id,
                moment(tableRow.creationDate).format(Format.dateTime),
                tableRow.type?.label ? tableRow.type?.label : "",
                tableRow.comment,
                tableRow.answer,
                tableRow.latitude,
                tableRow.longitude,
                tableRow.project?.shortTitle,
            ];

            sheet.addRow(rowData);
        });
    } else {
        sheet.addRow(["Es sind keine Kommentare für das aktuelle Projekt verfügbar"]);
    }

    return workbook.xlsx.writeBuffer().then((buf) => {
        return saveAs(new Blob([buf]), sheetName + ".xlsx");
    });
}
