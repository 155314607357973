import { ProjectTabEnum } from "../../masks/public/projectData/ProjectTabEnum";
import IProjectFull from "../../types/IProjectFull";
import { IProjectHeaderData } from "./PortalHeader";

const getEither = (content: string | undefined, fallbackContent: string | undefined): string => {
    if (content != null && content !== "") {
        return content;
    }

    return String(fallbackContent);
};

const getProjectHeaderData = (activeProject: IProjectFull, activeTab?: string): IProjectHeaderData => {
    // active tab might be empty
    const theTab = activeTab ? activeTab : ProjectTabEnum.START_TAB;

    switch (theTab) {
        case ProjectTabEnum.START_TAB: {
            return {
                title: activeProject?.titleTabStart,
                desc: activeProject?.descTabStart,
                header: activeProject?.headerTabStart ? activeProject?.headerTabStart : activeProject?.imageHeader,
            };
        }
        case ProjectTabEnum.INFO_TAB: {
            return {
                title: activeProject?.titleTabInfo,
                desc: activeProject?.descTabInfo,
                header: activeProject?.headerTabInfo,
            };
        }
        case ProjectTabEnum.EVENTS_TAB: {
            return {
                title: getEither(activeProject?.titleTabEvents, activeProject?.titleTabStart),
                desc: getEither(activeProject?.descTabEvents, activeProject.descTabStart),
                header: activeProject?.headerTabEvents ? activeProject?.headerTabEvents : activeProject?.imageHeader,
            };
        }
        case ProjectTabEnum.PARTICIPATE_TAB: {
            return {
                title: getEither(activeProject?.titleTabParticipate, activeProject?.titleTabStart),
                desc: getEither(activeProject?.descTabParticipate, activeProject.descTabStart),
                header: activeProject?.headerTabParticipate
                    ? activeProject?.headerTabParticipate
                    : activeProject?.imageHeader,
            };
        }
        case ProjectTabEnum.TEAM_TAB: {
            return {
                title: getEither(activeProject?.titleTabTeam, activeProject?.titleTabStart),
                desc: getEither(activeProject?.descTabTeam, activeProject.descTabStart),
                header: activeProject?.headerTabTeam ? activeProject?.headerTabTeam : activeProject?.imageHeader,
            };
        }
        case ProjectTabEnum.CONSULTING_TAB: {
            return {
                title: getEither(activeProject?.titleTabConsulting, activeProject?.titleTabStart),
                desc: getEither(activeProject?.descTabConsulting, activeProject.descTabStart),
                header: activeProject?.headerTabConsulting
                    ? activeProject?.headerTabConsulting
                    : activeProject?.imageHeader,
            };
        }
        case ProjectTabEnum.CAMPAIGN_TAB: {
            return {
                title: getEither(activeProject?.titleTabCampaign, activeProject?.titleTabStart),
                desc: getEither(activeProject?.descTabCampaign, activeProject.descTabStart),
                header: activeProject?.headerTabCampaign
                    ? activeProject?.headerTabCampaign
                    : activeProject?.imageHeader,
            };
        }
        case ProjectTabEnum.RESULTS_TAB: {
            return {
                title: getEither(activeProject?.titleTabResults, activeProject?.titleTabStart),
                desc: getEither(activeProject?.descTabResults, activeProject.descTabStart),
                header: activeProject?.headerTabResults ? activeProject?.headerTabResults : activeProject?.imageHeader,
            };
        }
        default: {
            return {
                title: activeProject?.titleTabStart,
                desc: activeProject?.descTabStart,
                header: activeProject?.headerTabStart ? activeProject?.headerTabStart : activeProject?.imageHeader,
            };
        }
    }
};

export default getProjectHeaderData;
