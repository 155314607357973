import { AxiosResponse } from "axios";

import {
    ADMIN_POLL_CAMPAIGN,
    ADMIN_POLL_CAMPAIGN_ACTIVE,
    ADMIN_POLL_CAMPAIGN_IN_ACTIVE,
    PORTAL_POLL_CAMPAIGN,
    VOTE,
} from "../enums/Endpoints";
import IElseVote from "../types/IElseVote";
import IPollCampaign from "../types/IPollCampaign";
import IPollCampaignList from "../types/IPollCampaignList";
import IVote from "../types/IVote";
import { getData, postData, putData } from "../utils/httpRequest/request";

/**
 * Portal get list poll
 */
export const loadAdminPollCampaignList = (mandant: string): Promise<AxiosResponse<IPollCampaignList[]>> => {
    return getData<IPollCampaignList[]>(ADMIN_POLL_CAMPAIGN, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

/**
 * get the single Campaign for the Portal
 */
export const loadAdminSingleCampaignRequest = (
    mandant: string,
    campaignId: number
): Promise<AxiosResponse<IPollCampaignList>> => {
    return getData<IPollCampaignList>(`${ADMIN_POLL_CAMPAIGN}${campaignId}`, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

/**
 * Portal get single poll
 */
export const loadPortalSinglePollRequest = (
    mandant: string,
    projectId: string
): Promise<AxiosResponse<IPollCampaignList>> => {
    return getData<IPollCampaignList>(`${PORTAL_POLL_CAMPAIGN}${projectId}`, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

/* portal Vote with existing option  */
export const campaignVoteRequest = (mandant: string, voteData: IVote, voteId: number): Promise<AxiosResponse<any>> => {
    return postData<IVote, any>(`${PORTAL_POLL_CAMPAIGN}${VOTE}${voteId}`, voteData, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};
/* portal Vote with new option Text */
export const campaignVoteElseRequest = (
    mandant: string,
    voteData: IElseVote,
    voteId: number
): Promise<AxiosResponse<any>> => {
    return postData<IElseVote, any>(`${PORTAL_POLL_CAMPAIGN}${VOTE}${voteId}`, voteData, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

/**
 * Create a new PollCampaign Request
 * @param mandant
 * @param pageData
 */
export const createPollCampaignRequest = (mandant: string, pageData: IPollCampaign): Promise<any> => {
    return postData<IPollCampaign, IPollCampaign>(ADMIN_POLL_CAMPAIGN, pageData, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

/**
 * Update Poll Request
 * @param mandant
 * @param campaignData
 */
export const updatePollCampaignRequest = (mandant: string, campaignData: IPollCampaign): Promise<any> => {
    return putData<IPollCampaign, IPollCampaign>(ADMIN_POLL_CAMPAIGN, campaignData.id + "", campaignData, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

/**
 * Update Poll Request
 */
export const setCampaignActiveRequest = (mandant: string, campaignId: number): Promise<any> => {
    return putData<any, any>(
        ADMIN_POLL_CAMPAIGN_ACTIVE,
        campaignId + "",
        {},
        {
            mandant,
            isPublic: false,
            withLang: true,
        }
    );
};

/**
 * Update Poll Request
 */
export const setCampaignInActiveRequest = (mandant: string, campaignId: number): Promise<any> => {
    return putData<any, any>(
        ADMIN_POLL_CAMPAIGN_IN_ACTIVE,
        campaignId + "",
        {},
        {
            mandant,
            isPublic: false,
            withLang: true,
        }
    );
};

/**
 * Update the comment
 * /
export const updateCommentRequest = (
    mandant: string,
    commentId: number,
    commentData: IPortalComment
): Promise<AxiosResponse<IPortalComment>> => {
    return putData<IPortalComment, IPortalComment>(
        PORTAL_COMMENT,
        commentId,
        commentData,
        {
            mandant,
            isPublic: false,
            withLang: true,
        }
    );
};

/**
 * Delete the comment request
 * /
export const deleteCommentRequest = (
    mandant: string,
    commentId: number
): Promise<AxiosResponse<any>> => {
    return deleteData<any>(PORTAL_COMMENT, commentId, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
*/
