import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import { getMandantByDomain } from "../../utils/httpRequest/request";
import { loadMandantRequest } from "../../xhr/MandantRequests";
import { loadMandantError, loadMandantSuccess } from "./MandantAction";

const LoadMandant = (mandantKey: string, isDomain = false): ThunkAction<void, {}, {}, Action> => {
    return async (dispatch: ThunkDispatch<{}, {}, Action>) => {
        try {
            const mandantRequest = isDomain
                ? await getMandantByDomain(mandantKey)
                : await loadMandantRequest(mandantKey);

            dispatch(loadMandantSuccess(mandantRequest.data));

            // auto redirect to the old ui if the new ui hast the flag
            if (mandantRequest?.data?.flagUseOldUi) {
                window.location.href = "https://beteiligung-steg.de/" + mandantKey + "/";
            }
        } catch (err) {
            dispatch(loadMandantError(err.text));
            const baseUrl = window.location.origin;
            window.location.href = baseUrl;
        }
    };
};

export default LoadMandant;
