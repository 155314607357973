import ICampaignRoom from "../../../../types/appointment/ICampaignRoom";

export type IRoomValidationState = {
    hasError: boolean;
    roomName?: boolean;
};
export const requiredRoomDataFilled = (Room: ICampaignRoom): IRoomValidationState => {
    const validationState: IRoomValidationState = {
        hasError: false,
    };
    if (!Room.name || Room.name.trim().length < 1) {
        validationState.roomName = true;
        validationState.hasError = true;
    }
    return validationState;
};
