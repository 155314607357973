import "./Footer.css";

import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

import AdminShowContentModeFooter from "../../components/adminShowContentModeFooter/AdminShowContentModeFooter";
import HtmlContent from "../../components/cms/renderer/content/components/HtmlContent";
import FooterContact from "../../components/contact/FooterContact";
import CookiesConsent from "../../components/cookiesConsent/CookiesConsent";
import RoutesEnum from "../../routes/RoutesEnum";
import IMandant from "../../types/IMandant";
import { toPortalUrl } from "../../utils/toPortalUrl";
import PartnerLogoList from "./PartnerLogoList";

interface IFooterHeaderParams {
    mandant: IMandant;
    activeProjectKey?: string;
}

const PortalFooter = (props: IFooterHeaderParams) => {
    const { mandant, activeProjectKey } = props;
    const history = useHistory();
    const isPreview = window.name === "preview";
    const onLinkClick = (event: React.SyntheticEvent<HTMLSpanElement>) => {
        event.preventDefault();
        history.push(toPortalUrl(mandant, "" + event.currentTarget.dataset.page));
    };

    const getLinkUrl = (pageKey: string) => {
        if (activeProjectKey) {
            return activeProjectKey + "/seite/" + pageKey;
        } else {
            return RoutesEnum.PORTAL_PAGE + "/" + pageKey;
        }
    };

    return (
        <>
            <div className="App-footer">
                <Grid className="App-footer-outer" container>
                    <Grid item xs={12} lg={12}>
                        <Typography variant="h3" className="gap-bottom gap-top">
                            {mandant.footerLeftTitel}
                        </Typography>

                        <HtmlContent content={"" + mandant.footerLeftText} />
                    </Grid>

                    <Grid item xs={12} lg={12} className="gap-bottom_2 gap-top-2">
                        {!mandant.flagDisabelFooterContact && <FooterContact />}
                        {/* <LogosGrid /> */}
                    </Grid>

                    <Grid item xs={12} lg={6} className="gap-top">
                        <a
                            data-page={getLinkUrl("impressum")}
                            href={toPortalUrl(mandant, getLinkUrl("impressum"))}
                            onClick={onLinkClick}
                        >
                            Impressum
                        </a>
                        <br />
                        <a
                            data-page={getLinkUrl("datenschutz")}
                            href={toPortalUrl(mandant, getLinkUrl("datenschutz"))}
                            onClick={onLinkClick}
                        >
                            Datenschutzerklärung
                        </a>
                        <br />
                        <a
                            data-page={getLinkUrl("nutzungsbedingungen")}
                            href={toPortalUrl(mandant, getLinkUrl("nutzungsbedingungen"))}
                            onClick={onLinkClick}
                        >
                            Nutzungsbedingungen
                        </a>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} className="gap-top steg-copyright">
                        <a href="https://steg.de">© {new Date().getFullYear()} die STEG</a>
                    </Grid>
                    <PartnerLogoList mandant={mandant} />
                </Grid>
            </div>
            <CookiesConsent mandant={mandant} />
            {isPreview && <AdminShowContentModeFooter />}
        </>
    );
};

export default PortalFooter;
