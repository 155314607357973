import { AxiosResponse } from "axios";

import { ADMIN_ACTIVE_CAMPAIGN, ADMIN_APPOINTMENT_SINGLE, ADMIN_CAMPAIGN } from "../enums/Endpoints";
import IAppointmentBulk from "../types/appointment/IAppointmentBulk";
import ICampaign from "../types/appointment/ICampaign";
import ICampaignAppointmentList from "../types/appointment/ICampaignAppointmentList";
import ICampaignList from "../types/appointment/ICampaignList";
import { deleteData, getData, postData, putData } from "../utils/httpRequest/request";

/**
 * Portal get list campaign
 */
export const loadAdminCampaignList = (mandant: string): Promise<AxiosResponse<ICampaignList[]>> => {
    return getData<ICampaignList[]>(ADMIN_CAMPAIGN, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

/**
 * Portal get list appointments
 */
export const loadCampaignAppointments = (
    mandant: string,
    campaignId: string | number
): Promise<AxiosResponse<ICampaignAppointmentList[]>> => {
    return getData<ICampaignAppointmentList[]>(`${ADMIN_CAMPAIGN}/appointment/${campaignId}`, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

/**
 * get the single Campaign for the Portal
 */
export const loadAdminSingleCampaignRequest = (
    mandant: string,
    campaignId: number
): Promise<AxiosResponse<ICampaign>> => {
    return getData<ICampaign>(`${ADMIN_CAMPAIGN}${campaignId}`, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

/**
 * Get project campaign request
 */
export const loadProjectCampaignDataRequest = (mandant: string, key: string): Promise<any> => {
    return getData<ICampaign>(`${ADMIN_CAMPAIGN}/campaign-by-key/${key}`, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

/**
 * Portal get active campaign
 */
export const loadActiveCampaignRequest = (mandant: string, projectId: string): Promise<AxiosResponse<ICampaign>> => {
    return getData<ICampaign>(`${ADMIN_ACTIVE_CAMPAIGN}/${projectId}`, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

/**
 * Create a new Campaign Request
 * @param mandant
 * @param campaignData
 */
export const createCampaignRequest = (mandant: string, campaignData: ICampaign): Promise<any> => {
    return postData<ICampaign, ICampaign>(ADMIN_CAMPAIGN, campaignData, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

/**
 * Generation appointments bulk Request
 * @param mandant
 * @param campaignData
 */
export const createAppointmentBulkRequest = (
    mandant: string,
    campaignData: IAppointmentBulk
): Promise<AxiosResponse<ICampaignAppointmentList[]>> => {
    return postData<IAppointmentBulk, ICampaignAppointmentList[]>(
        `${ADMIN_APPOINTMENT_SINGLE}/create-bulk/`,
        campaignData,
        {
            mandant,
            isPublic: false,
            withLang: true,
        }
    );
};

/**
 * Update Campaign Request
 * @param mandant
 * @param campaignData
 */
export const updateCampaignRequest = (mandant: string, campaignData: ICampaign): Promise<any> => {
    return putData<ICampaign, ICampaign>(ADMIN_CAMPAIGN, campaignData.id + "", campaignData, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
/**
 * Delete Campaign Request
 */
export const deleteCampaignRequest = (mandant: string, campaignId: string): Promise<AxiosResponse<any>> => {
    return deleteData<any>(ADMIN_CAMPAIGN, campaignId, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};
