import {
    Breadcrumbs,
    Button,
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Paper,
    Select,
    Theme,
    Typography,
    createStyles,
    makeStyles,
} from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Notify from "../../../components/notify/Notify";
import AdminProposalMap from "../../../components/olMap/adminProposal/AdminProposalMap";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import IUser from "../../../store/userStore/interfaces/IUser";
import IMandant from "../../../types/IMandant";
import IMapProposal from "../../../types/IMapProposal";
import InitialMapProposal from "../../../types/initialData/InitialMapProposal";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../types/ISnackbarState";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import { deleteProjectProposalRequest, loadProposalsList } from "../../../xhr/ProposalRequests";
import exportMapComments from "./logic/exportMapComments";
import TableWithPagination from "./TablePagination";

type IMapStateProps = {
    mandant: IMandant;
    activeUser: IUser | null;
    projectList: any;
};

type IMapDispatchProps = {};

type IOwnProps = {};

type IMapProposalViewerParams = IMapStateProps & IMapDispatchProps & IOwnProps;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 250,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    })
);

const MapProposalViewer = (props: IMapProposalViewerParams) => {
    const classes = useStyles();
    const { mandant, projectList } = props;
    const [actualProject, setProject] = React.useState("");

    const history = useHistory();
    const confirm = useConfirm();

    const [proposals, setProposals] = React.useState<IMapProposal[]>([]);
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });
    const [actualProposal, setActualProposal] = React.useState<IMapProposal>({
        ...InitialMapProposal,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    React.useEffect(() => {
        if (actualProject !== "") {
            loadProposalsList(mandant.key, actualProject)
                .then((result: any) => {
                    setProposals(result.data);
                    return Promise.resolve();
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Beim Laden der Anfragen ist ein Fehler aufgetreten ",
                        type: "error",
                    });
                });
        }
        return () => {
            setProposals([]);
        };
    }, [mandant, actualProject]);

    if (!mandant.id) {
        return <></>;
    }

    /*  const actionDeleteComment = (event: SyntheticEvent<HTMLSpanElement>) => {
        const commentId: number = parseInt("" + event.currentTarget.dataset.id);

        deleteProposalRequest(mandant.key, proposalId)
            .then((response: any) => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Umfrage wurde erfolgreich gelöscht",
                    type: "success",
                });

                loadAdminPollCampaignList(mandantKey)
                    .then((result: any) => {
                        setProposalList(result.data);
                        return Promise.resolve();
                    })
                    .catch((error: any) => {
                        setSnackBarState({
                            isOpen: true,
                            message:
                                "Beim Laden der Umfragen ist ein Fehler aufgetreten",
                            type: "error",
                        });
                    });
            })
            .catch((error: any) => {
                setSnackBarState({
                    isOpen: true,
                    message:
                        "Beim Löschen der Umfrage ist ein Fehler aufgetreten",
                    type: "error",
                });
            });
    };*/

    function handleBread(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }
    const handleProjectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setProject(event.target.value as string);
    };
    const handleOnProposalClicked = (proposal: IMapProposal) => {
        setActualProposal(proposal);
    };

    const handleDeleteProposal = (event: any) => {
        const id = event.currentTarget.dataset.id;

        confirm({
            description: "Möchten Sie diesen Kommentar wirklich löschen?",
            confirmationText: "Ja",
            cancellationText: "Nein",
            title: "Bitte bestätigen",
        })
            .then(() => {
                deleteProjectProposalRequest(id, mandant.key)
                    .then(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Der Kommentar wurde gelöscht.",
                            type: "success",
                        });
                        loadProposalsList(mandant.key, actualProject)
                            .then((listResp: any) => {
                                setProposals(listResp.data);
                            })
                            .catch(() => {
                                setSnackBarState({
                                    isOpen: true,
                                    message: "Die aktualisierte Liste konnte nicht geladen werden",
                                    type: "error",
                                });
                            });
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Beim Löschen des Kommentars ist ein Fehler aufgetreten.",
                            type: "error",
                        });
                    });
            })
            .catch(() => {
                return;
            });
    };

    const handleExcelExport = () => {
        exportMapComments(proposals);
    };

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    onClick={handleBread}
                >
                    Start
                </Link>
                <Typography color="textPrimary">Projektbasiertes Geofeedback</Typography>
            </Breadcrumbs>

            <Typography variant={"h3"} className="main-header gap-top">
                Projektbasiertes Geofeedback
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Projekt</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={actualProject}
                    onChange={handleProjectChange}
                    label="Projekt"
                >
                    {projectList &&
                        projectList.length > 0 &&
                        projectList.map((elem: any, index: number) => (
                            <MenuItem key={elem.id + index} value={elem.id}>
                                {elem.label}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <Button startIcon={<CloudDownload />} onClick={handleExcelExport} color="primary">
                {" "}
                Daten für aktuelles Projekt Exportieren
            </Button>
            <Grid container spacing={2} style={{ height: "80vh" }}>
                <Grid item xs={8}>
                    <Paper variant="outlined" className="map-card">
                        <Typography variant={"h3"} className="main-header ">
                            Vorschläge in der Karte
                        </Typography>

                        <TableWithPagination
                            tableData={proposals}
                            onDeleteClick={handleDeleteProposal}
                            onProposalClick={handleOnProposalClicked}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper variant="outlined" className="map-card">
                        <Typography variant="h3">Verortung auf der Karte</Typography>
                        <AdminProposalMap proposals={proposals} activeProposal={actualProposal} />
                    </Paper>
                </Grid>
            </Grid>
            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<IMapStateProps, IOwnProps, IApplicationRootState> = (
    state: IApplicationRootState
) => {
    return {
        mandant: state.mandant.mandant,
        activeUser: state.user.userData,
        projectList: state.projectSelectbox.projectData,
    };
};

export default connect(mapStateToProps)(MapProposalViewer);
