import {
    Breadcrumbs,
    Button,
    Grid,
    IconButton,
    LinearProgress,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";

import Notify from "../../../components/notify/Notify";
import { Format } from "../../../config/config";
import RoleEnum from "../../../enums/RoleEnum";
import RoutesEnum from "../../../routes/RoutesEnum";
import IMandant from "../../../types/IMandant";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../types/ISnackbarState";
import IUser from "../../../types/IUser";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import { deleteUserRequest, loadUserListRequest } from "../../../xhr/UserRequests";

interface IUserListingProps {
    mandant: IMandant;
}

const UserListing = (props: IUserListingProps) => {
    const history = useHistory();
    const { mandant } = props;
    const [userList, setUserList] = React.useState<IUser[]>([]);
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    React.useEffect(() => {
        setIsLoading(true);
        loadUserListRequest(mandant.key)
            .then((result: any) => {
                setUserList(result.data);
                setIsLoading(false);
                return Promise.resolve();
            })
            .catch(() => {
                setIsLoading(false);
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Laden der Benutzerdaten ist ein Fehler aufgetreten",
                    type: "error",
                });
            });
    }, [mandant.key]);

    if (!mandant.id) {
        return <></>;
    }

    const actionEditUser = (event: React.SyntheticEvent<HTMLSpanElement>) => {
        history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_USER + event.currentTarget.dataset.id));
    };

    const onCreateNewUserClick = () => {
        history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_USER + "create"));
    };

    const actionDeleteUser = (event: React.SyntheticEvent<HTMLSpanElement>) => {
        setIsLoading(true);
        deleteUserRequest(mandant.key, parseInt("" + event.currentTarget.dataset.id))
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Der Benutzer wurde erfolgreich gelöscht",
                    type: "success",
                });

                loadUserListRequest(mandant.key)
                    .then((result: any) => {
                        setUserList(result.data);
                        setIsLoading(false);
                        return Promise.resolve();
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Beim Laden der Benutzerdaten ist ein Fehler aufgetreten",
                            type: "error",
                        });
                        setIsLoading(false);
                    });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Löschen des Benutzers ist ein Fehler aufgetreten",
                    type: "error",
                });

                setIsLoading(false);
            });
    };

    const roleLabelMap: { [key: string]: string } = {
        [RoleEnum.ROLE_ADMIN]: "Admin",
        [RoleEnum.ROLE_PROJECT_MANAGER]: "PM",
        [RoleEnum.ROLE_CONTENT_MANAGER]: "CM",
        [RoleEnum.ROLE_PROJECT_MODERATOR]: "Mod",
        [RoleEnum.ROLE_PORTAL]: "Portal",
    };

    const mapRoleToLabel = (roles: string[]) => {
        const rolesText = roles
            .map((role: string): string => {
                return roleLabelMap[role];
            })
            .join(", ");

        // why the fuck do i have to remove the comma
        return rolesText.substr(0, rolesText.length - 2);
    };

    function handleBread(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                {mandant.key !== "stegadmin" && (
                    <Link
                        color="inherit"
                        href={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                        data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                        onClick={handleBread}
                    >
                        Start
                    </Link>
                )}
                {mandant.key === "stegadmin" && (
                    <Link
                        color="inherit"
                        href={toPortalUrl(mandant, RoutesEnum.ADMIN_PORTAL_LIST)}
                        data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_PORTAL_LIST)}
                        onClick={handleBread}
                    >
                        Start
                    </Link>
                )}
                <Typography color="textPrimary">Liste der Benutzer</Typography>
            </Breadcrumbs>

            {isLoading && <LinearProgress color={"primary"} style={{ marginBottom: "16px" }} />}

            {userList.length === 0 && (
                <p>
                    Aktuell gibt es keine Benutzer... Das sollte eigentlich nie passieren können. Wenn Sie diese Meldung
                    sehen ist echt etwas kaput.
                </p>
            )}

            <Grid container alignItems="flex-start">
                <Grid item xs={12} md={12} lg={12}>
                    <Button onClick={onCreateNewUserClick} color="primary" startIcon={<AddIcon />}>
                        Einen neuen Benutzer anlegen
                    </Button>
                </Grid>
            </Grid>

            <TableContainer className="main-table">
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>E-Mail</TableCell>
                            <TableCell>Anrede</TableCell>
                            <TableCell>Vorname</TableCell>
                            <TableCell>Nachname</TableCell>
                            <TableCell>Rollen</TableCell>
                            <TableCell>Lezter Login</TableCell>
                            <TableCell style={{ width: "100px" }}>Menu</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userList.map((user: IUser, index: number) => {
                            return (
                                <TableRow key={"user" + index}>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.salutation}</TableCell>
                                    <TableCell>{user.firstname}</TableCell>
                                    <TableCell>{user.lastname}</TableCell>
                                    <TableCell>{mapRoleToLabel(user.roles)}</TableCell>
                                    <TableCell>
                                        {user.lastLogin
                                            ? moment(user.lastLogin).format(Format.dateTime)
                                            : "Noch kein Login"}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            component={"span"}
                                            data-id={user.id}
                                            onClick={actionEditUser}
                                            size={"small"}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            component={"span"}
                                            data-id={user.id}
                                            onClick={actionDeleteUser}
                                            size={"small"}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};

export default UserListing;
