import {
    Breadcrumbs,
    Button,
    Checkbox,
    Grid,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import IconAdd from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import React, { SyntheticEvent } from "react";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory } from "react-router-dom";

import DeleteDatasetDialog from "../../../components/deleteDialog/DeleteDatasetDialog";
import Notify from "../../../components/notify/Notify";
import { Format } from "../../../config/config";
import RoleEnum from "../../../enums/RoleEnum";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import IUser from "../../../store/userStore/interfaces/IUser";
import ICampaignList from "../../../types/appointment/ICampaignList";
import IMandant from "../../../types/IMandant";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import ISnackbarState from "../../../types/ISnackbarState";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import userHasRoles from "../../../utils/userHasRole";
import { deleteCampaignRequest, loadAdminCampaignList } from "../../../xhr/CampaignRequests";

interface IMapStateProps {
    activeUser: IUser | null;
}

interface IOwnProps {
    mandant: IMandant;
}

interface IPortalCampaignListingParams extends IMapStateProps, IOwnProps {}

const CampaignListing = (props: IPortalCampaignListingParams) => {
    const history = useHistory();
    const { mandant, activeUser } = props;
    const [campaignList, setCampaignList] = React.useState<ICampaignList[]>([]);
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    React.useEffect(() => {
        loadAdminCampaignList(mandant.key)
            .then((result: any) => {
                setCampaignList(result.data);
                return Promise.resolve();
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Laden der Anfragen ist ein Fehler aufgetreten",
                    type: "error",
                });
            });
    }, [mandant.key]);

    const actionEditCampaign = (event: SyntheticEvent<HTMLSpanElement>) => {
        const campaignId: number = parseInt("" + event.currentTarget.dataset.id);

        history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_APPOINTMENT + campaignId));
    };

    const actionCreateCampaign = () => {
        history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_APPOINTMENT + "create"));
    };

    const actionDeleteCampaign = (campaignId: string) => {
        deleteCampaignRequest(mandant.key, campaignId)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Kampagne wurde erfolgreich gelöscht",
                    type: "success",
                });

                loadAdminCampaignList(mandant.key)
                    .then((result: any) => {
                        setCampaignList(result.data);
                        return Promise.resolve();
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Beim Laden der Kampagnen ist ein Fehler aufgetreten",
                            type: "error",
                        });
                    });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Löschen der Kampagne ist ein Fehler aufgetreten",
                    type: "error",
                });
            });
    };

    function handleBread(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    onClick={handleBread}
                >
                    Start
                </Link>
                <Typography color="textPrimary">Termin Kampagnen</Typography>
            </Breadcrumbs>

            <Grid item xs={12} md={12} lg={12}>
                {userHasRoles(activeUser, [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_PROJECT_MANAGER]) && (
                    <>
                        <Button onClick={actionCreateCampaign} color="primary" startIcon={<IconAdd />}>
                            Eine neue Termin Kampagne anlegen
                        </Button>
                    </>
                )}
            </Grid>

            <TableContainer>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Titel</TableCell>
                            <TableCell>Projekt</TableCell>
                            <TableCell>Aktiv</TableCell>
                            <TableCell>Öffentlich</TableCell>
                            <TableCell>Ort</TableCell>
                            <TableCell>Start Datum</TableCell>
                            <TableCell>End Datum</TableCell>
                            <TableCell>Buchungsende</TableCell>
                            <TableCell style={{ width: "150px" }}>Menu</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {campaignList.map((campaign: ICampaignList, index: number) => {
                            return (
                                <TableRow key={"campaign" + index}>
                                    <TableCell>{campaign.title}</TableCell>
                                    <TableCell>{campaign.project?.title}</TableCell>
                                    <TableCell>
                                        <Checkbox checked={campaign.isActive} disabled={true} />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox checked={campaign.isPublicAvailable} disabled={true} />
                                    </TableCell>
                                    <TableCell>{campaign.location}</TableCell>
                                    <TableCell>{moment(campaign.startDate).format(Format.date)}</TableCell>
                                    <TableCell>{moment(campaign.endDate).format(Format.date)}</TableCell>
                                    <TableCell>{moment(campaign.bookingEndDate).format(Format.date)}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            data-id={campaign.id}
                                            onClick={actionEditCampaign}
                                            component={"span"}
                                            size={"small"}
                                        >
                                            <EditIcon />
                                        </IconButton>

                                        <DeleteDatasetDialog
                                            deleteHandler={actionDeleteCampaign}
                                            id={"" + campaign.id}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<IMapStateProps, IOwnProps, IApplicationRootState> = (
    state: IApplicationRootState
) => {
    return {
        activeUser: state.user.userData,
    };
};

export default connect(mapStateToProps)(CampaignListing);
