import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControlLabel, Grid, Paper, TextField } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import AlarmIcon from "@material-ui/icons/AddAlarm";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import deLocal from "date-fns/locale/de";
import React from "react";

import ImageUpload from "../../../../../components/imageUpload/ImageUpload";
import OlMap from "../../../../../components/olMap/adminMap/OlMap";
import { Format } from "../../../../../config/config";
import IDocument from "../../../../../types/IDocument";
import IEventFull from "../../../../../types/IEventFull";
import ILocation from "../../../../../types/ILocation";
import { IEventValidationState } from "../../eventDataValidation";

type IEventDataTab = {
    eventData: IEventFull;
    onTextChange: (event: any) => void;
    onCheckboxChange: (event: any) => void;
    onImageChange: (image: IDocument, attrKey: string) => void;
    onDateChange: (date: Date, attrKey: string) => void;
    errorState: IEventValidationState;
    onEventLocationChange: (lat: number, lng: number, zoom: number) => void;
    eventLocation: ILocation;
};

const EventDataTab = (props: IEventDataTab) => {
    //const [selectedDate] = React.useState(new Date());

    const {
        eventData,
        onImageChange,
        onTextChange,
        onCheckboxChange,
        onDateChange,
        onEventLocationChange,
        eventLocation,
    } = props;
    return (
        <Paper variant="outlined" className="main-paper">
            <Typography variant="h2" className="main-header gap-bottom">
                Stammdaten
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                error={props.errorState.title}
                                variant="outlined"
                                label="Veranstaltungs Titel"
                                name={"title"}
                                value={eventData.title ? eventData.title : ""}
                                onChange={onTextChange}
                                fullWidth
                                required={true}
                            />

                            <TextField
                                error={props.errorState.urlKey}
                                variant="outlined"
                                label="Adressschlüssel"
                                name={"urlKey"}
                                value={eventData.urlKey ? eventData.urlKey : ""}
                                onChange={onTextChange}
                                fullWidth
                                required={true}
                            />

                            <TextField
                                variant="outlined"
                                label="Veranstaltungsort"
                                name={"locationName"}
                                value={eventData.locationName ? eventData.locationName : ""}
                                onChange={onTextChange}
                                fullWidth
                            />

                            <TextField
                                variant="outlined"
                                label="Link Veranstaltungsort"
                                name={"locationUrl"}
                                value={eventData.locationUrl ? eventData.locationUrl : ""}
                                onChange={onTextChange}
                                fullWidth
                            />

                            <TextField
                                variant="outlined"
                                label="Konferenz Video Link"
                                name={"videoLink"}
                                value={eventData.videoLink ? eventData.videoLink : ""}
                                onChange={onTextChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <MuiPickersUtilsProvider locale={deLocal} utils={DateFnsUtils}>
                                <DateTimePicker
                                    error={props.errorState.theStart}
                                    inputVariant="outlined"
                                    label="Start"
                                    variant="inline"
                                    required={true}
                                    id="inp-event-start"
                                    fullWidth
                                    format={Format.dateInpTime}
                                    value={eventData.theStart ? new Date(eventData.theStart) : null}
                                    onChange={(date: any) => {
                                        onDateChange(date, "theStart");
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <AlarmIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={6}>
                            <MuiPickersUtilsProvider locale={deLocal} utils={DateFnsUtils}>
                                <DateTimePicker
                                    error={props.errorState.theEnd}
                                    label="Ende"
                                    variant="inline"
                                    inputVariant="outlined"
                                    id="inp-event-ende"
                                    fullWidth
                                    required={true}
                                    format={Format.dateInpTime}
                                    value={eventData.theEnd ? new Date(eventData.theEnd) : null}
                                    onChange={(date: any) => {
                                        onDateChange(date, "theEnd");
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <AlarmIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                error={props.errorState.shortDesc}
                                multiline
                                rows={8}
                                variant="outlined"
                                label="Kurzbeschreibung"
                                name={"shortDesc"}
                                value={eventData.shortDesc}
                                onChange={onTextChange}
                                fullWidth
                                required={true}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormLabel component="legend">Einstellungen</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={eventData.flagCommentsOpen ? true : false}
                                            onChange={onCheckboxChange}
                                            name="flagCommentsOpen"
                                        />
                                    }
                                    label="Kommentare für die Veranstaltung aktiv"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <Paper variant="outlined" className="upload-image-card">
                        <Typography variant="h3" gutterBottom style={{ paddingTop: "8px", paddingLeft: "8px" }}>
                            Veranstaltung auf der Karte
                        </Typography>
                        <OlMap
                            location={[eventLocation.longitude, eventLocation.latitude]}
                            zoom={eventLocation.zoom}
                            onLocationSelected={onEventLocationChange}
                        />
                    </Paper>
                    <ImageUpload
                        onChange={onImageChange}
                        docData={eventData.imageMenu}
                        idKey={"imageMenu"}
                        name={"imageMenu"}
                        label={"Bild im Menüeintrag *"}
                        defaultFilename="Menu.jpg"
                        errorState={props.errorState.imageMenu}
                        minSourceImageWidth={1000}
                        minSourceImageHeight={520}
                        imageExportHeightInPixels={421}
                        imageExportWidthInPixels={800}
                    />
                    <ImageUpload
                        onChange={onImageChange}
                        docData={eventData.imageHeader}
                        idKey={"imageHeader"}
                        name={"imageHeader"}
                        label={"Kopf Bild *"}
                        defaultFilename="Header.jpg"
                        errorState={props.errorState.imageHeader}
                        minSourceImageWidth={2000}
                        minSourceImageHeight={1125}
                        imageExportHeightInPixels={1125}
                        imageExportWidthInPixels={2000}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default EventDataTab;
