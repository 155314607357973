import "./Footer.css";

import { Grid } from "@material-ui/core";
import React from "react";

import CookiesConsent from "../../components/cookiesConsent/CookiesConsent";
import IMandant from "../../types/IMandant";

const PortalSimpleFooter = (props: { mandant: Partial<IMandant> }) => {
    return (
        <>
            <div className="App-footer">
                <Grid className="App-footer-outer" container>
                    <Grid item xs={12} lg={6} className="gap-top">
                        <a href="https://www.steg.de/impressum/" target="_blank" rel="noopener noreferrer">
                            Impressum
                        </a>
                        <br />
                        <a href="https://www.steg.de/datenschutz/" target="_blank" rel="noopener noreferrer">
                            Datenschutzerklärung
                        </a>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} className="gap-top steg-copyright">
                        <a href="https://steg.de">© 2022 die STEG</a>
                    </Grid>
                </Grid>
            </div>
            <CookiesConsent mandant={props.mandant} />
        </>
    );
};

export default PortalSimpleFooter;
