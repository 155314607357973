import { Button, CircularProgress, Grid, LinearProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconAdd from "@material-ui/icons/Add";
import IconGames from "@material-ui/icons/Games";
import IconOpenWith from "@material-ui/icons/OpenWith";
import IconSave from "@material-ui/icons/Save";
import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import IProjectGalleryImage from "../../../../types/IProjectGalleryImage";
import ImageGalleryCard from "../components/cards/ImageGalleryCard";

interface IProjectDownloadsSectionProps {
    images?: IProjectGalleryImage[];
    onUpload: (type: string, image: IProjectGalleryImage, index: number) => void;
    onDelete: (type: string, index: number) => void;
    onResetImage: (type: string, index: number, imageId: number) => void;
    onSave: (type: string) => void;
    onImageCreate: (type: string) => void;
    loadingGallery: boolean;
    onSort: (oldIndex: number, newIndex: number, type: string) => void;
    handleImagesSortSave: (type: string) => void;
}

const ProjectInfoDownloadsSection = (props: IProjectDownloadsSectionProps) => {
    const { images, handleImagesSortSave, onUpload, onDelete, onResetImage, onSave, onImageCreate, loadingGallery } =
        props;
    const [sortingActive, setSortingActive] = React.useState<boolean>(false);
    const toggleSortingAction = () => {
        if (sortingActive) {
            handleImagesSortSave("attachments");
        }

        setSortingActive(!sortingActive);
    };
    const SortableItem = SortableElement(({ value, index }: { value: any; index: any }) => (
        <ImageGalleryCard
            key={value.name + index}
            type={"attachments"}
            index={index}
            image={value}
            onChange={onUpload}
            onDelete={onDelete}
            onReset={onResetImage}
            isNotImage={true}
            isSorting={sortingActive}
        />
    ));
    const SortableList = SortableContainer(({ items }: { items: IProjectGalleryImage[] }) => {
        return (
            <Grid container direction="row" spacing={2} style={{ marginLeft: "-16px", marginRight: "-16px" }}>
                {items ? (
                    items.map((image: IProjectGalleryImage, index) => (
                        <SortableItem value={image} index={index} key={image.id ? image.id + index + index : index} />
                    ))
                ) : (
                    <CircularProgress />
                )}
            </Grid>
        );
    });
    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        props.onSort(oldIndex, newIndex, "attachments");
    };
    return (
        <>
            {loadingGallery && <LinearProgress color={"primary"} style={{ marginBottom: "16px" }} />}

            <Typography variant="h2" className="main-header">
                Downloads
            </Typography>

            <Button
                color="primary"
                onClick={() => {
                    onSave("attachments");
                }}
                startIcon={<IconSave />}
            >
                Daten speichern
            </Button>

            <Button
                color="secondary"
                onClick={() => {
                    onImageCreate("attachments");
                }}
                startIcon={<IconAdd />}
            >
                Daten hinzufügen
            </Button>
            <Button
                color="secondary"
                onClick={toggleSortingAction}
                startIcon={sortingActive ? <IconOpenWith /> : <IconGames />}
            >
                {sortingActive ? "Sortierung deaktivieren" : "Sortierung aktivieren"}
            </Button>
            {sortingActive && <SortableList items={images ? images : []} onSortEnd={onSortEnd} axis="xy" />}

            {!sortingActive && (
                <Grid container direction="row" spacing={2} style={{ marginLeft: "-16px", marginRight: "-16px" }}>
                    {images ? (
                        images.map((image: IProjectGalleryImage, index: number) => (
                            <ImageGalleryCard
                                type={"attachments"}
                                key={index}
                                index={index}
                                image={image}
                                onChange={onUpload}
                                onDelete={onDelete}
                                onReset={onResetImage}
                                isNotImage={true}
                            />
                        ))
                    ) : (
                        <CircularProgress />
                    )}
                </Grid>
            )}
        </>
    );
};

export default ProjectInfoDownloadsSection;
