import "./HeaderProjectMenu.css";

import { Button, Grid } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React from "react";
import { useHistory } from "react-router-dom";

import SwipableDrawer from "../../components/SwipableDrawer/SwipableDrawer";
import useWindowScroll from "../../customhooks/useWindowScroll";
import { IMenuEntry, IProjectMenu } from "../../masks/admin/projectForm/tabs/ProjectMenuTab";
import toLabel from "../../masks/public/projectData/helper/toLabel";
import { ProjectTabEnum } from "../../masks/public/projectData/ProjectTabEnum";
import RoutesEnum from "../../routes/RoutesEnum";
import IUser from "../../store/userStore/interfaces/IUser";
import IHeaderEntry, { IRichHeaderEntry } from "../../types/IHeaderEntry";
import IMandant from "../../types/IMandant";
import IProjectFull from "../../types/IProjectFull";
import { toPortalUrl } from "../../utils/toPortalUrl";

interface IHeaderProjectMenuProps {
    mandant: IMandant;
    activeProject?: IProjectFull | null;
    activeTab?: string;
    pageKey?: string;
    userData: IUser | null;
    isDesktopView: boolean;
    handleLogout: (mandantKey: string) => void;
    linkToLogin: () => void;
    customMenu?: IProjectMenu | null;
    flagShowMoreProjects: boolean;
    isFullWidthMenu?: boolean;
    logoUrl: string;
    handleLogoRouting: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const HeaderProjectMenu = (props: IHeaderProjectMenuProps) => {
    const {
        activeProject,
        activeTab,
        pageKey,
        mandant,
        userData,
        isDesktopView,
        handleLogout,
        linkToLogin,
        customMenu,
        flagShowMoreProjects,
        isFullWidthMenu,
        logoUrl,
        handleLogoRouting,
    } = props;
    const history = useHistory();
    const scrollPos = useWindowScroll();

    const [containerWidth, setContainerWidth] = React.useState(0);
    // Default window max width
    const maxWidth = 1200;

    // Get window size
    React.useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setContainerWidth(width);
        };

        // Initial resize check
        handleResize();

        // Listen for resize events
        window.addEventListener("resize", handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const linkToProject = (tab: string) => {
        history.push(toPortalUrl(mandant, activeProject?.urlKey + "/" + tab));
        window.scrollTo({ left: 0, top: 0 });
    };

    const linkToProjects = () => {
        history.push(toPortalUrl(mandant, RoutesEnum.PORTAL_PROJECT_LIST));
        window.scrollTo({ left: 0, top: 0 });
    };

    const logoutHandler = () => {
        handleLogout(mandant.key);
        linkToProjects();
        window.scrollTo({ left: 0, top: 0 });
    };

    const projectMenuEntries: any[] =
        activeProject != null
            ? [
                  {
                      label: toLabel(activeProject.menuTabStart, "Start"),
                      onClick: () => {
                          return linkToProject(ProjectTabEnum.START_TAB);
                      },
                      visible: Boolean(activeProject?.flagTabStart),
                      active: activeTab === ProjectTabEnum.START_TAB ? "active" : "",
                  },
                  {
                      label: toLabel(activeProject.menuTabInfo, "Informationen"),
                      onClick: () => {
                          return linkToProject(ProjectTabEnum.INFO_TAB);
                      },
                      visible: Boolean(activeProject?.flagTabInfo),
                      active: activeTab === ProjectTabEnum.INFO_TAB ? "active" : "",
                  },
                  {
                      label: toLabel(activeProject.menuTabConsulting, "Beratung"),
                      onClick: () => {
                          return linkToProject(ProjectTabEnum.CONSULTING_TAB);
                      },
                      visible: Boolean(activeProject?.flagTabConsulting),
                      active: activeTab === ProjectTabEnum.CONSULTING_TAB ? "active" : "",
                  },
                  {
                      label: toLabel(activeProject.menuTabEvents, "Veranstaltungen"),
                      onClick: () => {
                          return linkToProject(ProjectTabEnum.EVENTS_TAB);
                      },
                      visible: Boolean(activeProject?.flagTabEvents),
                      active: activeTab === ProjectTabEnum.EVENTS_TAB ? "active" : "",
                  },
                  {
                      label: toLabel(activeProject.menuTabResults, "Ergebnisse"),
                      onClick: () => {
                          return linkToProject(ProjectTabEnum.RESULTS_TAB);
                      },
                      visible: Boolean(activeProject?.flagTabResults),
                      active: activeTab === ProjectTabEnum.RESULTS_TAB ? "active" : "",
                  },
                  {
                      label: toLabel(activeProject.menuTabParticipate, "Beteiligung"),
                      onClick: () => {
                          return linkToProject(ProjectTabEnum.PARTICIPATE_TAB);
                      },
                      visible: Boolean(activeProject?.flagTabParticipate),
                      active: activeTab === ProjectTabEnum.PARTICIPATE_TAB ? "active" : "",
                  },
                  {
                      label: toLabel(activeProject.menuTabTeam, "Team"),
                      onClick: () => {
                          return linkToProject(ProjectTabEnum.TEAM_TAB);
                      },
                      visible: Boolean(activeProject?.flagTabTeam),
                      active: activeTab === ProjectTabEnum.TEAM_TAB ? "active" : "",
                  },
                  {
                      label: toLabel(activeProject.menuTabCampaign, "Kampagne"),
                      onClick: () => {
                          return linkToProject(ProjectTabEnum.CAMPAIGN_TAB);
                      },
                      visible: Boolean(activeProject?.flagTabCampaign),
                      active: activeTab === ProjectTabEnum.CAMPAIGN_TAB ? "active" : "",
                  },
              ].filter((entry) => {
                  return entry.visible;
              })
            : [];
    /*
    const mobileMenuEntries: IHeaderEntry[] = [
        ...projectMenuEntries,
        {
            label: userData ? "Ausloggen" : "Anmelden",
            onClick: userData ? logoutHandler : linkToLogin,
        },
    ];
*/

    /**
     * show top menu
     */
    const getLinkActionUrl = (menuEntry: IMenuEntry): string => {
        if (activeProject) {
            if (menuEntry.type === "page") {
                return toPortalUrl(mandant, activeProject.urlKey + "/seite/" + menuEntry.url);
            } else {
                return toPortalUrl(mandant, activeProject.urlKey + "/" + menuEntry.url);
            }
        }
        return "";
    };

    const mobileMenuEntries: IRichHeaderEntry[] = [
        ...(activeProject?.flagEnableProjectCms
            ? (customMenu?.content || []).map((menuEntry) => ({
                  label: menuEntry.label,
                  onClick: () => {
                      const linkPath = getLinkActionUrl(menuEntry);
                      history.push(linkPath);
                  },
                  children: menuEntry.children ? menuEntry.children : [],
              }))
            : projectMenuEntries),
        {
            label: userData ? "Ausloggen" : "Anmelden",
            onClick: userData ? logoutHandler : linkToLogin,
        },
    ];

    const SideDrawerButtons = () => {
        const logoutAction = () => {
            handleLogout(mandant.key);
        };

        return (
            <Grid item className={"App-header-menu--entry-buttons"}>
                <div className="App-header-menu--entry header-buttons-container ">
                    {flagShowMoreProjects && (
                        <Button className="with-shadow" onClick={linkToProjects} endIcon={<ArrowForwardIcon />}>
                            Mehr Projekte
                        </Button>
                    )}
                    {!userData && (
                        <Button
                            className="with-shadow"
                            onClick={linkToLogin}
                            endIcon={<ArrowForwardIcon />}
                            color={"primary"}
                        >
                            Jetzt anmelden
                        </Button>
                    )}
                    {!!userData && (
                        <Button
                            onClick={logoutAction}
                            className="App-header-user-button"
                            endIcon={<ArrowForwardIcon />}
                            color={"primary"}
                        >
                            Ausloggen
                        </Button>
                    )}
                </div>
            </Grid>
        );
    };

    const checkIfIsActive = (menuEntry: IMenuEntry, checkKids: boolean): boolean => {
        if (menuEntry?.type === "tab") {
            if (activeTab === menuEntry?.url) {
                return true;
            }
        } else {
            if (pageKey === menuEntry?.url) {
                return true;
            }
        }

        if (!checkKids) {
            return false;
        }

        if (menuEntry?.children.length === 0) {
            return false;
        }

        for (const child of menuEntry?.children) {
            if (child.type === "tab") {
                if (activeTab === child.url) {
                    return true;
                }
            } else {
                if (pageKey === child?.url) {
                    return true;
                }
            }
        }

        return false;
    };
    const showTopMenu = () => {
        let menuClass = ` ${mandant.flagShowBigCustomerLogo ? "App-header-menu-with-big-customer-logo" : ""} ${
            isFullWidthMenu && !mandant.flagShowBigCustomerLogo ? "menu-with-no-buttons" : ""
        } ${isFullWidthMenu && mandant.flagShowBigCustomerLogo ? "menu-with-no-buttons-with-big-logo" : ""} ${
            isFullWidthMenu && containerWidth >= maxWidth
                ? "centred-menu-with-no-buttons"
                : isFullWidthMenu
                ? "full-width-menu-with-no-buttons"
                : ""
        } ${
            isFullWidthMenu && containerWidth >= maxWidth && mandant.flagShowBigCustomerLogo
                ? "centred-menu-with-no-buttons-with-big-image"
                : isFullWidthMenu && mandant.flagShowBigCustomerLogo
                ? "full-width-menu-with-no-buttons-with-big-image"
                : ""
        } App-header-menu App-header-menu-responsive`;
        if (scrollPos > 200) {
            menuClass += " full-width";
        }
        return isDesktopView ? (
            !activeProject?.flagEnableProjectCms ? (
                projectMenuEntries.length > 0 && (
                    <nav className={menuClass}>
                        <ul>
                            {projectMenuEntries.map((entry: IHeaderEntry, index: number) => {
                                return (
                                    <li
                                        className={`App-header-menu--entry App-header-menu--top-bar ${entry?.active}`}
                                        onClick={entry.onClick}
                                        key={index}
                                    >
                                        {entry.label}
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                )
            ) : (
                <nav className={menuClass}>
                    <ul>
                        {customMenu &&
                            customMenu.content &&
                            customMenu.content.map((menuEntry, index) => {
                                const linkPath = getLinkActionUrl(menuEntry);
                                return (
                                    <li
                                        key={index}
                                        className={`App-header-menu--custom-entry App-header-menu--top-bar ${
                                            checkIfIsActive(menuEntry, true) ? "is-active" : ""
                                        }`}
                                    >
                                        <a
                                            href={linkPath}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                history.push(linkPath);
                                            }}
                                        >
                                            {menuEntry.label}{" "}
                                            {menuEntry.children.length > 0 && (
                                                <div className="menu-opener-icon">
                                                    <ArrowDropDownIcon />
                                                </div>
                                            )}
                                        </a>
                                        {menuEntry.children.length > 0 && (
                                            <ul>
                                                {menuEntry.children.map((subMenuEntry, subMenuIndex) => {
                                                    const linkSubPath = getLinkActionUrl(subMenuEntry);

                                                    return (
                                                        <li
                                                            key={index + "-" + subMenuIndex}
                                                            className={
                                                                checkIfIsActive(subMenuEntry, false)
                                                                    ? "sub-menu is-active"
                                                                    : "sub-menu"
                                                            }
                                                        >
                                                            <a
                                                                href={linkSubPath}
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    history.push(linkSubPath);
                                                                }}
                                                            >
                                                                {subMenuEntry.label}
                                                            </a>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )}
                                    </li>
                                );
                            })}
                    </ul>
                </nav>
            )
        ) : (
            <></>
        );
    };

    return (
        <>
            {/*main header grid*/}
            <div>
                {!isDesktopView && (
                    <SwipableDrawer
                        title={activeProject?.title ? activeProject?.title : ""}
                        entries={mobileMenuEntries}
                        mandant={mandant}
                        buttons={SideDrawerButtons}
                        activeProject={activeProject}
                        history={history}
                        logoUrl={logoUrl}
                        handleLogoRouting={handleLogoRouting}
                    />
                )}

                {/* top menu grid item(xs8).*/}
                {showTopMenu()}
            </div>
        </>
    );
};

export default HeaderProjectMenu;
