export const PORTAL_AUTH_END_POINT = "login";
export const ADMIN_LOGOUT_END_POINT = "logout";
export const PORTAL_DEADLINE = "deadline/";
export const PORTAL_EVENT = "event/";
export const PORTAL_PROJECT = "project/";
export const PORTAL_COMMENT = "comment/";
export const PORTAL_PROPOSAL = "map-proposal/";
export const PORTAL_CAMPAIGN = "poll-campaign/";
export const PORTAL_PROJECT_MENU = "cms/project_menu/";
export const PORTAL_PROJECT_TEAM = "project/team/";

export const LOAD_MANDANT = "mandant/";
export const ADMIN_PROJECT = "project/project/";
export const ADMIN_PROJECT_MENU = "cms/project_menu/";
export const ADMIN_EVENT = "project/event/";
export const ADMIN_EVENT_GALLERY = "project/event/";
export const ADMIN_PROJECT_TEAM = "project/team/";
export const ADMIN_EVENT_PARTICIPANT = "project/event-participant/";
export const ADMIN_DEADLINE = "project/deadline/";
export const PORTAL_APPOINTMENT = "appointment/appointment/";

export const PROJECT_RESULTS_SORT = "/results/sort";

export const PROJECT_LIST_SORT = "project-list/sort";
export const PARTNER_LOGOS_LIST_SORT = "sort";

export const UPLOADS_ATTACHEMENTS_SORT = "/uploads/attachments/sort";
export const ADMIN_SORT_IMAGES_DEFAULT = "/gallery/default/sort";
export const ADMIN_SORT_EVENT_IMAGES_AFTER = "/gallery/after/sort";
export const ADMIN_LOAD_PROJECT_SELECTBOX = "project/selectbox/";

export const PORTAL_LOAD_PROJECT_SELECTBOX = "project/selectbox/";
export const PORTAL_LOAD_PROJECT_SELECTBOX_ALL = "project/all/";
export const PORTAL_LOAD_CMS_PAGE = "page/";

export const ADMIN_OVERVIEW = "overview";

export const ADMIN_USER = "base/user/";
export const ADMIN_TOKEN_REFRESH = "token/refresh";
export const ADMIN_CMS_PAGE = "cms/page/";
export const ADMIN_CMS_PAGE_SELECTBOX = "cms/project-page-selectbox/";
export const ADMIN_FEEDBACK = "project/feedback/";
//TODO optional: please rename this to use a better name as you create the api call
export const FEEDBACK_FORM_DATA = "project/feedback/formdata";
export const ADMIN_CHANGE_PASSWORD = "password/change";
export const ADMIN_PUBLISH_PROJECT = "project/project/publish/";
export const ADMIN_PUBLISH_EVENT = "project/event/publish/";
export const ADMIN_MANDANT = "cms/mandant/";
export const ADMIN_CHANGE_ADMIN_PASSWORD = "password/change-admin/";
export const ADMIN_POLL_CAMPAIGN = "project/poll-campaign/";
export const ADMIN_CAMPAIGN = "appointment/campaign/";
export const ADMIN_APPOINTMENT = "appointment/appointment/";
export const ADMIN_APPOINTMENT_SINGLE = "appointment";
export const ADMIN_CAMPAIGN_PARTICIPANT = "campaign/participant/";
export const ADMIN_CAMPAIGN_PARTICIPANTS = "campaign/participants/";
export const ADMIN_ACTIVE_CAMPAIGN = "appointment/project-active-campaign";
export const ADMIN_POLL_CAMPAIGN_ACTIVE = "project/poll-campaign/activate/";
export const ADMIN_POLL_CAMPAIGN_IN_ACTIVE = "project/poll-campaign/de-activate/";

export const PORTAL_POLL_CAMPAIGN = "poll-campaign/";
export const VOTE = "vote/";

export const FEEDBACK = "feedback/";
const ENDPOINTS = {
    PORTAL_AUTH_END_POINT,
    ADMIN_LOGOUT_END_POINT,
    LOAD_MANDANT,
    PORTAL_LOAD_PROJECT_SELECTBOX,
    PUBLIC_PROJECT: PORTAL_PROJECT,
    PORTAL_DEADLINE,
    LOAD_EVENT_LIST: PORTAL_EVENT,
    PORTAL_CAMPAIGN,
    FEEDBACK,
    ADMIN_USER,
    ADMIN_FEEDBACK,
    ADMIN_PROJECT,
    ADMIN_EVENT,
    ADMIN_PROJECT_TEAM,
    ADMIN_CHANGE_PASSWORD,
    ADMIN_MANDANT,
    ADMIN_DEADLINE,
    ADMIN_EVENT_PARTICIPANT,
    ADMIN_TOKEN_REFRESH,
    ADMIN_PUBLISH_EVENT,
    ADMIN_SORT_IMAGES_DEFAULT,
    ADMIN_SORT_EVENT_IMAGES_AFTER,
    ADMIN_APPOINTMENT_SINGLE,
    ADMIN_CAMPAIGN_PARTICIPANT,
    UPLOADS_ATTACHEMENTS_SORT,
    ADMIN_POLL_CAMPAIGN,
    ADMIN_APPOINTMENT,
    PORTAL_POLL_CAMPAIGN,
    VOTE,
};

export default ENDPOINTS;
