import "./ProjectDataTab.css";
import "date-fns";

import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, Divider, FormControl, Grid, InputLabel, Paper, Select, TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { Alert } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import deLocal from "date-fns/locale/de";
import React from "react";

import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import Notify from "../../../../components/notify/Notify";
import OlMap from "../../../../components/olMap/adminMap/OlMap";
import { Format } from "../../../../config/config";
import { ICmsPageChild } from "../../../../types/ICmsPage";
import IDocument from "../../../../types/IDocument";
import IMandant from "../../../../types/IMandant";
import InitialSnackbarState from "../../../../types/initialData/InitialSnackbarState";
import IProjectFull from "../../../../types/IProjectFull";
import ISnackbarState from "../../../../types/ISnackbarState";
import ISelectOption from "../../../../xhr/interface/ISelectOption";
import defaultTabs from "../data/defaultTabs";
import { IProjectValidationState } from "../projectDataValidation";

interface IProjectDataFormProps {
    mandant: IMandant;
    onTextChange: (event: any) => void;
    onImageChange: (image: IDocument, attrKey: string) => void;
    onCheckboxChange: (event: any) => void;
    projectData: IProjectFull;
    cmsPageProjectData: ICmsPageChild[];
    onEditStart: (e: any) => void;
    onEditEnd: (e: any) => void;
    errorState: IProjectValidationState;
    onProjectLocationChange: (lat: number, lng: number, zoom: number) => void;
    handleZoomChange: (zoom: number) => void;
    showDoku: boolean;
}

const ProjectDataTab = (props: IProjectDataFormProps) => {
    const {
        projectData,
        cmsPageProjectData,
        onTextChange,
        onImageChange,
        onCheckboxChange,
        onProjectLocationChange,
        handleZoomChange,
        showDoku,
    } = props;
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    // Handle select logo route
    const changeLogoRouteSelectbox = (event: React.ChangeEvent<any>) => {
        props.onTextChange(event);
    };

    return (
        <Paper variant="outlined" className="main-paper">
            <Typography variant="h2" className="main-header gap-bottom">
                Stammdaten
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                error={props.errorState.title}
                                name="title"
                                label="Title"
                                fullWidth
                                value={projectData.title}
                                onChange={onTextChange}
                                variant="outlined"
                                required={true}
                            />
                            <TextField
                                name="shortTitle"
                                label="Kurzer Name (für den Tab)"
                                fullWidth
                                value={projectData.shortTitle}
                                onChange={onTextChange}
                                variant="outlined"
                                required={true}
                            />

                            <TextField
                                error={props.errorState.urlKey}
                                name="urlKey"
                                label={projectData.flagLinkExternalOnly ? "Projekt URL" : "Adressschlüssel"}
                                fullWidth
                                value={projectData.urlKey}
                                onChange={onTextChange}
                                variant="outlined"
                                required={true}
                            />

                            {showDoku && (
                                <Alert severity="info" className="gap-top gap-bottom">
                                    Der Adressschlüssel wird bei der Adressierung im Browser verwendet. Es sollte keine
                                    Sonderzeichen, Umlaute und Leerzeichen enthalten. Er repräsentiert das Projekt.
                                    Daher sollte er möglichst das Projekt beschreiben.
                                    <br />
                                    Bsp: <strong>"SanierungMitteWest2025"</strong>
                                </Alert>
                            )}
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <MuiPickersUtilsProvider locale={deLocal} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    name="theStart"
                                    margin="normal"
                                    id="inp-project-start"
                                    variant="inline"
                                    label="Start"
                                    required={true}
                                    fullWidth
                                    format={Format.dateInp}
                                    value={projectData.theStart ? new Date(projectData.theStart) : null}
                                    onChange={props.onEditStart}
                                    KeyboardButtonProps={{
                                        "aria-label": "Startdatum anpassen",
                                    }}
                                    error={props.errorState.theStart}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <MuiPickersUtilsProvider locale={deLocal} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    name="theEnd"
                                    margin="normal"
                                    inputVariant="outlined"
                                    id="inp-project-ende"
                                    variant="inline"
                                    label="Ende"
                                    fullWidth
                                    format={Format.dateInp}
                                    value={projectData.theEnd ? new Date(projectData.theEnd) : null}
                                    onChange={props.onEditEnd}
                                    KeyboardButtonProps={{
                                        "aria-label": "Enddatum anpassen",
                                    }}
                                    error={props.errorState.theEnd}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            error={props.errorState.shortDesc}
                            name="shortDesc"
                            id="outlined-multiline-flexible"
                            label="Kurzbeschreibung"
                            fullWidth
                            multiline
                            /* rowsMax={6} */
                            value={projectData.shortDesc}
                            onChange={onTextChange}
                            variant="outlined"
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControl variant="outlined" fullWidth={true}>
                            <InputLabel id="menu-select-logo-route">Link des Logos anpassen</InputLabel>
                            <Select
                                labelId="logo-route"
                                value={projectData.logoRoute}
                                onChange={changeLogoRouteSelectbox}
                                name="logoRoute"
                                label="Logo Verlinkung"
                            >
                                <MenuItem key="empty" value="">
                                    <em>Bitte Ziel wählen</em>
                                </MenuItem>
                                <MenuItem key="Header-tabs" value="" disabled={true}>
                                    <strong>Standard Tabs</strong>
                                </MenuItem>
                                {defaultTabs.map((defTab: ISelectOption, index: number) => {
                                    return (
                                        <MenuItem key={"tab-" + index} value={defTab.id} data-type="tab">
                                            Tab: {defTab.label}
                                        </MenuItem>
                                    );
                                })}
                                <MenuItem key="Header-pages" value="" disabled={true}>
                                    {cmsPageProjectData.length ? <strong>Seiten</strong> : <></>}
                                </MenuItem>
                                {cmsPageProjectData.map((page: ICmsPageChild, index: number) => {
                                    return (
                                        <MenuItem key={"page-" + index} value={"seite/" + page.urlKey} data-type="page">
                                            Seite: {page.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        {showDoku && (
                            <Alert severity="info" className="gap-top gap-bottom">
                                Mit dieser Einstellung kann der Link des Logos auf eine beliebige Seite im Projekt
                                angepasst werden.
                            </Alert>
                        )}
                    </Grid>

                    {!!projectData.flagLinkExternalOnly && (
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container>
                                <Grid item xs={12} md={12} lg={12}>
                                    <FormLabel component="legend">Anzeige Einstellungen</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!projectData.flagLinkExternalOnly}
                                                    onChange={onCheckboxChange}
                                                    name="flagLinkExternalOnly"
                                                />
                                            }
                                            label="Dieses Projekt wird in einer externen Seite präsentiert (nur in der Übersicht anzeigen)"
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {!projectData.flagLinkExternalOnly && (
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container>
                                <Grid item xs={12} md={12} lg={12}>
                                    <FormLabel component="legend">Anzeige Einstellungen</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!projectData.flagHideStegLogo}
                                                    onChange={onCheckboxChange}
                                                    name="flagHideStegLogo"
                                                />
                                            }
                                            label="Verstecke das STEG Logo"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!projectData.flagHideInOverview}
                                                    onChange={onCheckboxChange}
                                                    name="flagHideInOverview"
                                                />
                                            }
                                            label="Das Projekt NICHT in der Übersicht/Startseite auflisten"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!projectData.flagEnableProjectCms}
                                                    onChange={onCheckboxChange}
                                                    name="flagEnableProjectCms"
                                                />
                                            }
                                            label="Projekt CMS aktivieren"
                                        />

                                        {showDoku && (
                                            <Alert severity="info">
                                                Wenn das CMS aktiviert wird muss das Menü im Menü Tab gepflegt werden.
                                            </Alert>
                                        )}

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!projectData.flagLinkExternalOnly}
                                                    onChange={onCheckboxChange}
                                                    name="flagLinkExternalOnly"
                                                />
                                            }
                                            label="Dieses Projekt wird in einer externen Seite präsentiert (nur in der Übersicht anzeigen)"
                                        />
                                    </FormGroup>
                                </Grid>
                                {!projectData.flagEnableProjectCms && (
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Divider className="gap-top gap-bottom" />
                                        <FormLabel component="legend">
                                            Welche Tabs / Inhalte werden angezeigt?
                                        </FormLabel>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={!!projectData.flagTabInfo}
                                                        onChange={onCheckboxChange}
                                                        name="flagTabInfo"
                                                    />
                                                }
                                                label="Informationen anzeigen"
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={!!projectData.flagTabConsulting}
                                                        onChange={onCheckboxChange}
                                                        name="flagTabConsulting"
                                                    />
                                                }
                                                label="Beratung anzeigen"
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={!!projectData.flagTabResults}
                                                        onChange={onCheckboxChange}
                                                        name="flagTabResults"
                                                    />
                                                }
                                                label="Ergebnisse anzeigen"
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={!!projectData.flagTabEvents}
                                                        onChange={onCheckboxChange}
                                                        name="flagTabEvents"
                                                    />
                                                }
                                                label="Veranstaltungen anzeigen"
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={!!projectData.flagTabParticipate}
                                                        onChange={onCheckboxChange}
                                                        name="flagTabParticipate"
                                                    />
                                                }
                                                label="Beteiligung anzeigen"
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={!!projectData.flagTabTeam}
                                                        onChange={onCheckboxChange}
                                                        name="flagTabTeam"
                                                    />
                                                }
                                                label="Team anzeigen"
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={!!projectData.flagTabCampaign}
                                                        onChange={onCheckboxChange}
                                                        name="flagTabCampaign"
                                                    />
                                                }
                                                label="Kampagne anzeigen"
                                            />
                                        </FormGroup>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper variant="outlined" className="map-card">
                        <Typography variant="h3">Projekt auf der Karte</Typography>
                        {projectData && (
                            <OlMap
                                location={[projectData.longitude || 0, projectData.latitude || 0]}
                                zoom={projectData.zoom || 0}
                                onLocationSelected={onProjectLocationChange}
                                persistZoomChange={handleZoomChange}
                            />
                        )}
                    </Paper>
                    <ImageUpload
                        onChange={onImageChange}
                        docData={projectData.imageMenu}
                        idKey={"imageMenu"}
                        name={"imageMenu"}
                        label={"Bild im Menüeintrag *"}
                        defaultFilename="Menu.jpg"
                        errorState={props.errorState.imageMenu}
                        minSourceImageWidth={1000}
                        minSourceImageHeight={520}
                        imageExportHeightInPixels={421}
                        imageExportWidthInPixels={800}
                    />
                    <ImageUpload
                        onChange={onImageChange}
                        docData={projectData.imageHeader}
                        idKey={"imageHeader"}
                        name={"imageHeader"}
                        label={"Kopf Bild *"}
                        defaultFilename="Header.jpg"
                        errorState={props.errorState.imageHeader}
                        minSourceImageWidth={2000}
                        minSourceImageHeight={1125}
                        imageExportHeightInPixels={1125}
                        imageExportWidthInPixels={2000}
                    />
                </Grid>
                <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
            </Grid>
        </Paper>
    );
};

export default ProjectDataTab;
