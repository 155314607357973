import {
    Breadcrumbs,
    Button,
    Checkbox,
    Grid,
    Link,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TableBody from "@material-ui/core/TableBody";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import React from "react";
import { MapStateToPropsParam, connect } from "react-redux";

import ImageUpload from "../../../components/imageUpload/ImageUpload";
import Notify from "../../../components/notify/Notify";
import RoleEnum from "../../../enums/RoleEnum";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import IDocument from "../../../types/IDocument";
import IMandant from "../../../types/IMandant";
import InitialDocumentData from "../../../types/initialData/InitialDocumentData";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import InitialUserData from "../../../types/initialData/InitialUserData";
import ISnackbarState from "../../../types/ISnackbarState";
import IUser from "../../../types/IUser";
import deepCopy from "../../../utils/deepCopy";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import ISelectOption from "../../../xhr/interface/ISelectOption";
import { loadProjectAllListRequest } from "../../../xhr/ProjectRequests";
import { createUserRequest, loadSingleUserRequest, saveUserRequest } from "../../../xhr/UserRequests";

type IMapStateProps = {
    mandant: IMandant;
};

type IMapDispatchProps = {};

type IOwnProps = {
    history: any;
    match: any;
};

type IUserFormParams = IMapStateProps & IMapDispatchProps & IOwnProps;

const UserForm = (props: IUserFormParams) => {
    const { history, mandant } = props;

    const [formData, setFormData] = React.useState<IUser>({
        ...InitialUserData,
    });

    const [projectList, setProjectList] = React.useState<ISelectOption[]>([]);

    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    React.useEffect(() => {
        const userId = props.match.params.id;

        if (!mandant) {
            return;
        }

        if (!isNaN(userId)) {
            loadProjectAllListRequest(mandant.key)
                .then((response: any) => {
                    setProjectList(response.data);
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Beim Laden der Daten ist ein Fehler aufgetreten",
                        type: "error",
                    });
                });

            loadSingleUserRequest(mandant.key, userId)
                .then((response: any) => {
                    const userOrig: any = response.data;

                    const user: IUser = { ...userOrig };
                    user.projectRoles = {};

                    userOrig.projectRoles.forEach((project: any) => {
                        if (user.projectRoles[project.project_id] === undefined) {
                            user.projectRoles[project.project_id] = [];
                        }

                        user.projectRoles[project.project_id].push(project.key);
                    });

                    setFormData(user);
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Beim Laden der Daten ist ein Fehler aufgetreten",
                        type: "error",
                    });
                });
        } else {
            setFormData({ ...InitialUserData });
        }
    }, [mandant, props]);

    const handleSaveClick = () => {
        if (formData.id) {
            saveUserRequest(mandant.key, formData, formData.id)
                .then(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Der Benutzer wurde erfolgreich gespeichert",
                        type: "success",
                    });
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Beim speichern der Benutzerdaten ist ein Fehler aufgetreten",
                        type: "error",
                    });
                });
        } else {
            createUserRequest(mandant.key, formData)
                .then((response: any) => {
                    setFormData(response.data);

                    setSnackBarState({
                        isOpen: true,
                        message: "Der Benutzer wurde erfolgreich erstellt",
                        type: "success",
                    });

                    history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_USER + response.data.id));
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Beim Erstellen des Benutzers ist ein Problem aufgetreten",
                        type: "error",
                    });
                });
        }
    };

    const handleChange = (event: any) => {
        const newData = {
            ...formData,
            [event.target.name]: event.target.value,
        };
        setFormData(newData);
    };

    const handleImageChange = (image: IDocument, attrKey: string) => {
        const newData = { ...formData, [attrKey]: image };
        setFormData(newData);
    };

    const toggleGlobalRole = (event: any): void => {
        let newRoles;

        if (event.target.checked) {
            newRoles = formData.roles;
            newRoles.push(event.target.name);
        } else {
            newRoles = formData.roles.filter((role: string) => {
                return event.target.name !== role;
            });
        }

        formData.roles = newRoles;
        setFormData(deepCopy<IUser>(formData));
    };

    const toggleProjectRole = (event: any): void => {
        let newRoles;
        const pKey = parseInt(event.currentTarget.dataset.project);

        if (formData.projectRoles[pKey] === undefined) {
            formData.projectRoles[pKey] = [];
        }

        if (event.target.checked) {
            newRoles = formData.projectRoles[pKey];
            newRoles.push(event.target.name);
        } else {
            newRoles = formData.projectRoles[pKey].filter((role: string) => {
                return event.target.name !== role;
            });
        }

        formData.projectRoles[pKey] = newRoles;
        setFormData(deepCopy<IUser>(formData));
    };

    const isRoleSet = (roleName: string): boolean => {
        return !!formData.roles.find((role: string) => {
            return roleName === role;
        });
    };

    const isProjectRoleSet = (roleName: string, projectId: string): boolean => {
        const pKey = parseInt(projectId);

        if (formData.projectRoles[pKey] === undefined) {
            return false;
        }

        return !!formData.projectRoles[pKey].find((role: string) => {
            return roleName === role;
        });
    };

    const actionCancel = () => {
        history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_USER));
    };

    const isPortalUser = formData.roles.find((role: string) => {
        return role === "ROLE_PORTAL";
    });

    if (!mandant.id) {
        return <></>;
    }

    function handleBread(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href={"/" + mandant.key + RoutesEnum.ADMIN_DASHBOARD}
                    data-url={"/" + mandant.key + RoutesEnum.ADMIN_DASHBOARD}
                    onClick={handleBread}
                >
                    Start
                </Link>
                <Link
                    color="inherit"
                    href={"/" + mandant.key + RoutesEnum.ADMIN_USER}
                    data-url={"/" + mandant.key + RoutesEnum.ADMIN_USER}
                    onClick={handleBread}
                >
                    Liste der Benutzer
                </Link>
                <Typography color="textPrimary">
                    {formData.id ? (
                        <>
                            {formData.firstname} {formData.lastname}
                        </>
                    ) : (
                        <>Einen neuen Benutzer erstellen</>
                    )}
                </Typography>
            </Breadcrumbs>

            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                    <Button onClick={handleSaveClick} color="primary" startIcon={<SaveIcon />}>
                        {formData.id ? <>Meine Änderungen speichern</> : <>Den den Benutzer anlegen</>}
                    </Button>

                    <Button onClick={actionCancel} color="secondary" startIcon={<CancelIcon />}>
                        Meine Änderungen zurücksetzen
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={isPortalUser ? 12 : 6}>
                    <Paper variant="outlined" className="main-paper">
                        <Typography variant={"h3"} className="main-header gap-bottom">
                            Benutzer Daten
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <ImageUpload
                                    onChange={handleImageChange}
                                    docData={formData.image ? formData.image : { ...InitialDocumentData }}
                                    name={"image"}
                                    idKey={"image"}
                                    label={"Benutzer Bild *"}
                                    defaultFilename="Avatar.jpg"
                                    hasFreeScale={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    name="salutation"
                                    label="Anrede"
                                    fullWidth
                                    value={formData.salutation}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="firstname"
                                    label="Vorname"
                                    fullWidth
                                    value={formData.firstname}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="lastname"
                                    label="Nachname"
                                    fullWidth
                                    value={formData.lastname}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="street"
                                    label="Strasse"
                                    fullWidth
                                    value={formData.street}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="streetNo"
                                    label="Hausnummer"
                                    fullWidth
                                    value={formData.streetNo}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="city"
                                    label="Stadt"
                                    fullWidth
                                    value={formData.city}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="zip"
                                    label="Postleitzahl"
                                    fullWidth
                                    value={formData.zip}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="nickName"
                                    label="Anzeigename"
                                    fullWidth
                                    value={formData.nickName ? formData.nickName : ""}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    name="email"
                                    label="E-Mail"
                                    fullWidth
                                    value={formData.email}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="password"
                                    label="Passwort"
                                    fullWidth
                                    value={formData.password}
                                    onChange={handleChange}
                                    type="password"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    name="passwordCheck"
                                    label="Passwort bestätigen"
                                    fullWidth
                                    value={formData.passwordCheck}
                                    onChange={handleChange}
                                    type="password"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {!isPortalUser && (
                    <Grid item xs={12} md={12} lg={6}>
                        <Paper variant="outlined" className="main-paper">
                            <Typography variant={"h3"} className="main-header gap-bottom">
                                Benutzer Daten
                            </Typography>

                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isRoleSet(RoleEnum.ROLE_ADMIN)}
                                            onChange={toggleGlobalRole}
                                            name={RoleEnum.ROLE_ADMIN}
                                        />
                                    }
                                    label="Ist Admin"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isRoleSet(RoleEnum.ROLE_PROJECT_MANAGER)}
                                            onChange={toggleGlobalRole}
                                            name={RoleEnum.ROLE_PROJECT_MANAGER}
                                        />
                                    }
                                    label="Projektmanager auf allen Projekten"
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isRoleSet(RoleEnum.ROLE_CONTENT_MANAGER)}
                                            onChange={toggleGlobalRole}
                                            name={RoleEnum.ROLE_CONTENT_MANAGER}
                                        />
                                    }
                                    label="Bearbeiter auf allen Projekten"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isRoleSet(RoleEnum.ROLE_PROJECT_MODERATOR)}
                                            onChange={toggleGlobalRole}
                                            name={RoleEnum.ROLE_PROJECT_MODERATOR}
                                        />
                                    }
                                    label="Moderator auf allen Projekten"
                                />
                            </FormGroup>

                            <Typography variant={"h3"} className="main-header  gap-top gap-bottom">
                                Benutzer Projekt Rollen
                            </Typography>

                            <TableContainer>
                                <Table size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Projekt Name</TableCell>
                                            <TableCell>Manager</TableCell>
                                            <TableCell>Bearbeiter</TableCell>
                                            <TableCell>Moderator</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {projectList.map((project: ISelectOption) => {
                                            return (
                                                <TableRow key={"project-" + project.id}>
                                                    <TableCell>{project.label}</TableCell>
                                                    <TableCell>
                                                        <Checkbox
                                                            data-project={project.id}
                                                            checked={isProjectRoleSet(
                                                                RoleEnum.ROLE_PROJECT_MANAGER,
                                                                project.id
                                                            )}
                                                            onClick={toggleProjectRole}
                                                            name={RoleEnum.ROLE_PROJECT_MANAGER}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Checkbox
                                                            data-project={project.id}
                                                            checked={isProjectRoleSet(
                                                                RoleEnum.ROLE_CONTENT_MANAGER,
                                                                project.id
                                                            )}
                                                            onClick={toggleProjectRole}
                                                            name={RoleEnum.ROLE_CONTENT_MANAGER}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Checkbox
                                                            data-project={project.id}
                                                            checked={isProjectRoleSet(
                                                                RoleEnum.ROLE_PROJECT_MODERATOR,
                                                                project.id
                                                            )}
                                                            onClick={toggleProjectRole}
                                                            name={RoleEnum.ROLE_PROJECT_MODERATOR}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                )}
            </Grid>

            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<IMapStateProps, IOwnProps, IApplicationRootState> = (
    state: IApplicationRootState
) => {
    return {
        mandant: state.mandant.mandant,
    };
};

export default connect(mapStateToProps)(UserForm);
