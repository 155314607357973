import "./ProjectForm.css";

import { Breadcrumbs, Button, LinearProgress, Link, Switch, Toolbar } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import ArchiveIcon from "@material-ui/icons/Archive";
import SaveIcon from "@material-ui/icons/Save";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import IconPublish from "@material-ui/icons/Visibility";
import IconUnPublish from "@material-ui/icons/VisibilityOff";
import WebIcon from "@material-ui/icons/Web";
import arrayMove from "array-move";
import draft from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { MapDispatchToPropsParam, MapStateToPropsParam, connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import Notify from "../../../components/notify/Notify";
import TabContainer from "../../../components/tabContainer/TabContainer";
import { dateStateFormat } from "../../../config/config";
import RoutesEnum from "../../../routes/RoutesEnum";
import LoadCmsPageProjectSelectbox from "../../../store/cmsPageProjectSelectbox/LoadCmsPageProjectSelectbox";
import IApplicationRootState from "../../../store/StoreTypes";
import { ICmsPageChild } from "../../../types/ICmsPage";
import IDocument from "../../../types/IDocument";
import IEventFull from "../../../types/IEventFull";
import IEventList from "../../../types/IEventList";
import IMandant from "../../../types/IMandant";
import InitialProjectFullData from "../../../types/initialData/InitialProjectFullData";
import InitialProjectGalleryElementData from "../../../types/initialData/InitialProjectGalleryElementData";
import InitialProjectResultElementData from "../../../types/initialData/InitialProjectResultElementData";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import IProjectFull from "../../../types/IProjectFull";
import IProjectGalleryImage from "../../../types/IProjectGalleryImage";
import IProjectResult from "../../../types/IProjectResult";
import ISnackbarState from "../../../types/ISnackbarState";
import deepCopy from "../../../utils/deepCopy";
import { getEditorStateContent } from "../../../utils/editorContentHelper/editorContentHelper";
import { getIds } from "../../../utils/getIds";
import DevLogger from "../../../utils/logger/DevLogger";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import { deleteEventRequest, switchEventPublishFlagRequest } from "../../../xhr/EventRequests";
import {
    adminGetSingleProjectRequest,
    createProjectRequest,
    loadProjectAttachmentsRequest,
    loadProjectProjectRequest,
    sortProjectFilesRequest,
    sortProjectImagesRequest,
    sortProjectResultsRequest,
    switchProjectPublishFlagRequest,
    updateProjectFileRequest,
    updateProjectGalleryRequest,
    updateProjectRequest,
    updateProjectResultsRequest,
} from "../../../xhr/ProjectRequests";
import { sortProjectTeamRequest } from "../../../xhr/TeamRequests";
import {
    IProjectValidationState,
    IProjectValidationStateWithDocumentAndImage,
    requiredProjectDataFilled,
    requiredProjectResulatsDataFilled,
} from "./projectDataValidation";
import ConsultingTab from "./tabs/ConsultingTab";
import DeadlineListingTab from "./tabs/DeadlineListingTab";
import EventListingTab from "./tabs/EventListingTab";
import ParticipationDescTab from "./tabs/ParticipationDescTab";
import ProjectDataTab from "./tabs/ProjectDataTab";
import ProjectGalleryTab from "./tabs/ProjectGalleryTab";
import ProjectInfoTab from "./tabs/ProjectInfoTab";
import ProjectMenuTab from "./tabs/ProjectMenuTab";
import ProjectResultsTab from "./tabs/ProjectResultsTab";
import ProjectStartpageTab from "./tabs/ProjectStartpageTab";
import ProjectTeamTab from "./tabs/ProjectTeamTab";

interface ILocalImageGallery {
    [key: string]: IProjectGalleryImage[];
}

interface IStateProps {
    mandant: IMandant;
}

interface IMapDispatchProps {
    dispatchLoadCmsPageProjectSelectbox: (mandantKey: string, projectId: string) => void;
}
interface IOwnProps {
    mandant: IMandant;
    match: any;
    loading: boolean;
    cmsPageProjectData: ICmsPageChild[];
}

interface IMenuTabEditFormProps extends IOwnProps, IMapDispatchProps, IStateProps {}

const ProjectForm = (props: IMenuTabEditFormProps) => {
    const history = useHistory();
    const confirm = useConfirm();
    const urlParams = useParams<{ id: string }>();

    const { mandant } = props;
    // Redux
    const { dispatchLoadCmsPageProjectSelectbox, cmsPageProjectData } = props;

    const [activeTab, setActiveTab] = React.useState<number>(1);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isEditForm, setIsEditForm] = React.useState<boolean>(!isNaN(props.match.params.id));

    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const [changesNotSavedState, setChangesNotSavedState] = React.useState<boolean>(false);

    const [projectData, setProjectData] = React.useState<IProjectFull>({
        ...InitialProjectFullData,
    });

    const [showDoku, setShowDoku] = React.useState<boolean>(false);

    const [projectZoom, setProjectZoom] = React.useState<number>(4);

    const [errorState, setErrorState] = React.useState<IProjectValidationState>({ hasError: false });
    const [projectResultsErrorState, setProjectResultsErrorState] = React.useState<
        IProjectValidationStateWithDocumentAndImage[]
    >([]);

    /**
     * Gallery states
     */
    const [images, setImages] = React.useState<ILocalImageGallery>({});
    const [projectResults, setProjectResults] = React.useState<IProjectResult[]>([]);

    const [galleryLoading, setGalleryLoading] = React.useState<boolean>(false);

    const closeChangesNotSavedWarning = () => {
        setChangesNotSavedState(false);
    };
    const openChangesNotSavedWarning = () => {
        setChangesNotSavedState(true);
    };

    /* load project data to state */
    const loadProjectToState = (projectId: string) => {
        setLoading(true);
        adminGetSingleProjectRequest(mandant.key, projectId)
            .then((response) => {
                setIsEditForm(true);
                setProjectZoom(response.data.zoom);
                setProjectData(response.data);

                loadProjectAttachmentsRequest(mandant.key, props.match.params.id).then((res) => {
                    images.attachments = res.data ? [...res.data] : [];
                    images.default = response.data.images ? [...response.data.images] : [];

                    setImages(deepCopy<ILocalImageGallery>(images));
                });

                loadProjectProjectRequest(mandant.key, props.match.params.id).then((res) => {
                    setProjectResults(deepCopy<IProjectResult[]>(res.data ? [...res.data] : []));
                });
                closeChangesNotSavedWarning();
                setLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Laden der Projektdaten ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setLoading(false);
            });
    };
    /* load files attachments to state */

    /**
     * @param oldIndex
     * @param newIndex
     * @param type
     */
    const handleOnImagesOrderChange = (oldIndex: number, newIndex: number, type: string) => {
        const arry = arrayMove(images[type], oldIndex, newIndex);

        setImages({
            ...images,
            [type]: arry,
        });
    };

    /**
     *
     */
    const handleOnImagesOrderSave = () => {
        setLoading(true);

        sortProjectImagesRequest(mandant.key, getIds(images.default), projectData.id)
            .then((response: any) => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Sortierung wurde erfolgreich gespeichert.",
                    type: "success",
                });
                setLoading(false);
                setImages({
                    default: response.data,
                });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Speichern der Sortierung ist ein Fehler aufgetreten",
                    type: "error",
                });
                setLoading(false);
            });
    };

    React.useEffect(() => {
        if (isEditForm) {
            loadProjectToState(props.match.params.id);
        }
    }, [isEditForm]);

    React.useEffect(() => {
        if (mandant.id && projectData.id) {
            dispatchLoadCmsPageProjectSelectbox(mandant.key, String(projectData.id));
        }
    }, [isEditForm, mandant, projectData, dispatchLoadCmsPageProjectSelectbox]);

    /**
     *
     */
    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

    /**
     *
     */

    const handleProjectSave = () => {
        const theValidation = requiredProjectDataFilled(projectData);
        const theProjectResultValidation = requiredProjectResulatsDataFilled(projectResults);
        const validation = {
            ...theValidation,
            hasError: theValidation.hasError,
        };
        setErrorState(validation);
        setProjectResultsErrorState(theProjectResultValidation);

        if (validation.hasError || Boolean(theProjectResultValidation.length)) {
            DevLogger.logError("errors in the project", {
                errors: theValidation,
            });
            setSnackBarState({
                isOpen: true,
                message: "Fehlende Pflichtfelder.",
                type: "error",
            });
            return;
        }

        setLoading(true);

        if (isEditForm) {
            updateProjectRequest(mandant.key, {
                ...projectData,
                zoom: projectZoom,
            })
                .then(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Das Projekt wurde erfolgreich gespeichert.",
                        type: "success",
                    });
                    setLoading(false);
                    closeChangesNotSavedWarning();
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Beim Speichern der Projektdaten ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setLoading(false);
                });
        } else {
            createProjectRequest(mandant.key, projectData)
                .then((response: any) => {
                    history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_PROJECT + response.data.id));

                    setIsEditForm(true);
                    loadProjectToState(response.data.id);

                    setSnackBarState({
                        isOpen: true,
                        message: "Das Projekt wurde erfolgreich erstellt.",
                        type: "success",
                    });
                    closeChangesNotSavedWarning();
                    setLoading(false);
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Beim Erstellen des Projektes ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setLoading(false);
                });
        }
    };

    /**
     * @param sortedlistTeam
     */
    const updateProjectParticipantOrder = (sortedlistTeam: any) => {
        setLoading(true);

        sortProjectTeamRequest(mandant.key, sortedlistTeam, projectData.id)
            .then((response: any) => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Sortierung wurde erfolgreich gespeichert.",
                    type: "success",
                });
                setProjectData({
                    ...projectData,
                    team: response.data,
                });
                setLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Speichern der Sortierung ist ein Fehler aufgetreten",
                    type: "error",
                });
                setLoading(false);
            });
    };

    /**
     */
    const onEventEditClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        history.push(
            toPortalUrl(
                mandant,
                RoutesEnum.ADMIN_PROJECT + urlParams?.id + RoutesEnum.ADMIN_SUB_EVENT + event.currentTarget.dataset.id
            )
        );
    };

    /**
     */
    const onEventCreateProjectClick = () => {
        history.push(
            toPortalUrl(mandant, RoutesEnum.ADMIN_PROJECT + urlParams?.id + RoutesEnum.ADMIN_SUB_EVENT + "create")
        );
    };

    /**
     * @param event
     */
    const onEventDeleteClick = (event: IEventFull) => {
        confirm({
            description: "Möchten Sie diese Veranstaltung wirklich löschen?",
            confirmationText: "Ja",
            cancellationText: "Nein",
            title: "Bitte bestätigen",
        })
            .then(() => {
                setLoading(true);

                deleteEventRequest(event.id, mandant.key)
                    .then(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Die Veranstaltung wurde gelöscht.",
                            type: "success",
                        });

                        projectData.events = projectData.events.filter((checkEvent: IEventList): boolean => {
                            return event.id !== checkEvent.id;
                        });

                        setProjectData(deepCopy<IProjectFull>(projectData));

                        setLoading(false);
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Beim Löschen der Veranstaltung ist ein Fehler aufgetreten.",
                            type: "error",
                        });
                        setLoading(false);
                    });
            })
            .catch(() => {
                return;
            });
    };

    const onEventPublishClick = (event: IEventList) => {
        switchEventPublishFlagRequest(event.id, mandant.key)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: event.flagIsPublished
                        ? "Die Veranstaltung wurde depubliziert."
                        : "Die Veranstaltung wurde publiziert.",
                    type: "success",
                });

                // it's a reference. should work
                event.flagIsPublished = !event.flagIsPublished;
                setProjectData(deepCopy<IProjectFull>(projectData));
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Publizieren der Veranstaltung is ein Fehler aufgetreten",
                    type: "error",
                });
            });
    };

    /*
      Handle generic change
     */
    const handleTextChange = (event: any) => {
        const newData = {
            ...projectData,
            [event.target.name]: event.target.value,
        };
        setProjectData(newData);
        openChangesNotSavedWarning();
    };

    const handleImageChange = (image: IDocument, attrKey: string) => {
        const newData = { ...projectData, [attrKey]: image };
        setProjectData(newData);
        openChangesNotSavedWarning();
    };

    const handleWysiwygChange = (editorState: any, name: string) => {
        const rawContentState = draft.convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(rawContentState);
        let newData: IProjectFull = InitialProjectFullData;
        newData = {
            ...projectData,
            [name]: getEditorStateContent(editorState, markup),
        };
        setProjectData(newData);
        openChangesNotSavedWarning();
    };

    const handleCheckboxChange = (event: any) => {
        const newData = {
            ...projectData,
            [event.target.name]: event.target.checked,
        };
        setProjectData(newData);
        openChangesNotSavedWarning();
    };

    const handleProjectEndChange = (date: Date) => {
        setProjectData({
            ...projectData,
            theEnd: dateStateFormat(date),
        });
        openChangesNotSavedWarning();
    };
    const handleProjectStartChange = (date: Date) => {
        setProjectData({
            ...projectData,
            theStart: dateStateFormat(date),
        });
        openChangesNotSavedWarning();
    };

    const handleProjectPublishFlagRequest = () => {
        setLoading(true);

        switchProjectPublishFlagRequest(projectData.id, props.mandant.key)
            .then(() => {
                const message = projectData.flagPublished
                    ? "Das Projekt wurde offline genommen"
                    : "Das Projekt wurde publiziert.";
                setSnackBarState({
                    isOpen: true,
                    message,
                    type: "success",
                });

                setProjectData({
                    ...projectData,
                    flagPublished: !projectData.flagPublished,
                });
                setLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die aktualisierte Projektliste konnte nicht geladen werden",
                    type: "error",
                });
                setLoading(false);
            });
    };
    const handleProjectArchiveFlagRequest = () => {
        setLoading(true);

        confirm({
            description: `${
                projectData.flagArchive
                    ? "Möchten Sie dieses Projekt wirklich de archivieren?"
                    : "Möchten Sie dieses Projekt wirklich archivieren?"
            }`,
            confirmationText: "Ja",
            cancellationText: "Nein",
            title: "Bitte bestätigen",
        })
            .then(() => {
                updateProjectRequest(mandant.key, {
                    ...projectData,
                    flagArchive: !projectData.flagArchive,
                })
                    .then(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: `${
                                projectData.flagArchive
                                    ? "Das Projekt wurde de archivieren."
                                    : "Das Projekt wurde archiviert."
                            }`,
                            type: "success",
                        });
                        setProjectData({
                            ...projectData,
                            flagArchive: !projectData.flagArchive,
                        });
                        setLoading(false);
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: `Beim ${
                                projectData.flagArchive ? "De Archivieren" : "Archivieren"
                            }  des Projekts ist ein Fehler aufgetreten.`,
                            type: "error",
                        });
                        setLoading(false);
                    });
            })
            .catch(() => {
                return;
            });
    };

    const handleOpenPreviewForPage = () => {
        const linkMap: any = {
            p1: projectData.urlKey + "/start",
            p2: projectData.urlKey + "/start",
            p3: projectData.urlKey + "/start",
            p5: projectData.urlKey + "/teilnehmen",
            p6: projectData.urlKey + "/beratung",
            p7: projectData.urlKey + "/ereignisse",
            p8: projectData.urlKey + "/ergebnisse",
            p9: projectData.urlKey + "/ueber",
            p11: projectData.urlKey + "/ueber",
            p12: projectData.urlKey + "/team",
        };

        if (changesNotSavedState) {
            confirm({
                description:
                    "Das Projekt hat ungespeicherte Änderungen. Bitte speichern Sie zuerst das Projekt wenn Sie die Änderungen sehen möchten.",
                confirmationText: "Trotzdem anschauen",
                cancellationText: "Erst speichern",
                title: "Inhalt anzeigen",
            }).then(() => {
                const previewUrl: string = linkMap["p" + activeTab]
                    ? linkMap["p" + activeTab]
                    : projectData.urlKey + "/start";

                window.open(toPortalUrl(mandant, previewUrl), "preview");
            });
        } else {
            const previewUrl: string = linkMap["p" + activeTab]
                ? linkMap["p" + activeTab]
                : projectData.urlKey + "/start";

            window.open(toPortalUrl(mandant, previewUrl), "preview");
        }
    };

    /*
     * Functions for the gallery tab
     */

    const handleGalleryUpload = (type: string, image: IProjectGalleryImage, index: number): void => {
        images[type][index] = image;
        setImages(deepCopy<ILocalImageGallery>(images));
        openChangesNotSavedWarning();
    };

    const handleGalleryDelete = (type: string, index: number): void => {
        confirm({
            description: "Bitte bestätigen sie, dass sie das Bild löschen möchten.",
            confirmationText: "Ja löschen",
            cancellationText: "Abbrechen",
            title: "Bild aus der Galerie löschen",
        }).then(() => {
            images[type].splice(index, 1);
            setImages(deepCopy<ILocalImageGallery>(images));
            openChangesNotSavedWarning();
        });
    };

    const handleGalleryItemReset = (type: string, index: number, imageId: number): void => {
        const oldImage = projectData.images.find((checkImg: IProjectGalleryImage) => {
            return checkImg.id === imageId;
        });

        // will always exist
        if (oldImage) {
            images[type][index] = oldImage;
        }

        setImages(deepCopy<ILocalImageGallery>(images));
    };

    const handleGalleryCreateImage = (type: string): void => {
        const newImage = { ...InitialProjectGalleryElementData, type };

        if (images[type].length) {
            images[type] = [newImage].concat(images[type]);
        } else {
            images[type] = [newImage];
        }

        setImages(deepCopy<ILocalImageGallery>(images));
    };

    //updateProjectGalleryRequest

    const handleSaveImages = (type: string): void => {
        setGalleryLoading(true);
        updateProjectGalleryRequest(mandant.key, projectData.id, type, images[type])
            .then((response: any) => {
                setImages({ ...images, [type]: [...response.data] });

                setProjectData({
                    ...projectData,
                    images: [...response.data],
                });

                setSnackBarState({
                    isOpen: true,
                    message: "Die Galerie wurde gespeichert.",
                    type: "success",
                });
                setGalleryLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim speichern der Galerie ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setGalleryLoading(false);
            });
    };

    /**
     *
     * @param type
     */
    const handleSaveFiles = (type: string): void => {
        setGalleryLoading(true);

        updateProjectFileRequest(mandant.key, projectData.id, type, images[type])
            .then((response: any) => {
                setImages({ ...images, [type]: [...response.data] });
                setProjectData({
                    ...projectData,
                    images: [...response.data],
                });

                setSnackBarState({
                    isOpen: true,
                    message: "Die Galerie wurde gespeichert.",
                    type: "success",
                });
                setGalleryLoading(false);
            })
            .catch(() => {
                //setLoading(false);
                setSnackBarState({
                    isOpen: true,
                    message: "Beim speichern der Galerie ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setGalleryLoading(false);
            });
    };

    // Project Result

    /**
     *
     */
    const handleProjectResultCreate = (): void => {
        const newProjectResult = { ...InitialProjectResultElementData };
        projectResults.push(newProjectResult);
        setProjectResults(deepCopy<IProjectResult[]>(projectResults));
        openChangesNotSavedWarning();
    };

    /**
     *
     */
    const handleSaveProjectResults = (): void => {
        const theValidation = requiredProjectResulatsDataFilled(projectResults);
        setProjectResultsErrorState(theValidation);
        if (theValidation.length) {
            DevLogger.logError("errors in the project", {
                errors: theValidation,
            });
            setSnackBarState({
                isOpen: true,
                message: "Fehlende Pflichtfelder.",
                type: "error",
            });
            return;
        }
        setGalleryLoading(true);

        updateProjectResultsRequest(mandant.key, projectData.id, projectResults)
            .then((response: any) => {
                setProjectResults(deepCopy<IProjectResult[]>(response.data));

                setProjectData({
                    ...projectData,
                    projectResults: deepCopy<IProjectResult[]>(response.data),
                });

                setSnackBarState({
                    isOpen: true,
                    message: "Die Ergebnisse wurde gespeichert.",
                    type: "success",
                });
                setGalleryLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Speichern der Ergebnisse ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setGalleryLoading(false);
            });
    };

    /**
     * @param oldIndex
     * @param newIndex
     */
    const handleOnProjectResultOrderChange = (oldIndex: number, newIndex: number) => {
        const array = arrayMove(projectResults, oldIndex, newIndex);
        setProjectResults(deepCopy<IProjectResult[]>(array));
        openChangesNotSavedWarning();
    };

    /**
     *
     */
    const handleOnProjectResultsOrderSave = () => {
        setLoading(true);

        sortProjectResultsRequest(mandant.key, getIds(projectResults), projectData.id)
            .then((response: any) => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Sortierung wurde erfolgreich gespeichert.",
                    type: "success",
                });
                setLoading(false);
                setImages({
                    default: response.data,
                });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Speichern der Sortierung ist ein Fehler aufgetreten",
                    type: "error",
                });
                setLoading(false);
            });
    };

    const handleProjectResultItemReset = (index: number, resultId: number): void => {
        const oldResult = projectData.projectResults.find((checkRes: IProjectResult) => {
            return checkRes.id === resultId;
        });

        // will always exist
        if (oldResult) {
            projectResults[index] = oldResult;
        }

        setProjectResults(deepCopy<IProjectResult[]>(projectResults));
        openChangesNotSavedWarning();
    };

    const handleProjectResultUpload = React.useCallback(
        (projectResult: IProjectResult, index: number): void => {
            const updatedProjectResults = [...projectResults]; // Create a copy of the projectResults array
            updatedProjectResults[index] = projectResult; // Update the specific index with the new projectResult
            setProjectResults(updatedProjectResults); // Update the state with the updated array
            openChangesNotSavedWarning(); // Call the function to indicate changes are not saved
        },
        [projectResults, openChangesNotSavedWarning]
    );

    const handleProjectResultDelete = (index: number): void => {
        confirm({
            description: "Bitte bestätigen sie, dass sie das Ergebnis löschen möchten.",
            confirmationText: "Ja löschen",
            cancellationText: "Abbrechen",
            title: "Ergebnis aus der Liste löschen",
        }).then(() => {
            projectResults.splice(index, 1);
            setProjectResults(deepCopy<IProjectResult[]>(projectResults));
            openChangesNotSavedWarning();
        });
    };

    function handleBread(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }
    /* handle backend save files order */
    const handleOnFileOrderSave = (type: string) => {
        setLoading(true);

        sortProjectFilesRequest(mandant.key, getIds(images[type]), projectData.id)
            .then((response: any) => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Sortierung wurde erfolgreich gespeichert.",
                    type: "success",
                });
                setLoading(false);
                setImages({
                    ...images,
                    [type]: response.data,
                });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Speichern der Sortierung ist ein Fehler aufgetreten",
                    type: "error",
                });
                setLoading(false);
            });
    };

    /**
     * @param zoom
     */
    const changeStateZoom = (zoom: number) => {
        setProjectZoom(zoom);
        // openChangesNotSavedWarning();
    };
    /* update location state */
    /**
     *
     * @param latitude
     * @param longitude
     * @param zoom
     */
    const handleProjectLocationChange = (latitude: number, longitude: number, zoom: number) => {
        const newData = {
            ...projectData,
            latitude,
            longitude,
            zoom,
        };
        setProjectData(deepCopy(newData));
        openChangesNotSavedWarning();
    };

    return (
        <>
            {loading && <LinearProgress color={"primary"} style={{ marginBottom: "16px" }} />}
            {projectData.id === 0 && !isNaN(props.match.params.id) ? (
                <LinearProgress color={"primary"} style={{ marginBottom: "16px" }} />
            ) : (
                <>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            color="inherit"
                            href={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                            data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                            onClick={handleBread}
                        >
                            Projekte verwalten
                        </Link>
                        <Typography color="textPrimary">
                            {isEditForm ? <>{projectData.title}</> : <>Ein neues Projekt erstellen</>}
                        </Typography>
                    </Breadcrumbs>

                    <Toolbar disableGutters>
                        <Button color="primary" onClick={handleProjectSave} startIcon={<SaveIcon />}>
                            Projekt Speichern
                        </Button>
                        {projectData.id > 0 && (
                            <Button
                                color="secondary"
                                onClick={handleProjectPublishFlagRequest}
                                startIcon={projectData.flagPublished ? <IconUnPublish /> : <IconPublish />}
                            >
                                {projectData.flagPublished ? "Projekt depublizieren" : "Projekt publizieren"}
                            </Button>
                        )}
                        {projectData.id > 0 && (
                            <>
                                <Button
                                    color="secondary"
                                    onClick={handleProjectArchiveFlagRequest}
                                    startIcon={projectData.flagArchive ? <UnarchiveIcon /> : <ArchiveIcon />}
                                >
                                    {projectData.flagArchive ? "Projekt de archivieren" : "Projekt archivieren"}
                                </Button>
                                <Button color="secondary" onClick={handleOpenPreviewForPage} startIcon={<WebIcon />}>
                                    Vorschau anzeigen
                                </Button>

                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={showDoku}
                                            onChange={() => {
                                                setShowDoku(!showDoku);
                                            }}
                                            color={"primary"}
                                        />
                                    }
                                    label="Hilfstexte anzeigen"
                                />
                            </>
                        )}
                    </Toolbar>

                    <TabContainer
                        isAdmin={true}
                        tabs={[
                            {
                                id: "tab-project-data",
                                label: "Projekt Daten",
                                display: true,
                                tabKey: 1,
                                content: (
                                    <ProjectDataTab
                                        onProjectLocationChange={handleProjectLocationChange}
                                        handleZoomChange={changeStateZoom}
                                        projectData={{
                                            ...projectData,
                                            zoom: projectZoom,
                                        }}
                                        showDoku={showDoku}
                                        mandant={mandant}
                                        cmsPageProjectData={cmsPageProjectData}
                                        onTextChange={handleTextChange}
                                        onImageChange={handleImageChange}
                                        onCheckboxChange={handleCheckboxChange}
                                        onEditStart={handleProjectStartChange}
                                        onEditEnd={handleProjectEndChange}
                                        errorState={errorState}
                                    />
                                ),
                            },
                            {
                                id: "tab-menu",
                                label: "Projekt Menu",
                                display: !!projectData.flagEnableProjectCms && !projectData.flagLinkExternalOnly,
                                tabKey: 2,
                                content: (
                                    <ProjectMenuTab
                                        mandant={mandant}
                                        projectData={projectData}
                                        onTextChange={handleTextChange}
                                        setSnackBarState={setSnackBarState}
                                        cmsPageProjectData={cmsPageProjectData}
                                    />
                                ),
                            },
                            {
                                id: "tab-start-page",
                                label: "Startseite",
                                display: !projectData.flagLinkExternalOnly,
                                tabKey: 3,
                                content: (
                                    <ProjectStartpageTab
                                        projectData={projectData}
                                        mandant={mandant}
                                        onTextChange={handleTextChange}
                                        onImageChange={handleImageChange}
                                        onChangeWysiwyg={handleWysiwygChange}
                                        errorState={errorState}
                                    />
                                ),
                            },
                            {
                                id: "tab-infos",
                                label: "Informationen",
                                display: !projectData.flagLinkExternalOnly,
                                tabKey: 4,
                                content: (
                                    <ProjectInfoTab
                                        projectData={projectData}
                                        mandant={mandant}
                                        onTextChange={handleTextChange}
                                        onImageChange={handleImageChange}
                                        onChangeWysiwyg={handleWysiwygChange}
                                        onCheckboxChange={handleCheckboxChange}
                                        errorState={errorState}
                                        downloads={{
                                            images: images.attachments,
                                            loadingGallery: galleryLoading,
                                            onUpload: handleGalleryUpload,
                                            onDelete: handleGalleryDelete,
                                            onResetImage: handleGalleryItemReset,
                                            onSave: handleSaveFiles,
                                            onImageCreate: handleGalleryCreateImage,
                                            onSort: handleOnImagesOrderChange,
                                            handleImagesSortSave: handleOnFileOrderSave,
                                        }}
                                    />
                                ),
                            },
                            {
                                id: "tab-image-gallery",
                                label: "Bilder Gallery",
                                display: isEditForm && !projectData.flagLinkExternalOnly,
                                tabKey: 9,
                                content: (
                                    <>
                                        {images.default && (
                                            <ProjectGalleryTab
                                                images={images.default}
                                                label="Bilder Gallery"
                                                type="default"
                                                onUpload={handleGalleryUpload}
                                                onDelete={handleGalleryDelete}
                                                onResetImage={handleGalleryItemReset}
                                                onSave={handleSaveImages}
                                                onImageCreate={handleGalleryCreateImage}
                                                loadingGallery={galleryLoading}
                                                onSort={handleOnImagesOrderChange}
                                                handleImagesSortSave={handleOnImagesOrderSave}
                                            />
                                        )}
                                    </>
                                ),
                            },

                            {
                                id: "tab-events",
                                label: "Veranstaltungen",
                                display: isEditForm && !!projectData.flagTabEvents && !projectData.flagLinkExternalOnly,
                                tabKey: 7,
                                content: (
                                    <EventListingTab
                                        projectData={projectData}
                                        mandant={mandant}
                                        onTextChange={handleTextChange}
                                        onImageChange={handleImageChange}
                                        events={projectData.events}
                                        onPublishClick={onEventPublishClick}
                                        onCreateProjectClick={onEventCreateProjectClick}
                                        onEditClick={onEventEditClick}
                                        onDeleteClick={onEventDeleteClick}
                                    />
                                ),
                            },
                            {
                                id: "tab-project-results",
                                label: "Ergebnisse",
                                display:
                                    isEditForm && !!projectData.flagTabResults && !projectData.flagLinkExternalOnly,
                                tabKey: 8,
                                content: (
                                    <>
                                        <ProjectResultsTab
                                            projectData={projectData}
                                            mandant={mandant}
                                            projectResultsErrorState={projectResultsErrorState}
                                            onTextChange={handleTextChange}
                                            onImageChange={handleImageChange}
                                            onCheckboxChange={handleCheckboxChange}
                                            projectResults={projectResults}
                                            onUpload={handleProjectResultUpload}
                                            onDelete={handleProjectResultDelete}
                                            onReset={handleProjectResultItemReset}
                                            onSave={handleSaveProjectResults}
                                            onImageCreate={handleProjectResultCreate}
                                            loadingGallery={galleryLoading}
                                            onSort={handleOnProjectResultOrderChange}
                                            handleSortSave={handleOnProjectResultsOrderSave}
                                        />
                                    </>
                                ),
                            },
                            {
                                id: "tab-participate",
                                label: "Mitmachen",
                                display: !!projectData.flagTabParticipate && !projectData.flagLinkExternalOnly,
                                tabKey: 5,
                                content: (
                                    <ParticipationDescTab
                                        projectData={projectData}
                                        mandant={mandant}
                                        onTextChange={handleTextChange}
                                        onImageChange={handleImageChange}
                                        onCheckboxChange={handleCheckboxChange}
                                        onChangeWysiwyg={handleWysiwygChange}
                                    />
                                ),
                            },
                            {
                                id: "tab-consulting",
                                label: "Beratung",
                                display: !!projectData.flagTabConsulting && !projectData.flagLinkExternalOnly,
                                tabKey: 6,
                                content: (
                                    <ConsultingTab
                                        projectData={projectData}
                                        mandant={mandant}
                                        onTextChange={handleTextChange}
                                        onImageChange={handleImageChange}
                                        onChangeWysiwyg={handleWysiwygChange}
                                    />
                                ),
                            },
                            {
                                id: "tab-team",
                                label: "Team",
                                display: isEditForm && !projectData.flagLinkExternalOnly,
                                tabKey: 12,
                                content: (
                                    <ProjectTeamTab
                                        projectData={projectData}
                                        mandant={mandant}
                                        onTextChange={handleTextChange}
                                        onImageChange={handleImageChange}
                                        onChangeWysiwyg={handleWysiwygChange}
                                        onRefreshProject={() => {
                                            loadProjectToState("" + projectData.id);
                                        }}
                                        onUpdateParticipants={updateProjectParticipantOrder}
                                    />
                                ),
                            },
                            {
                                id: "tab-deadline-events",
                                label: "Ereignisse",
                                display: isEditForm && !projectData.flagLinkExternalOnly,
                                tabKey: 11,
                                content: (
                                    <DeadlineListingTab
                                        deadlines={projectData.deadlines}
                                        mandant={props.mandant}
                                        projectID={projectData.id}
                                        onUpdate={() => {
                                            loadProjectToState("" + projectData.id);
                                        }}
                                    />
                                ),
                            },
                        ]}
                        activeTab={activeTab}
                        ariaLabel={"Project Edit Form"}
                        idKey={"Project admin"}
                        handleChange={handleChange}
                    />
                    <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
                </>
            )}
        </>
    );
};
const mapStateToProps: MapStateToPropsParam<IStateProps, {}, IApplicationRootState> = (
    state: IApplicationRootState
) => {
    return {
        mandant: state.mandant.mandant,
        cmsPageProjectData: state.cmsPageProjectSelectbox.cmsPageProjectData,
        loading: state.user.loading,
    };
};

const mapDispatchToProps: MapDispatchToPropsParam<IMapDispatchProps, IOwnProps> = (
    dispatch: ThunkDispatch<{}, {}, Action>
) => {
    return {
        dispatchLoadCmsPageProjectSelectbox: (mandantKey: string, projectId: string) => {
            return dispatch(LoadCmsPageProjectSelectbox(mandantKey, projectId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectForm);
