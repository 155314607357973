import { Grid, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SyncIcon from "@material-ui/icons/Sync";
import SyncDisabledIcon from "@material-ui/icons/SyncDisabled";
import React, { useEffect, useState } from "react";
import { arrayMove } from "react-sortable-hoc";
import uuid from "uuid-random";

import { API_URL_STATIC } from "../../../../../../config/config";
import DocumentTypeEnum from "../../../../../../enums/DocumentTypeEnum";
import IDocument from "../../../../../../types/IDocument";
import InitialDocumentData from "../../../../../../types/initialData/InitialDocumentData";
import DownloadCard from "../../../../../downloadCard/DownloadCard";
import FileUpload from "../../../../../fileUpload/FileUpload";
import { CmsContentBlock, CmsContentBlockAttachmentList } from "../../../../types/CmsPageData";
import { useCmsEditorContext } from "../../../masks/CmsEditorContext";
import { SortableListDownload } from "../../sorting/download/SortableListDownload";
import { CmsContentEditor } from "../CmsContentEditor";
import { CmsContentTypeProps } from "./CmsContentTypeProps";

export interface CmsContentAttachmentListProps extends CmsContentTypeProps {
    contentNode: CmsContentBlockAttachmentList;
}

export function CmsContentAttachmentList({
    contentNode,
    editMode,
    startEditMode,
    stopEditMode,
    showLayoutMenu,
}: CmsContentAttachmentListProps) {
    const { uploadsIndex, setUploadsIndex, setFormHasChangesAction, setContentIndex, contentIndex } =
        useCmsEditorContext();

    const [sortingEnabled, setSortingEnabled] = useState<boolean>(false);

    const updateContentNode = (newContent: CmsContentBlock) => {
        setContentIndex({
            ...contentIndex,
            [contentNode.id]: {
                ...newContent,
            } as CmsContentBlock,
        });
        setFormHasChangesAction();
    };

    useEffect(() => {
        if (!editMode) {
            if (!Array.isArray(contentNode.data) || contentNode.data.length == 0) {
                startEditMode();
            }
        }

        if (!Array.isArray(contentNode.data)) {
            const newRefId = uuid();

            updateContentNode({
                ...contentNode,
                data: [{ key: newRefId, type: DocumentTypeEnum.FILE }],
            });

            setUploadsIndex({
                ...uploadsIndex,
                [newRefId]: {
                    ...InitialDocumentData,
                    key: newRefId,
                    type: DocumentTypeEnum.FILE,
                },
            });
        }
    }, [contentNode.data, editMode]);

    const stopEditModeIfAllowed = () => {
        if (!Array.isArray(contentNode.data) || contentNode.data.length == 0) {
            return;
        }
        stopEditMode();
    };

    const handleUploadChange = (attach: Partial<IDocument>) => {
        setUploadsIndex({
            ...uploadsIndex,
            ["" + attach.key]: { ...attach } as IDocument,
        });
        setFormHasChangesAction();
    };

    const addAdditionalFile = () => {
        const newRef = uuid();

        setContentIndex({
            ...contentIndex,
            [contentNode.id]: {
                ...contentNode,
                data: [...contentNode.data, { key: newRef, type: DocumentTypeEnum.FILE }],
            } as CmsContentBlock,
        });

        setUploadsIndex({
            ...uploadsIndex,
            [newRef]: { ...InitialDocumentData, key: newRef, type: 2 },
        });

        setFormHasChangesAction();
    };

    const removeAttachmentAction = (id: string) => {
        const index = contentNode.data.findIndex((check) => {
            return check.key === id;
        });

        contentNode.data.splice(index, 1);

        const newContent = {
            ...contentIndex,
            [contentNode.id]: {
                ...contentNode,
                data: [...contentNode.data],
            } as CmsContentBlock,
        };

        setContentIndex(newContent);

        delete uploadsIndex[id];
        const newIndex = {
            ...uploadsIndex,
        };
        //delete newIndex[id];

        setUploadsIndex(newIndex);
        setFormHasChangesAction();
    };

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        setContentIndex({
            ...contentIndex,
            [contentNode.id]: {
                ...contentNode,
                data: arrayMove(contentNode.data, oldIndex, newIndex),
            } as CmsContentBlock,
        });
    };

    // the use effect will fix this
    if (!Array.isArray(contentNode.data)) {
        return <></>;
    }

    if (!editMode) {
        return (
            <Grid container spacing={2} direction="row" onClick={startEditMode}>
                {contentNode.data.map((ref, index) => {
                    const document = uploadsIndex[ref.key];
                    return (
                        <Grid key={index + ""} item xs={12} md={6} lg={4}>
                            <DownloadCard
                                tooltip={document?.name}
                                title={document?.title ? String(document.title) : String(document.name)}
                                description={document?.description != null ? document.description : ""}
                                actionButtonText="Herunterladen"
                                actionButtonClassName="App-header-red-button"
                                downloadUrl={API_URL_STATIC + document?.url}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    return (
        <CmsContentEditor
            stopEditMode={stopEditModeIfAllowed}
            contentNode={contentNode}
            updateContentNode={updateContentNode}
            showLayoutMenu={showLayoutMenu}
            additionalButtons={[
                <IconButton key="add-file" onClick={addAdditionalFile} color="primary" disabled={sortingEnabled}>
                    <AddIcon />
                </IconButton>,
                <IconButton
                    key="toggle-sorting"
                    onClick={() => {
                        setSortingEnabled(!sortingEnabled);
                    }}
                    title={sortingEnabled ? "Sortierung deaktivieren" : "Sortierung aktivieren"}
                    color="primary"
                >
                    {sortingEnabled ? <SyncDisabledIcon /> : <SyncIcon />}
                </IconButton>,
            ]}
        >
            <>
                {sortingEnabled && (
                    <SortableListDownload
                        documentIndex={uploadsIndex}
                        documents={contentNode.data}
                        onSortEnd={onSortEnd}
                        axis="xy"
                    />
                )}
                {!sortingEnabled && (
                    <Grid container spacing={2}>
                        {contentNode.data.map((docRef, fileIndex: number) => {
                            return (
                                <Grid item xs={6} key={fileIndex}>
                                    <FileUpload
                                        onChange={handleUploadChange}
                                        docData={uploadsIndex[docRef.key] as IDocument}
                                        name={fileIndex}
                                        label={"Datei"}
                                        defaultFilename="data.dat"
                                        handleRemoveAction={removeAttachmentAction}
                                        showRemoveButtonAlways={true}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </>
        </CmsContentEditor>
    );
}
