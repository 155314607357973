import {
    Breadcrumbs,
    Button,
    Grid,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import IconAdd from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PublicIcon from "@material-ui/icons/Public";
import PublishIcon from "@material-ui/icons/Publish";
import moment from "moment";
import React, { SyntheticEvent } from "react";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Notify from "../../../components/notify/Notify";
import { Format } from "../../../config/config";
import RoleEnum from "../../../enums/RoleEnum";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import IUser from "../../../store/userStore/interfaces/IUser";
import IMandant from "../../../types/IMandant";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import IPollCampaignList from "../../../types/IPollCampaignList";
import ISnackbarState from "../../../types/ISnackbarState";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import userHasRoles from "../../../utils/userHasRole";
import { deleteCommentRequest } from "../../../xhr/CommentRequests";
import {
    loadAdminPollCampaignList,
    setCampaignActiveRequest,
    setCampaignInActiveRequest,
} from "../../../xhr/PollCampaign";

type IMapStateProps = {
    mandant: IMandant;
    activeUser: IUser | null;
};

type IMapDispatchProps = {};

type IOwnProps = {
    history: any;
};

type IPortalCampaignListingParams = IMapStateProps & IMapDispatchProps & IOwnProps;

const PollCampaignListing = (props: IPortalCampaignListingParams) => {
    const history = useHistory();
    const { mandant, activeUser } = props;
    const [campaignList, setCampaignList] = React.useState<IPollCampaignList[]>([]);
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    React.useEffect(() => {
        loadAdminPollCampaignList(mandant.key)
            .then((result: any) => {
                setCampaignList(result.data);
                return Promise.resolve();
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Laden der Anfragen ist ein Fehler aufgetreten",
                    type: "error",
                });
            });
    }, [mandant]);

    if (!mandant.id) {
        return <></>;
    }

    const actionMarkAsActive = (event: SyntheticEvent<HTMLSpanElement>) => {
        const campaignId: number = parseInt("" + event.currentTarget.dataset.id);

        /*
        const index = campaignList.findIndex((search) => {
            return commentId === search.id;
        });
        */

        setCampaignActiveRequest(mandant.key, campaignId)
            .then(() => {
                loadAdminPollCampaignList(mandant.key)
                    .then((result: any) => {
                        setCampaignList(result.data);
                        return Promise.resolve();
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Beim Laden der Anfragen ist ein Fehler aufgetreten",
                            type: "error",
                        });
                    });

                setSnackBarState({
                    isOpen: true,
                    message: "Die Umfrage wurde für das Projekt aktiviert",
                    type: "success",
                });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Fehler beim aktivieren der Umfrage",
                    type: "error",
                });
            });
    };

    const actionMarkAsInActive = (event: SyntheticEvent<HTMLSpanElement>) => {
        const campaignId: number = parseInt("" + event.currentTarget.dataset.id);

        setCampaignInActiveRequest(mandant.key, campaignId)
            .then(() => {
                loadAdminPollCampaignList(mandant.key)
                    .then((result: any) => {
                        setCampaignList(result.data);
                        return Promise.resolve();
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Beim Laden der Anfragen ist ein Fehler aufgetreten",
                            type: "error",
                        });
                    });

                setSnackBarState({
                    isOpen: true,
                    message: "Die Umfrage wurde für das Projekt aktiviert",
                    type: "success",
                });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Fehler beim aktivieren der Umfrage",
                    type: "error",
                });
            });
    };

    const actionEditCampaign = (event: SyntheticEvent<HTMLSpanElement>) => {
        const campaignId: number = parseInt("" + event.currentTarget.dataset.id);

        history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_POLL_CAMPAIGN + campaignId));
    };

    const actionCreateCmpaign = () => {
        history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_POLL_CAMPAIGN + "create"));
    };

    const actionDeleteCampaign = (event: SyntheticEvent<HTMLSpanElement>) => {
        const commentId: number = parseInt("" + event.currentTarget.dataset.id);

        deleteCommentRequest(mandant.key, commentId)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Umfrage wurde erfolgreich gelöscht",
                    type: "success",
                });

                loadAdminPollCampaignList(mandant.key)
                    .then((result: any) => {
                        setCampaignList(result.data);
                        return Promise.resolve();
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Beim Laden der Umfragen ist ein Fehler aufgetreten",
                            type: "error",
                        });
                    });
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Löschen der Umfrage ist ein Fehler aufgetreten",
                    type: "error",
                });
            });
    };

    function handleBread(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    href={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                    onClick={handleBread}
                >
                    Start
                </Link>
                <Typography color="textPrimary">Projekt Umfragen</Typography>
            </Breadcrumbs>

            <Typography variant={"h3"} className="main-header gap-top">
                Projekt Umfragen
            </Typography>

            <Grid item xs={12} md={12} lg={12}>
                {userHasRoles(activeUser, [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_PROJECT_MANAGER]) && (
                    <Button onClick={actionCreateCmpaign} color="primary" startIcon={<IconAdd />}>
                        Eine neue Umfrage Kampagne anlegen
                    </Button>
                )}
            </Grid>

            <TableContainer>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Titel</TableCell>
                            <TableCell>Projekt</TableCell>
                            <TableCell>Erstell Datum</TableCell>
                            <TableCell style={{ width: "150px" }}>Menu</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {campaignList.map((campaign: IPollCampaignList, index: number) => {
                            return (
                                <TableRow key={"user" + index}>
                                    <TableCell>{campaign.title}</TableCell>
                                    <TableCell>
                                        {
                                            //@ts-ignore
                                            campaign.project?.title
                                        }
                                    </TableCell>
                                    <TableCell>{moment(campaign.creationDate).format(Format.dateTime)}</TableCell>
                                    <TableCell>
                                        {campaign.flagPublished && (
                                            <IconButton
                                                data-id={campaign.id}
                                                onClick={actionMarkAsInActive}
                                                component={"span"}
                                                size={"small"}
                                            >
                                                <PublicIcon />
                                            </IconButton>
                                        )}
                                        {!campaign.flagPublished && (
                                            <IconButton
                                                data-id={campaign.id}
                                                onClick={actionMarkAsActive}
                                                component={"span"}
                                                size={"small"}
                                            >
                                                <PublishIcon />
                                            </IconButton>
                                        )}

                                        <IconButton
                                            data-id={campaign.id}
                                            onClick={actionEditCampaign}
                                            component={"span"}
                                            size={"small"}
                                        >
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            data-id={campaign.id}
                                            onClick={actionDeleteCampaign}
                                            component={"span"}
                                            size={"small"}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<IMapStateProps, IOwnProps, IApplicationRootState> = (
    state: IApplicationRootState
) => {
    return {
        mandant: state.mandant.mandant,
        activeUser: state.user.userData,
    };
};

export default connect(mapStateToProps)(PollCampaignListing);
