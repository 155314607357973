import IMandant from "../types/IMandant";

export const toPortalUrl = (mandant: IMandant, url: string): string => {
    if (url.substr(0, 1) === "/") {
        if (mandant.flagHaveDomain) {
            return url;
        } else {
            return "/" + mandant.key + url;
        }
    } else {
        if (mandant.flagHaveDomain) {
            return "/" + url;
        } else {
            return "/" + mandant.key + "/" + url;
        }
    }
};

export const toPortalUrlFromProject = (mandant: IMandant, url: string): string => {
    if (url.substr(0, 1) === "/") {
        if (mandant.flagHaveDomain) {
            return "https://" + mandant.domain + url;
        } else {
            return mandant.key + url;
        }
    } else {
        if (mandant.flagHaveDomain) {
            return "https://" + mandant.domain + "/" + url;
        } else {
            return "/" + mandant.key + "/" + url;
        }
    }
};
