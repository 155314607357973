import { Grid, IconButton, TextField } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import draft, { EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useCallback } from "react";

import TextEdit from "../../../../../../wysiwyg/TextEdit";
import CmsFaqQuestion from "../../../../../types/CmsFaqQuestion";
import CmsFaqResponse from "../../../../../types/CmsFaqResponse";

export interface CmsFaqQuestionEditorProps {
    question: CmsFaqQuestion;
    questionIndex: number;
    updateQuestionAction: (updateData: CmsFaqQuestion, questionIndex: number) => void;
    deleteQuestionAction: (questionIndex: number) => void;
    setFormHasChangesAction: () => void;
}

export function CmsFaqQuestionEditor({
    question,
    questionIndex,
    updateQuestionAction,
    deleteQuestionAction,
    setFormHasChangesAction,
}: CmsFaqQuestionEditorProps) {
    const handleQuestionTextChange = (event: any) => {
        updateQuestionAction(
            {
                ...question,
                question: event.target.value,
            },
            questionIndex
        );
    };

    const addResponseAction = () => {
        const answers = [...question.answers, { content: "" } as CmsFaqResponse];

        updateQuestionAction(
            {
                ...question,
                answers,
            },
            questionIndex
        );

        setFormHasChangesAction();
    };

    const handleResponseTextChange = useCallback(
        (editorState: EditorState, responseIndex: number) => {
            const rawContentState = draft.convertToRaw(editorState.getCurrentContent());

            const answers = [...question.answers];
            answers[responseIndex].content = draftToHtml(rawContentState);

            updateQuestionAction(
                {
                    ...question,
                    answers,
                },
                questionIndex
            );

            setFormHasChangesAction();
        },
        [question]
    );

    const deleteResponseAction = useCallback(
        (responseIndex: number) => {
            question.answers.splice(responseIndex, 1);

            updateQuestionAction(
                {
                    ...question,
                    answers: question.answers,
                },
                questionIndex
            );

            setFormHasChangesAction();
        },
        [question.answers]
    );

    return (
        <>
            <Grid item xs={12} className="faq-list__container__questions">
                <Grid item xs={12} className="faq-list__container__questions__buttons">
                    <Tooltip title="Antwort hinzufügen">
                        <IconButton key="add-response" onClick={addResponseAction}>
                            <QuestionAnswerIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Frage löschen">
                        <IconButton
                            key="delete-question"
                            onClick={() => {
                                deleteQuestionAction(questionIndex);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <TextField
                    className="faq-list__container__questions__question"
                    autoFocus
                    variant="outlined"
                    label="Frage"
                    value={question.question ? question.question : ""}
                    onChange={handleQuestionTextChange}
                    fullWidth
                    required={true}
                />
            </Grid>
            <Grid item xs={12} className="aq-list-answers__container">
                {question.answers.map((response: CmsFaqResponse, responseIndex: number) => {
                    return (
                        <Grid
                            className="faq-list-answers__container__item"
                            xs={12}
                            key={response.id}
                            item
                            direction="row"
                        >
                            <TextEdit
                                handleChange={(editorState) => {
                                    handleResponseTextChange(editorState, responseIndex);
                                }}
                                showHelp={false}
                                title="Antwort"
                                defaultValue={response.content ? response.content : ""}
                            />
                            <Tooltip title="Frage löschen">
                                <IconButton
                                    key="Antwort löschen"
                                    className="faq-list-answers__container__item__button"
                                    onClick={() => deleteResponseAction(responseIndex)}
                                    color="secondary"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
}
