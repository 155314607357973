/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const addLocaleData = require("react-intl").addLocaleData;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const deLocaleData = require("react-intl/locale-data/de");

// eslint-disable-next-line @typescript-eslint/no-var-requires
const deTranslationMessages = require("./de.json");

addLocaleData(deLocaleData);

export const DEFAULT_LOCALE = "DE";

// prettier-ignore
export const appLocales = [
    'DE',
];

export const formatTranslationMessages = (locale: string, messages: any) => {
    const defaultFormattedMessages: any =
        locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, deTranslationMessages) : {};
    const flattenFormattedMessages = (formattedMessages: any, key: string) => {
        const formattedMessage: any =
            !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key];
        return { ...formattedMessages, [key]: formattedMessage };
    };
    return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
    DE: formatTranslationMessages("DE", deTranslationMessages),
};
