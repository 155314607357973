import expandToDecimalPlaces from "../../../utils/expandToDecimalPlaces";
import { baseProgramm } from "./FundingCalculatorForm.data";
import { FundingCalculationResult, FundingType, KfwProgramm } from "./FundingCalculatorForm.types";
import { formatNumberToVisible } from "./FundingCalculatorForm.utils";

function getBaseGap(costs: number, baseCalcParams: FundingType) {
    let gapBaseFunding = 0;
    const percentage = Number(baseCalcParams.fundingPercentage) / 100;
    const capPercentage = Number(baseCalcParams.capFundingPercentage) / 100;

    if (costs <= Number(baseCalcParams.upperGapLimit)) {
        gapBaseFunding = Number(baseCalcParams.capStatic) * percentage;
    } else {
        const base = Number(baseCalcParams.capStatic) * percentage;
        const extraFunding = costs * capPercentage;
        gapBaseFunding = base + extraFunding;
    }

    return gapBaseFunding;
}

export function calculateFunding(
    costs: number,
    activeProgramm: KfwProgramm,
    calcData: FundingType,
    baseCalcParams: FundingType
): FundingCalculationResult {
    const percentage = Number(calcData.fundingPercentage) / 100;
    const capPercentage = Number(calcData.capFundingPercentage) / 100;
    const extraFunding = costs * capPercentage;

    const baseFunding = costs * percentage;

    let amountFounding = 0;

    if (activeProgramm == baseProgramm) {
        if (costs <= Number(baseCalcParams.lowerGapLimit)) {
            amountFounding = baseFunding;
        } else if (costs <= Number(baseCalcParams.upperGapLimit)) {
            amountFounding = Number(baseCalcParams.lowerGapLimit) * percentage;
        } else {
            const base = Number(baseCalcParams.capStatic) * percentage;
            amountFounding = base + extraFunding;
        }
    } else {
        if (costs <= Number(baseCalcParams.lowerGapLimit)) {
            amountFounding = baseFunding;
        } else {
            const baseProgrammFunding = getBaseGap(costs, baseCalcParams);
            amountFounding = baseProgrammFunding + extraFunding;
        }
    }

    let error: string | undefined;
    if (amountFounding > Number(calcData.capTotal)) {
        amountFounding = Number(calcData.capTotal);
        error = `Für dieses Förderprogramm ist die Förderung auf ${formatNumberToVisible(
            calcData.capTotal
        )} € gedeckelt.`;
    }

    const fundingPercentage = expandToDecimalPlaces((amountFounding / costs) * 100, 1);

    return {
        amountFunding: amountFounding,
        percentOfEligibleCosts: fundingPercentage,
        error,
    };
}
