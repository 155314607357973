import { ADMIN_FEEDBACK, FEEDBACK, FEEDBACK_FORM_DATA } from "../enums/Endpoints";
import IFeedback from "../types/IFeedback";
import IFeedbackList from "../types/IFeedbackList";
import { deleteData, getData, postData, putData } from "../utils/httpRequest/request";

const createNewFeedback = (mandant: string, dataBody: IFeedback): Promise<any> => {
    return postData<IFeedback, {}>(FEEDBACK, dataBody, {
        mandant,
        isPublic: true,
        withLang: true,
    });
};

const loadFeedbackList = (mandant: string): Promise<any> => {
    return getData<IFeedbackList[]>(ADMIN_FEEDBACK, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const loadFeedbackFormData = (mandant: string): Promise<any> => {
    return getData<IFeedbackList[]>(FEEDBACK_FORM_DATA, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const updateFeedbackRequest = (dataBody: any, feedbackId: number, mandant: string): Promise<any> => {
    return putData<any, any>(ADMIN_FEEDBACK, feedbackId, dataBody, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

const deleteFeedbackRequest = (feedbackId: number, mandant: string): Promise<any> => {
    return deleteData<any>(ADMIN_FEEDBACK, feedbackId, {
        mandant,
        isPublic: false,
        withLang: true,
    });
};

export { createNewFeedback, loadFeedbackList, updateFeedbackRequest, deleteFeedbackRequest, loadFeedbackFormData };
