import { Breadcrumbs, Button, Grid, LinearProgress, Link, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import arrayMove from "array-move";
import draft from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { MapDispatchToPropsParam, connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import Notify from "../../../components/notify/Notify";
import TabContainer from "../../../components/tabContainer/TabContainer";
import RoutesEnum from "../../../routes/RoutesEnum";
import LoadMandantAction from "../../../store/mandantStore/LoadMandant";
import IDocument from "../../../types/IDocument";
import IMandant from "../../../types/IMandant";
import InitialDocumentData from "../../../types/initialData/InitialDocumentData";
import InitialMandantData from "../../../types/initialData/InitialMandantData";
import InitialPartnerLogoData from "../../../types/initialData/InitialPartnerLogoData";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import IPartnerLogo from "../../../types/IPartnerLogo";
import ISnackbarState from "../../../types/ISnackbarState";
import deepCopy from "../../../utils/deepCopy";
import { getEditorStateContent } from "../../../utils/editorContentHelper/editorContentHelper";
import DevLogger from "../../../utils/logger/DevLogger";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import {
    createMandantRequest,
    loadMandantSingleRequest,
    resetAdminPasswortRequest,
    saveMandantRequest,
} from "../../../xhr/MandantRequests";
import ChangeAdminPasswordModal, { IChangeAdminPasswordForm } from "./modal/ChangeAdminPasswordModal";
import MandantDataTab from "./tabs/MandantDataTab";
import MandantInfoTab from "./tabs/MandantInfoTab";
import MandantPartnerLogoTab from "./tabs/MandantPartnerLogoTab";
import MandantTextTab from "./tabs/MandantTextTab";
import { IMandantValidationState, requiredMandantDataFilled } from "./validations/mandantDataValidation";

interface IMapDispatchProps {
    handleLoadMandant: (mandant: IMandant) => void;
}

interface IOwnProps {
    mandant: IMandant;
}

interface IUserListingParams extends IMapDispatchProps, IOwnProps {}

const MandantForm = (props: IUserListingParams) => {
    const history = useHistory();
    const confirm = useConfirm();
    const params = useParams<{
        id: string;
    }>();
    const mandantId = params.id;
    const { mandant, handleLoadMandant } = props;
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [changeModalIsOpen, setChangeModalIsOpen] = React.useState<boolean>(false);

    /**
     * Gallery states
     */
    const [partnerLogos, setPartnerLogos] = React.useState<IPartnerLogo[]>([]);

    const [activeTab, setActiveTab] = React.useState<number>(1);
    const [mandantData, setMandantData] = React.useState<IMandant>({
        ...mandant,
    });

    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const [errorState, setErrorState] = React.useState<IMandantValidationState>({ hasError: false });

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    const closeChangeModal = () => {
        setChangeModalIsOpen(false);
    };

    React.useEffect(() => {
        if (mandantData) {
            setPartnerLogos(mandantData.partnerLogos);
        }
    }, [mandantData]);

    React.useEffect(() => {
        if (mandantId) {
            if (mandantId === "create") {
                setMandantData({ ...InitialMandantData });
            } else {
                loadMandantSingleRequest(mandant.key, mandantId)
                    .then((response: any) => {
                        const mandantFormData = response.data;
                        if (!mandantFormData.favIcon) {
                            mandantFormData.favIcon = {
                                ...InitialDocumentData,
                            };
                        }
                        setMandantData(mandantFormData);
                        setPartnerLogos(mandant.partnerLogos);
                    })
                    .catch(() => {
                        setSnackBarState({
                            isOpen: true,
                            message: "Beim Laden der Daten ist ein Fehler aufgetreten",
                            type: "error",
                        });
                    });
            }
        } else {
            if (!mandant.favIcon) {
                mandant.favIcon = { ...InitialDocumentData };
            }

            setMandantData({ ...mandant });
        }
    }, [mandant, mandantId]);

    const resetMyChangesAction = () => {
        setMandantData({
            ...mandant,
        });
    };

    const setChangeModalPasswordOpen = () => {
        setChangeModalIsOpen(true);
    };

    const handleTabChange = (event: any, newTab: number) => {
        setActiveTab(newTab);
    };

    const handleChange = (event: any) => {
        const newData = {
            ...mandantData,
            [event.target.name]: event.target.value,
        };
        setMandantData(newData);
    };

    const handleCheckboxChange = (event: any) => {
        const newData = {
            ...mandantData,
            [event.target.name]: event.target.checked,
        };

        setMandantData(newData);
    };

    const handleImageChange = (image: IDocument, attrKey: string) => {
        const newData = { ...mandantData, [attrKey]: image };
        DevLogger.logInfo("mandant imgs changes", newData);
        setMandantData(newData);
    };
    const handleWysiwygChange = (editorState: any, name: string) => {
        const rawContentState = draft.convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(rawContentState);
        let newData: IMandant = InitialMandantData;
        newData = {
            ...mandantData,
            [name]: getEditorStateContent(editorState, markup),
        };
        setMandantData(newData);
    };

    const handleSaveClick = () => {
        setIsLoading(true);

        const newErrorState = requiredMandantDataFilled(mandantData);
        setErrorState(newErrorState);

        if (newErrorState.hasError) {
            setIsLoading(false);
            setSnackBarState({
                isOpen: true,
                message: "Fehlende Pflichtfelder.",
                type: "error",
            });
            return;
        }
        if (mandantId === "create") {
            createMandantRequest(mandant.key, {
                ...mandantData,
                flagShowBigCustomerLogo: mandantData.flagShowBigCustomerLogo
                    ? mandantData.flagShowBigCustomerLogo
                    : false,
                partnerLogos,
            })
                .then((response: any) => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Das Portal wurde erfolgreich erstellt",
                        type: "success",
                    });

                    setIsLoading(false);

                    history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_PORTAL + response.data.id));
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Fehler beim Speichern der Portaldaten",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        } else {
            saveMandantRequest(mandant.key, { ...mandantData, partnerLogos }, mandantData.id)
                .then(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Das Portal wurde erfolgreich gespeichert",
                        type: "success",
                    });
                    setIsLoading(false);
                    handleLoadMandant(mandant);
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Fehler beim Speichern der Portaldaten",
                        type: "error",
                    });
                    setIsLoading(false);
                });
        }
    };

    const resetAdminPasswortAction = (formData: IChangeAdminPasswordForm) => {
        setIsLoading(true);

        resetAdminPasswortRequest(mandant.key, formData)
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Das Adminpasswort des Portals wurde zurück gesetzt.",
                    type: "success",
                });

                setIsLoading(false);
                setChangeModalIsOpen(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Fehler beim zurücksetzen des Adminpasswort.",
                    type: "error",
                });
                setIsLoading(false);
            });
    };

    const handlePartnerLogoUpload = (image: IPartnerLogo, index: number): void => {
        partnerLogos[index] = image;
        setPartnerLogos(deepCopy<IPartnerLogo[]>(partnerLogos));
    };

    const handlePartnerLogoDelete = (index: number): void => {
        confirm({
            description: "Bitte bestätigen sie, dass sie das Logo löschen möchten.",
            confirmationText: "Ja löschen",
            cancellationText: "Abbrechen",
            title: "Logo aus der Liste löschen",
        }).then(() => {
            partnerLogos.splice(index, 1);
            setPartnerLogos(deepCopy<IPartnerLogo[]>(partnerLogos));
        });
    };
    /**
     * Update partner logos list on local state
     */
    const handleOnPartnerLogosOrderSave = (sortedData: IPartnerLogo[] | undefined) => {
        if (sortedData) {
            setPartnerLogos(sortedData);
        }
    };

    const handlePartnerLogoReset = (index: number, imageId: number): void => {
        const oldImage = mandant.partnerLogos.find((checkImg: IPartnerLogo) => {
            return checkImg.id === imageId;
        });

        // will always exist
        if (oldImage) {
            partnerLogos[index] = oldImage;
        }

        setPartnerLogos(deepCopy<IPartnerLogo[]>(partnerLogos));
    };

    const handlePartnerLogoImage = (): void => {
        const newImage = { ...InitialPartnerLogoData };

        if (partnerLogos.length) {
            setPartnerLogos(deepCopy<IPartnerLogo[]>([newImage].concat(partnerLogos)));
        } else {
            setPartnerLogos(deepCopy<IPartnerLogo[]>([newImage]));
        }
    };
    /**
     * @param oldIndex
     * @param newIndex
     * @param type
     */
    const handleOnPartnerLogosOrderChange = (oldIndex: number, newIndex: number) => {
        const arry = arrayMove(partnerLogos, oldIndex, newIndex);

        setPartnerLogos({
            ...arry,
        });
    };

    const isStegAdmin = mandant.key === "stegadmin";

    if (!mandant.id) {
        return <></>;
    }

    function handleBread(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        history.push("" + event.currentTarget.dataset.url);
    }

    return (
        <>
            {isLoading && <LinearProgress color={"primary"} style={{ marginBottom: "16px" }} />}
            <Breadcrumbs aria-label="breadcrumb">
                {!isStegAdmin && (
                    <Link
                        color="inherit"
                        href={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                        data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_DASHBOARD)}
                        onClick={handleBread}
                    >
                        Start
                    </Link>
                )}
                {isStegAdmin && (
                    <Link
                        color="inherit"
                        href={toPortalUrl(mandant, RoutesEnum.ADMIN_PORTAL_LIST)}
                        data-url={toPortalUrl(mandant, RoutesEnum.ADMIN_PORTAL_LIST)}
                        onClick={handleBread}
                    >
                        Portal Übersicht
                    </Link>
                )}

                <Typography color="textPrimary">
                    {mandantId === "create" ? "Portal erstellen" : "Portal: " + mandantData.name}
                </Typography>
            </Breadcrumbs>

            <Grid container alignItems="flex-start">
                <Grid item xs={12} md={12} lg={12}>
                    <Button onClick={handleSaveClick} color="primary" startIcon={<SaveIcon />}>
                        Daten Speichern
                    </Button>

                    <Button onClick={resetMyChangesAction} color="secondary" startIcon={<CancelIcon />}>
                        Meine Änderungen zurücksetzen
                    </Button>

                    <Button onClick={setChangeModalPasswordOpen} color="primary" startIcon={<VpnKeyIcon />}>
                        Portaladmin zurücksezten
                    </Button>
                </Grid>
            </Grid>

            <TabContainer
                isAdmin={true}
                tabs={[
                    {
                        id: "tab-portal-data",
                        label: "Portal Daten",
                        display: true,
                        tabKey: 1,
                        content: (
                            <MandantDataTab
                                mandant={mandant}
                                mandantData={mandantData}
                                onChange={handleChange}
                                onCheckboxChange={handleCheckboxChange}
                                onImageChange={handleImageChange}
                                errorState={errorState}
                            />
                        ),
                    },
                    {
                        id: "tab-portal-partner-logos",
                        label: "Partner Logos",
                        display: true,
                        tabKey: 2,
                        content: (
                            <MandantPartnerLogoTab
                                mandant={mandantData}
                                partnerLogos={partnerLogos}
                                onUpload={handlePartnerLogoUpload}
                                onDelete={handlePartnerLogoDelete}
                                onResetImage={handlePartnerLogoReset}
                                onImageCreate={handlePartnerLogoImage}
                                onSort={handleOnPartnerLogosOrderChange}
                                handleImagesSortSave={handleOnPartnerLogosOrderSave}
                                onChange={handleChange}
                            />
                        ),
                    },
                    {
                        id: "tab-texts",
                        label: "Texte",
                        display: true,
                        tabKey: 3,
                        content: (
                            <MandantTextTab
                                mandantData={mandantData}
                                onChange={handleChange}
                                onCheckboxChange={handleCheckboxChange}
                                onChangeWysiwyg={handleWysiwygChange}
                            />
                        ),
                    },
                    {
                        id: "tab-login-page",
                        label: "Login Seite",
                        display: true,
                        tabKey: 4,
                        content: (
                            <MandantInfoTab
                                mandantData={mandantData}
                                onChange={handleChange}
                                onCheckboxChange={handleCheckboxChange}
                                onChangeWysiwyg={handleWysiwygChange}
                            />
                        ),
                    },
                ]}
                activeTab={activeTab}
                ariaLabel={"Mandant Settings"}
                idKey={"madant"}
                handleChange={handleTabChange}
            />

            {changeModalIsOpen && (
                <ChangeAdminPasswordModal
                    isOpen={changeModalIsOpen}
                    setClose={closeChangeModal}
                    activeMandant={mandantData.key}
                    resetAdminPasswortAction={resetAdminPasswortAction}
                />
            )}

            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};

/**
 * for async func we only set handleOnLoginEvent: (params:any) => LoginAction(params)
 * for non async we use  handleOnLoginEvent: () => {dispatch(SomeActions())
 */
const mapDispatchToProps: MapDispatchToPropsParam<IMapDispatchProps, IOwnProps> = (
    dispatch: ThunkDispatch<{}, {}, Action>
) => {
    return {
        handleLoadMandant: (mandant: IMandant) => {
            return dispatch(LoadMandantAction(mandant.key));
        },
    };
};

export default connect(null, mapDispatchToProps)(MandantForm);
