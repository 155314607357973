import { Box, Button, Card, FormControl, Grid, Paper, TextField } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import { AddPhotoAlternateOutlined } from "@material-ui/icons";
import AttachmentIcon from "@material-ui/icons/Attachment";
import IconDelete from "@material-ui/icons/Delete";
import IconReplay from "@material-ui/icons/Replay";
import React from "react";

import { API_URL_STATIC } from "../../../../../../config/config";
import IDocument from "../../../../../../types/IDocument";
import IEventGalleryImage from "../../../../../../types/IEventGalleryImage";

type IProps = {
    image: IEventGalleryImage;
    index: number;
    type: string;
    onChange: (type: string, image: IEventGalleryImage, index: number) => void;
    onDelete: (type: string, index: number) => void;
    onReset: (type: string, index: number, imageId: number) => void;
    isNotImage?: boolean;
    isSorting?: boolean;
};
const ImageGalleryCard = (props: IProps) => {
    const { image, index, isNotImage, isSorting, type, onChange, onDelete, onReset } = props;

    if (image === null) {
        return <div>Invalid image</div>;
    }

    const handleUploadClick = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        // const url =

        reader.onloadend = () => {
            onChange(
                type,
                {
                    ...image,
                    document: {
                        ...image.document,
                        data: reader.result,
                        name: file.name,
                        size: file.size,
                    },
                    isChanged: true,
                },
                props.index
            );
        };
    };

    const handleTextChange = (event: any) => {
        onChange(
            type,
            {
                ...image,
                document: {
                    ...image.document,
                    [event.target.name]: event.target.value,
                },
                isChanged: true,
            },
            props.index
        );
    };

    const getImageSrc = (imageNode: IDocument) => {
        if (imageNode.data) {
            return imageNode.data;
        }
        if (imageNode.url) {
            return API_URL_STATIC + imageNode.url;
        }

        return null;
    };

    const imageSource = getImageSrc(image.document);

    return (
        <Grid container item xs={12} md={6} lg={4} direction="column">
            <Card variant="outlined" elevation={1} style={{ height: "100%" }}>
                <FormControl fullWidth>
                    <Paper elevation={0}>
                        {!isNotImage && (
                            <>
                                <input
                                    accept="image/*"
                                    id={"event-gallery-image-upload-" + props.index}
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={handleUploadClick}
                                />
                                <label htmlFor={"event-gallery-image-upload-" + props.index}>
                                    {imageSource && (
                                        <img style={{ maxWidth: "100%" }} src={imageSource} alt={"fresh uploaded"} />
                                    )}
                                    {!imageSource && (
                                        <Fab
                                            component="span"
                                            style={{
                                                marginTop: "16px",
                                                marginLeft: "16px",
                                                marginBottom: "16px",
                                            }}
                                        >
                                            <AddPhotoAlternateOutlined />
                                        </Fab>
                                    )}
                                </label>
                            </>
                        )}
                        {isNotImage && (
                            <>
                                <input
                                    accept="file/*"
                                    id={"event-gallery-attachement-upload-" + props.index}
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={handleUploadClick}
                                />
                                <label htmlFor={"event-gallery-attachement-upload-" + props.index}>
                                    {imageSource && (
                                        <Button style={{ maxWidth: "100%" }}>
                                            {" "}
                                            <AttachmentIcon />{" "}
                                        </Button>
                                    )}
                                    {!imageSource && (
                                        <Fab
                                            component="span"
                                            style={{
                                                marginTop: "16px",
                                                marginLeft: "16px",
                                                marginBottom: "16px",
                                            }}
                                        >
                                            <AddPhotoAlternateOutlined />
                                        </Fab>
                                    )}
                                </label>
                            </>
                        )}
                    </Paper>
                    <Box m={0} padding={2}>
                        <Paper elevation={0}>
                            <TextField
                                variant="outlined"
                                label="Name Bild"
                                name="name"
                                value={image.document.name || " "}
                                onChange={handleTextChange}
                                fullWidth
                                disabled={isSorting}
                            />
                            <TextField
                                variant="outlined"
                                label="Beschreibung"
                                name="description"
                                value={image.document.description || " "}
                                onChange={handleTextChange}
                                fullWidth
                                multiline
                                disabled={isSorting}
                            />
                            <TextField
                                variant="outlined"
                                label="Copyright *"
                                name="copyright"
                                value={image.document.copyright || " "}
                                onChange={handleTextChange}
                                fullWidth
                                multiline
                                disabled={isSorting}
                            />
                        </Paper>
                    </Box>
                </FormControl>
                <CardActions>
                    {!isSorting && (
                        <IconButton
                            className="event_form-button"
                            onClick={() => {
                                onDelete(type, index);
                            }}
                            title="Löschen"
                        >
                            <IconDelete />
                        </IconButton>
                    )}

                    {image.isChanged && image.id != null && (
                        <IconButton
                            className="event_form-button"
                            color="secondary"
                            onClick={() => {
                                onReset(type, index, parseInt("" + image.id));
                            }}
                            title="Zurücksetzen"
                        >
                            <IconReplay />
                        </IconButton>
                    )}
                </CardActions>
            </Card>
        </Grid>
    );
};
export default ImageGalleryCard;
