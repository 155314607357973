import DateFnsUtils from "@date-io/date-fns";
import { Button, Checkbox, FormControl, FormControlLabel, Grid, Paper, TextField } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import deLocal from "date-fns/locale/de";
import React from "react";

import { Format } from "../../../../config/config";
import ICampaignAppointment from "../../../../types/appointment/IAppointmentBulk";
import ICampaign from "../../../../types/appointment/ICampaign";
import ICampaignRoom from "../../../../types/appointment/ICampaignRoom";
import { IAppointmentValidationState } from "../validations/appointmentDataValidation";

type IAppointmentDataTab = {
    errorAppointmentState: IAppointmentValidationState;
    appointmentData: ICampaignAppointment;
    campaignData: ICampaign;
    onTextChange: (event: any) => void;
    onCheckBoxChange: (event: any) => void;
    onRoomCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>, room: number) => void;
    handleSave: (event: any) => void;
    onDateChange: (date: Date, attrKey: string) => void;
    onTimeChange: (time: Date, attrKey: string) => void;
};

const AppointmentBulkDataTab = (props: IAppointmentDataTab) => {
    const {
        errorAppointmentState,
        campaignData,
        appointmentData,
        onTextChange,
        onCheckBoxChange,
        onRoomCheckBoxChange,
        handleSave,
        onDateChange,
        onTimeChange,
    } = props;
    return (
        <>
            <Grid container className="gap-bottom">
                <Grid item xs={12}>
                    <Button color={"primary"} onClick={handleSave}>
                        Termine erstellen
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper variant="outlined" className="main-paper">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h2" className="main-header gap-bottom">
                                    Bulk Termine erstellen
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider locale={deLocal} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        inputVariant="outlined"
                                        name="appointment-start-date"
                                        margin="normal"
                                        id="campaign-start-date"
                                        variant="inline"
                                        label="Start Date"
                                        required={true}
                                        fullWidth
                                        format={Format.dateInp}
                                        value={
                                            appointmentData.startDate.date
                                                ? new Date(appointmentData.startDate.date)
                                                : null
                                        }
                                        onChange={(date: any) => onDateChange(date, "startDate")}
                                        KeyboardButtonProps={{
                                            "aria-label": "Startdatum anpassen",
                                        }}
                                        error={
                                            errorAppointmentState.startDate?.empty ||
                                            errorAppointmentState.startDate?.invalid ||
                                            errorAppointmentState.startDate?.exceedCampaign
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider locale={deLocal} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        inputVariant="outlined"
                                        name="AppointmentEndDate"
                                        margin="normal"
                                        id="appointment-end-date"
                                        variant="inline"
                                        label="End Date"
                                        required={true}
                                        fullWidth
                                        format={Format.dateInp}
                                        value={
                                            appointmentData.endDate.date ? new Date(appointmentData.endDate.date) : null
                                        }
                                        onChange={(date: any) => {
                                            onDateChange(date, "endDate");
                                        }}
                                        KeyboardButtonProps={{
                                            "aria-label": "Enddatum anpassen",
                                        }}
                                        error={
                                            errorAppointmentState.endDate?.empty ||
                                            errorAppointmentState.endDate?.invalid ||
                                            errorAppointmentState.endDate?.exceedCampaign
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider locale={deLocal} utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        inputVariant="outlined"
                                        name="appointment-start-time"
                                        margin="normal"
                                        id="appointment-start-time"
                                        variant="inline"
                                        label="Start Time"
                                        required={true}
                                        fullWidth
                                        format={Format.time}
                                        value={
                                            appointmentData.startTime.date
                                                ? new Date(appointmentData.startTime.date)
                                                : null
                                        }
                                        onChange={(time: any) => onTimeChange(time, "startTime")}
                                        error={errorAppointmentState.startTime}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider locale={deLocal} utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        inputVariant="outlined"
                                        name="appointment-end-time"
                                        margin="normal"
                                        id="appointment-end-time"
                                        variant="inline"
                                        label="End Time"
                                        required={true}
                                        fullWidth
                                        format={Format.time}
                                        value={
                                            appointmentData.endTime.date ? new Date(appointmentData.endTime.date) : null
                                        }
                                        onChange={(time: any) => onTimeChange(time, "endTime")}
                                        error={errorAppointmentState.endTime}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    label="Länge des Termins (Minuten)"
                                    name="period"
                                    onChange={onTextChange}
                                    fullWidth
                                    error={errorAppointmentState.period}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl required error={errorAppointmentState.room}>
                                    <FormLabel component="legend">Auswahl Besprechungszimmer</FormLabel>
                                    <FormGroup>
                                        {campaignData.campaignRooms.map(
                                            (campaignRoom: ICampaignRoom, index: number) => (
                                                <FormControlLabel
                                                    key={"room-" + index}
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                !!appointmentData.rooms.find((room: number) => {
                                                                    return room === campaignRoom.id;
                                                                })
                                                            }
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                onRoomCheckBoxChange(event, campaignRoom.id);
                                                            }}
                                                            name={campaignRoom.name}
                                                        />
                                                    }
                                                    label={campaignRoom.name}
                                                />
                                            )
                                        )}
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className="group-based">
                                <FormLabel component="legend">Gruppe</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={appointmentData.isGroup}
                                                onChange={onCheckBoxChange}
                                                name="isGroup"
                                            />
                                        }
                                        label="Ist ein Gruppen Termin"
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default AppointmentBulkDataTab;
