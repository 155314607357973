import IEventFull from "../../../types/IEventFull";

export type IEventValidationState = {
    hasError: boolean;
    title?: boolean;
    urlKey?: boolean;
    shortDesc?: boolean;
    theStart?: boolean;
    theEnd?: boolean;
    imageMenu?: boolean;
    imageHeader?: boolean;
};

export const requiredEventFilled = (event: IEventFull): IEventValidationState => {
    const validationState: IEventValidationState = {
        hasError: false,
    };

    if (event.title.length < 1) {
        validationState.title = true;
        validationState.hasError = true;
    }

    if (event.urlKey.length < 1) {
        validationState.urlKey = true;
        validationState.hasError = true;
    }

    if (event.shortDesc.length < 1) {
        validationState.shortDesc = true;
        validationState.hasError = true;
    }

    if (isNaN(Date.parse(event.theStart))) {
        validationState.theStart = true;
        validationState.hasError = true;
    }

    if (isNaN(Date.parse(event.theEnd))) {
        validationState.theEnd = true;
        validationState.hasError = true;
    }

    if (event.imageMenu === null || (!event.imageMenu.data && !event.imageMenu.url)) {
        validationState.imageMenu = true;
        validationState.hasError = true;
    }

    if (event.imageHeader === null || (!event.imageHeader.data && !event.imageHeader.url)) {
        validationState.imageHeader = true;
        validationState.hasError = true;
    }

    return validationState;
};
