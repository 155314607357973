import { Button, Grid } from "@material-ui/core";
import IconSave from "@material-ui/icons/Save";
import update from "immutability-helper";
import React, { useCallback } from "react";

import Notify from "../../../components/notify/Notify";
import IMandant from "../../../types/IMandant";
import InitialSnackbarState from "../../../types/initialData/InitialSnackbarState";
import IProjectList from "../../../types/IProjectList";
import ISnackbarState from "../../../types/ISnackbarState";
import { getIds } from "../../../utils/getIds";
import { loadPublicAllProjectListRequest } from "../../../xhr/ProjectRequests";
import { sortOrdredProjectListRequest } from "../../../xhr/ProjectRequests";
import { Card } from "./ProjectCard";

export interface IContainerState {
    projectList: IProjectList[];
}

export interface IContaierProps {
    mandant: IMandant;
}

export const Container: React.FC<IContaierProps> = (props: IContaierProps) => {
    const [projectList, setProjectList] = React.useState<IProjectList[]>([]);
    const [projectReady, setProjectsReady] = React.useState<boolean>(false);
    const [isDndSorted, setIsDndSorted] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    React.useEffect(() => {
        loadPublicAllProjectListRequest()
            .then((result: any) => {
                setProjectList(
                    result.data.sort((a: IProjectList, b: IProjectList) => {
                        if (a.priority === null) {
                            return 1;
                        } else if (b.priority === null) {
                            return -1;
                        } else {
                            return a.priority - b.priority;
                        }
                    })
                );
                setProjectsReady(true);
                return Promise.resolve();
            })
            .catch(() => {
                setProjectsReady(true);
            });
    }, []);

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setProjectList((prevCards: IProjectList[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex] as IProjectList],
                ],
            })
        );
        setIsDndSorted(true);
    }, []);

    const renderCard = useCallback((card: IProjectList, index: number) => {
        const cardText = `${card.title} - ${card?.mandant?.key}`;

        return <Card key={card.id} index={index} id={card.id} text={cardText} moveCard={moveCard} />;
    }, []);

    /* handle save for new ordred list of projects */
    const handleProjectListOrderSave = () => {
        setLoading(true);
        sortOrdredProjectListRequest(props.mandant.key, getIds(projectList))
            .then(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Die Sortierung wurde erfolgreich gespeichert.",
                    type: "success",
                });
                setLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Speichern der Sortierung ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setLoading(false);
            });
    };

    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };
    return (
        <>
            <div>{projectList.map((card, i) => renderCard(card, i))}</div>
            <Grid item xs={12} md={12} lg={12}>
                <Button
                    disabled={!isDndSorted}
                    color="primary"
                    onClick={handleProjectListOrderSave}
                    startIcon={<IconSave />}
                >
                    Projektliste speichern
                </Button>
            </Grid>
            <Notify closeSnackbar={closeSnackbar} {...snackBarState} />
        </>
    );
};
