import moment from "moment";
//export const API_URL_STATIC = "https://steg-bube.staging-sys.de/";
//export const API_URL_STATIC = "http://localhost:8000/";
//export const API_URL_STATIC = "https://steg-bube.staging-sys.de/";
//export const API_URL_STATIC = "https://beteiligung.steg.de/";
//export const API_URL_STATIC = "https://steg.buizcloud.de/";
const profileKey = window.location.hostname;

export const serverApiProfiles: { [key: string]: string } = {
    localhost: "https://steg-bube.staging-sys.de/", // https://steg-bube.staging-sys.de/ | "http://steg-bube.backend/"
    "beteiligung.steg.de": "https://steg.buizcloud.de/",
    "steg-new-ui.staging-sys.de": "https://steg.buizcloud.de/",
    "steg-bube.staging-sys.de": "https://steg-bube.staging-sys.de/",
};

// a more robust way to configure if it's a testsystem or not
const testSystems: string[] = ["localhost", "steg-bube.staging-sys.de"];

export const API_URL_MATOMO = "https://matomo.buizcloud.de/";

export const API_URL_STATIC = serverApiProfiles[profileKey]
    ? serverApiProfiles[profileKey]
    : "https://steg.buizcloud.de/";

export const API_URL_CMS = API_URL_STATIC + "api/cms/";
export const API_URL_BACKEND = API_URL_STATIC + "api/";

export const APP_VERSION = process.env.REACT_APP_VERSION + "";

export const DEBUG_MODE = false;

export const IS_TESTSYSTEM = testSystems.includes(profileKey);

export const Format = {
    date: "DD.MM.YYYY",
    dateInp: "dd.MM.yyyy",
    dateInpTime: "dd.MM.yyyy HH:mm",
    time: "HH:mm",
    dateTime: "DD.MM.YYYY HH:mm",
    //dateTimeEn: "DD.MM.YYYY HH:mm",
    dateTimeEn: "YYYY-MM-DD HH:mm:ss",
    dateEn: "YYYY-MM-DD",
};
export const PAGE_BREAK_MOBILE = 970;
export const PAGE_BREAK_MOBILE_WITH_BIG_LOGO = 1070;

export const COMMENT_PAGINATION = 10;
export const MAP_COMMENT_PAGINATION = 100;

export const dateStateFormat = (date: Date | null) => {
    if (!date) {
        return "";
    }

    const dateYear = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return dateYear + "-" + month + "-" + day;
};

export const getTimeInterval = (date: string, duration: number) => {
    return `${moment(date).format("HH:mm")} - ${moment(date).add(duration, "minutes").format("HH:mm")}`;
};

export const dateStateFormatLeadingZero = (date: Date | null) => {
    if (!date) {
        return "";
    }
    const dateYear = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? "0" + date.getMonth() + 1 : date.getMonth() + 1;
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    return dateYear + "-" + month + "-" + day;
};
