import IMandant from "../types/IMandant";
import setCssVariable from "./setCssVariable";

const setMandantThemeByServer = (mandant: Partial<IMandant>, isMobile: boolean) => {
    /*
    primaryColor?: string;
    secondaryColor?: string;
    backgroundColorFooter1?: string;
    backgroundColorFooter2?: string;
    buttonBackground?: string;
    buttonBorder?: string;
    buttonTextColor?: string;
    colorLink?: string;
    colorLinkHover?: string;
    contactFormTextAfter?: string;
    contactFormTextBefore?: string;
    footerLeftText?: string;
    footerLeftTitel?: string;
    gap?: string;
    headerH1Color?: string;
    headerH1FontFamily?: string;
    headerH1Size?: string;
    headerH1Weight?: string;
    headerH2Color?: string;
    headerH2H3FontFamily?: string;
    headerH2Size?: string;
    headerH2Weight?: string;
    headerH3Color?: string;
    headerH3Size?: string;
    headerH3Weight?: string;

    linkColorFooter1?: string;
    linkColorFooter1Hover?: string;
    linkColorFooter2?: string;
    linkColorFooter2Hover?: string;

    textColor?: string;
    textColorFooter1?: string;
    textColorFooter2?: string;
    textColorSmaller?: string;
    textFontFamily?: string; //TODO to be replaced by the theme.
    textFontSize?: string;
    textFontSizeSmaller?: string;
    textFontWeight?: string;
    */
    setCssVariable("--primary-color", "" + mandant.primaryColor);

    switch (mandant?.theme) {
        case "sporty":
            //h1
            setCssVariable("--header-h1-size", isMobile ? "35px" : "70px");
            setCssVariable("--header-h1-line-height", isMobile ? "37px" : "74px");
            setCssVariable("--header-h1-font-family", "protest-grotesk-text-black");

            //h2
            setCssVariable("--header-h2-size", isMobile ? "23px" : "46px");
            setCssVariable("--header-h2-line-height", isMobile ? "27px" : "55px");
            setCssVariable("--header-h2-font-family", "protest-grotesk-text-bold");
            setCssVariable("--mobile-menu-font-family", "protest-grotesk-text-bold");
            setCssVariable("--mobile-menu-font-size", "15px");

            //h3
            setCssVariable("--header-h3-size", isMobile ? "16px" : "20px");
            setCssVariable("--header-h3-line-height", isMobile ? "20px" : "32px");
            setCssVariable("--header-h3-font-family", "protest-grotesk-text-black");

            //p
            setCssVariable("--text-font-family", "protest-grotesk-text-regular");
            setCssVariable("--text-font-size", isMobile ? "15px" : "20px");
            setCssVariable("--text-line-height", isMobile ? "8px" : "32px");
            setCssVariable("--text-font-size-smaller", isMobile ? "15px" : "16px");

            // links
            setCssVariable("--link-font-family", "protest-grotesk-text-black");

            break;
        case "classic":
            //h1
            setCssVariable("--header-h1-size", isMobile ? "40px" : "80px");
            setCssVariable("--header-h1-line-height", isMobile ? "45px" : "90px");
            setCssVariable("--header-h1-font-family", "suisse-works-medium");

            //h2
            setCssVariable("--header-h2-size", isMobile ? "23px" : "46px");
            setCssVariable("--header-h2-line-height", isMobile ? "32px" : "64px");
            setCssVariable("--header-h2-font-family", "suisse-works-medium");
            setCssVariable("--mobile-menu-font-family", "suisse-works-medium");
            setCssVariable("--mobile-menu-font-size", "15px");

            //h3
            setCssVariable("--header-h3-size", isMobile ? "14px" : "16px");
            setCssVariable("--header-h3-line-height", isMobile ? "20px" : "20px");
            setCssVariable("--header-h3-font-family", "helvetica-neue-medium");

            //p
            setCssVariable("--text-font-family", "suisse-works-regular");
            setCssVariable("--text-font-size", "25px");
            setCssVariable("--text-font-size-smaller", isMobile ? "15px" : "16px");

            setCssVariable("--text-line-height", "32px");

            // links
            setCssVariable("--link-font-family", "suisse-works-regular");
            break;

        case "bold":
            //h1
            setCssVariable("--header-h1-size", isMobile ? "50px" : "100px");
            setCssVariable("--header-h1-line-height", isMobile ? "50px" : "100px");
            setCssVariable("--header-h1-font-family", "suisse-intelcond-semibold");

            //h2
            setCssVariable("--header-h2-size", isMobile ? "23px" : "46px");
            setCssVariable("--header-h2-line-height", isMobile ? "31px" : "63px");
            setCssVariable("--header-h2-font-family", "suisse-intelcond-medium");
            setCssVariable("--mobile-menu-font-family", "suisse-intelcond-medium");
            setCssVariable("--mobile-menu-font-size", "15px");

            //h3
            setCssVariable("--header-h3-size", isMobile ? "16px" : "18px");
            setCssVariable("--header-h3-line-height", isMobile ? "16px" : "32px");
            setCssVariable("--header-h3-font-family", "suisse-intelcond-medium");

            //p
            setCssVariable("--text-font-family", "suisse-intelcond-medium");
            setCssVariable("--text-font-size", isMobile ? "14px" : "27px");
            setCssVariable("--text-line-height", isMobile ? "17px" : "34px");
            setCssVariable("--text-font-size-smaller", isMobile ? "15px" : "16px");
            // links
            setCssVariable("--link-font-family", "suisse-intelcond-medium");
            break;
        default:
            break;
    }
};

export default setMandantThemeByServer;
